import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import CardForm from '../Common/Card/CardForm';
import CardTable from '../Common/Card/CardTable';
import { tableHeadList } from '../../helpers/common';
import Loader from './../Common/Loader';
import Pagination from './../Common/Pagination';
import ReportSearch from './../Common/Search/ReportSearch';

import { formatDate } from './../../helpers/common';
import { clearServiceLogReports, getServiceLogs } from './../../store/actions';
import HistorySearch from '../Common/Search/HistorySearch';

const ReportsList = (props) => {
    const [current, setCurrent] = useState(1);
    const [meta, setMeta] = useState({})
    const perPage = 10;
    const reportsLogs = useSelector(state => state.ServiceLogs.logs)
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        setMeta(props.meta)
    }, [props.meta])

    useEffect(() => {
        const page = meta && meta.current_page;
        setCurrent(page)
    }, [meta])

    useEffect(() => {
        return () => {
            dispatch(clearServiceLogReports())
        }
    }, [dispatch])

    const handleClick = (e) => {
        const page = parseInt(e.target.id)
        setCurrent(page)
        dispatch(getServiceLogs(page, perPage))
    }

    const clickableRow = (id) => {
        history.push(`/istorija-zapisa/${id}`)
    }

    return (
        <CardForm
            title={props.title}
            icon={faListAlt}
            addReportBtn={props.addReportBtn}
            onClick={props.onClick}
        >
            {props.historyServiceBook && <HistorySearch />}
            <div className='list-of-reports'>
                {props.reports &&
                    <ReportSearch
                        current={current}
                        perPage={perPage}
                        serviceTypes={props.serviceTypes}
                        serviceCategories={props.serviceCategories}
                        manufacturers={props.manufacturers}
                        models={props.models}
                        customers={props.customers}
                        vehicles={props.vehicles}
                    />
                }
                {props.loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        {reportsLogs.data &&
                            reportsLogs.data.length > 0 &&
                            <CardTable
                                headListItems={tableHeadList.reports}
                            >

                                {reportsLogs.data.map((report, index) => {
                                    const vehicle = report.vehicle;
                                    return (
                                        <tr key={`${report.id}_${index}`} className='list-grid' onClick={() => clickableRow(report.id)}>
                                            <td>{formatDate(report.date)}</td>
                                            {vehicle && [vehicle].map((item, index) => (
                                                <Fragment key={`${item.id}_${index}`}>
                                                    <td >{item.production_year} {item.vehicle_manufacturer.name} {item.vehicle_model.name} <br /> {item.label}</td>
                                                    <td>{item.vehicle_plate}</td>
                                                </Fragment>

                                            ))}
                                            <td>{report.price_total}</td>
                                            <td>{report.title}</td>
                                            <td>{report.text}</td>
                                        </tr>
                                    )
                                })}
                            </CardTable>
                        }
                        {meta && meta.total > 10 &&
                            <Pagination
                                data={meta && parseInt(meta.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }
            </div>
        </CardForm>
    )
}

export default ReportsList
