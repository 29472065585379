import axios from 'axios';


export const loginUser = async (data, history) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/auth/login`,
        {
            email: data.email,
            password: data.password
        },
        {
            headers: {
                Accept: 'application/json'
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response.data
            }
        })
        .catch(error => {
            return error
        })
}

export const forgotPassword = async (email) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/password/email`, {
        email
    },
        {
            headers: {
                Accept: 'application/json'
            }
        })
}

export const resetPassword = async (token, email, password, password_confirmation) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/password/reset`,
        { token, email, password, password_confirmation },
        {
            headers: {
                Accept: 'application/json'
            }
        })
        .then(response => {
            return response
        })
        .catch(error => {
            return error
        })
}