import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getVehicleModels = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/vehicle_models?manufacturer_id=${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}