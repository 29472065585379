import React, { useState, useEffect, useCallback } from 'react';

const Pagination = ({ data, handleClick, itemsPerPage, currentPage }) => {
    const [pageNumbers, setPageNumbers] = useState([]);
    const pageLength = Math.ceil(data / itemsPerPage);

    const paginationPages = useCallback(() => {
        let arr = [];
        for (let i = 1; i <= pageLength; i++) {
            arr.push(i)
        }
        setPageNumbers(arr)
    }, [pageLength])

    useEffect(() => {
        paginationPages()
    }, [paginationPages])

    return <div className='pagination'>{pageNumbers.map(item => <span key={item} id={item} onClick={handleClick} className={currentPage === parseInt(item) ? 'activePage' : ''}>{item} </span>)}</div>
}

export default Pagination
