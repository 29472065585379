import React, { PureComponent } from 'react';
import { withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import { getToDo, getUsers, getVehicles, deleteToDoTask, getCustomers, getTodoStatuses } from './../../store/actions';

import Todo from '../../components/Lists/Todo';
import CommonButton from '../../components/Common/CommonButton';
import { faCar, faStickyNote, faTasks, faUser } from '@fortawesome/free-solid-svg-icons';

import { checkUserType } from './../../helpers/common';


class Dashboard extends PureComponent {

    componentDidMount() {
        if (!this.props.tasks) {
            this.props.getToDo(1, 10)
        }
        this.props.getUsers(1, 1000)
        this.props.getVehicles(1, 1000)
        this.props.getCustomers(1, 1000)
        if (this.props.todoStatuses.length === 0) {
            this.props.getTodoStatuses()
        }
    }

    handleRoute = (e) => {
        switch (e.target.name) {
            case 'vehicle':
                this.props.history.push('/dodavanje-vozila')
                break
            case 'log':
                this.props.history.push('/dodavanje-zapisa')
                break
            case 'task':
                this.props.history.push('/dodavanje-zadatka')
                break
            case 'customer':
                this.props.history.push('/dodavanje-klijenta')
                break
            default:
                return
        }
    }

    render() {
        return (
            <div className='dashboard page'>
                {checkUserType.admin(this.props.me?.role_id) &&
                    <div style={{ display: 'flex', justifyContent: 'space-between', marginBottom: '20px', flexWrap: 'wrap' }}>
                        <CommonButton
                            style={{ width: '20%' }}
                            variant='danger'
                            type='button'
                            name='vehicle'
                            onClick={this.handleRoute}
                            icon={faCar}
                            label='Dodaj vozilo'
                        />
                        <CommonButton
                            style={{ width: '20%' }}
                            variant='danger'
                            type='button'
                            name='log'
                            onClick={this.handleRoute}
                            icon={faStickyNote}
                            label='Dodaj zapis'
                        />
                        <CommonButton
                            style={{ width: '20%' }}
                            variant='danger'
                            type='button'
                            name='task'
                            onClick={this.handleRoute}
                            icon={faTasks}
                            label='Dodaj zadatak'
                        />
                        <CommonButton
                            style={{ width: '20%' }}
                            variant='danger'
                            type='button'
                            name='customer'
                            onClick={this.handleRoute}
                            icon={faUser}
                            label='Dodaj klijenta'
                        />
                    </div>
                }
                <Todo
                    loading={this.props.loading}
                    tasksList={this.props.tasks}
                    users={this.props.users}
                    vehicles={this.props.vehicles}
                    deleteTask={this.props.deleteToDoTask}
                    me={this.props.me}
                    meta={this.props.meta}
                    customers={this.props.customers}
                    todoStatuses={this.props.todoStatuses}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Todo.loading,
        tasks: state.Todo.tasks.data,
        users: state.Users.users.data,
        vehicles: state.Vehicles.vehicles.data,
        me: state.Me.me,
        meta: state.Todo.tasks.meta,
        customers: state.Customers.customers.data,
        todoStatuses: state.StaticTypes.todoStatuses
    }
}

export default connect(mapStateToProps, { getToDo, getUsers, getVehicles, deleteToDoTask, getCustomers, getTodoStatuses })(withRouter(Dashboard))
