import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import VehicleForm from '../../components/Forms/VehicleForm';

import {
    createVehicle,
    // getVehicleManufacturers,
    // getFuelTypes,
    // getVehicleStatuses,
    // getVehicleTypes,
    // getCustomerRoles,
    getCustomers,
    createCustomerVehiclesData
} from './../../store/actions';

const AddVehicle = () => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()
    useEffect(() => {
        // dispatch(getVehicleManufacturers())
        // dispatch(getFuelTypes())
        // dispatch(getVehicleStatuses())
        // dispatch(getVehicleTypes())
        // dispatch(getCustomerRoles())
        dispatch(getCustomers(1, 1000))
    }, [dispatch])

    return (
        <div className='page'>
            <VehicleForm
                title='Dodavanje vozila'
                submitVehicle={createVehicle}
                vehicleCreated={true}
                manufacturers={state.VehicleManufacturers.manufacturers}
                fuelTypes={state.StaticTypes.fuelTypes}
                vehicleStatuses={state.StaticTypes.vehicleStatuses}
                vehicleTypes={state.StaticTypes.vehicleTypes}
                customerRoles={state.StaticTypes.customerRoles}
                listOfCustomers={state.Customers.customers.data}
                createCustomerVehicles={createCustomerVehiclesData}
            />
        </div>
    )
}

export default AddVehicle
