import axios from 'axios';
const token = localStorage.getItem('authUser');

export const globalSearch = async (query) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/search?q=${query}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
}