import React from 'react';
import { Alert, Badge } from 'react-bootstrap';

function ErrorAlert({ content, onClose }) {
    return (
        <Alert
            variant='danger'
            dismissible={true}
            style={{ marginTop: '15px' }}
            onClose={onClose}
        >
            <Badge variant="danger">Greška</Badge>
            <div>{content}</div>
        </Alert>
    )
}

export default ErrorAlert
