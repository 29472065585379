import { takeEvery, call, put, fork, all } from "redux-saga/effects";

import {
    GLOBAL_SEARCH
} from './actionTypes';

import {
    globalSearchSuccess,
    globalSearchError
} from './actions';

import {
    globalSearch as apiGlobalSearch
} from './../../helpers/search_service/search_controller';

function* globalSearch({ payload }) {
    try {
        const response = yield call(apiGlobalSearch, payload.query);
        if (response.status === 200 || response.code === 200) {
            yield put(globalSearchSuccess(response.data))
        }
    } catch (error) {
        yield put(globalSearchError(error))
    }
}

function* watchGlobalSearch() {
    yield takeEvery(GLOBAL_SEARCH, globalSearch)
}

function* globalSearchSaga() {
    yield all([
        fork(watchGlobalSearch)
    ])
}

export default globalSearchSaga