import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const TextAreaField = ({ label, controlId, value, onChange, required, infoText, name }) => {
    return (
        <Form.Group controlId={controlId}>
            <Form.Label>{label} {required && <FontAwesomeIcon icon={faAsterisk} color='#dc3545' />}</Form.Label>
            <Form.Control
                as="textarea"
                rows={6}
                value={value}
                name={name}
                onChange={onChange}
            />
            <Form.Text className="text-muted">
                {infoText}
            </Form.Text>
        </Form.Group>
    )
}

export default TextAreaField
