import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    CREATE_CUSTOMER_ERROR,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER,
    GET_CUSTOMER,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER_SUCCESS,
    GET_CUSTOMER_VEHICLES,
    GET_CUSTOMER_VEHICLES_SUCCESS,
    GET_CUSTOMER_VEHICLES_ERROR,
    GET_CUSTOMER_SERVICE_LOGS,
    GET_CUSTOMER_SERVICE_LOGS_SUCCESS,
    GET_CUSTOMER_SERVICE_LOGS_ERROR,
    GET_CUSTOMER_VEHICLES_DATA,
    GET_CUSTOMER_VEHICLES_DATA_SUCCESS,
    GET_CUSTOMER_VEHICLES_DATA_ERROR,
    CREATE_CUSTOMER_VEHICLES_DATA,
    CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    CREATE_CUSTOMER_VEHICLES_DATA_ERROR,
    SHOW_CUSTOMER_VEHICLES_DATA,
    SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS,
    SHOW_CUSTOMER_VEHICLES_DATA_ERROR,
    UPDATE_CUSTOMER_VEHICLES_DATA,
    UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    UPDATE_CUSTOMER_VEHICLES_DATA_ERROR,
    DELETE_CUSTOMER_VEHICLES_DATA,
    DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    DELETE_CUSTOMER_VEHICLES_DATA_ERROR,
} from './actionTypes';

const initialState = {
    loading: false,
    customers: [],
    customer: {},
    customerVehicles: [],
    customerServiceLogs: [],
    customerVehiclesData: [],
    error: ''
}

const Customers = (state = initialState, action) => {
    switch (action.type) {
        case GET_CUSTOMERS:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: action.payload
            }
        case GET_CUSTOMERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CREATE_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case CREATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: [action.payload.data, ...state.customers.data]
            }
        case CREATE_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: action.payload
            }
        case GET_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case UPDATE_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case UPDATE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customer: action.payload
            }
        case UPDATE_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case DELETE_CUSTOMER:
            return {
                ...state,
                loading: true
            }
        case DELETE_CUSTOMER_SUCCESS:
            return {
                ...state,
                loading: false,
                customers: [...state.customers.data, action.payload]
            }
        case DELETE_CUSTOMER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case GET_CUSTOMER_VEHICLES:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_VEHICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehicles: action.payload
            }
        case GET_CUSTOMER_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case GET_CUSTOMER_SERVICE_LOGS:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_SERVICE_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                customerServiceLogs: action.payload
            }
        case GET_CUSTOMER_SERVICE_LOGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }

        //Customer vehicles endpoint
        case GET_CUSTOMER_VEHICLES_DATA:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_VEHICLES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehiclesData: action.payload
            }
        case GET_CUSTOMER_VEHICLES_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case CREATE_CUSTOMER_VEHICLES_DATA:
            return {
                ...state,
                loading: true
            }
        case CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehiclesData: [action.payload.data, ...state.customerVehiclesData.data]
            }
        case CREATE_CUSTOMER_VEHICLES_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case SHOW_CUSTOMER_VEHICLES_DATA:
            return {
                ...state,
                loading: true
            }
        case SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehiclesData: action.payload.data
            }
        case SHOW_CUSTOMER_VEHICLES_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case UPDATE_CUSTOMER_VEHICLES_DATA:
            return {
                ...state,
                loading: true
            }
        case UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehiclesData: action.payload
            }
        case UPDATE_CUSTOMER_VEHICLES_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        case DELETE_CUSTOMER_VEHICLES_DATA:
            return {
                ...state,
                loading: true
            }
        case DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS:
            return {
                ...state,
                loading: false,
                customerVehiclesData: [...state.customerVehiclesData.data]
            }
        case DELETE_CUSTOMER_VEHICLES_DATA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.paylaod
            }
        default:
            return state
    }
}

export default Customers