import { takeEvery, call, put, fork, all } from 'redux-saga/effects';

import {
    GET_VEHICLE_MANUFACTURERS
} from './actionTypes';

import {
    getVehicleManufacturersSuccess,
    getVehicleManufacturersError
} from './actions';

import {
    getVehicleManufacturers as apiGetVehicleManufacturers
} from '../../../helpers/vehicles_service/manufacturers_controller';

function* getVehicleManufacturers() {
    try {
        const response = yield call(apiGetVehicleManufacturers)
        if (response.status === 200 || response.status === 201) {
            yield put(getVehicleManufacturersSuccess(response.data))
        }
        yield put(getVehicleManufacturersError(response.message))
    } catch (error) {
        yield put(getVehicleManufacturersError(error))
    }
}

function* watchGetVehicleManufacturers() {
    yield takeEvery(GET_VEHICLE_MANUFACTURERS, getVehicleManufacturers)
}

function* vehicleManufacturersSaga() {
    yield all([
        fork(watchGetVehicleManufacturers)
    ])
}

export default vehicleManufacturersSaga