import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { faSave } from '@fortawesome/free-solid-svg-icons';
import { getUsers, getVehicles, getToDoTask, getServiceCategories, getServiceTypes, getTodoStatuses } from './../../store/actions';

import HandleTask from '../../components/Lists/Todo/HandleTask';
import { getUrlId } from './../../helpers/common';

class SingleTask extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = getUrlId();
    }


    componentDidMount() {
        this.props.getUsers(1, 1000)
        this.props.getVehicles(1, 1000)
        this.props.getToDoTask(this.id)
        if (this.props.serviceTypes.length === 0) {
            this.props.getServiceTypes()
        }
        if (this.props.serviceCategories.length === 0) {
            this.props.getServiceCategories()
        }
        if (this.props.todoStatuses.length === 0) {
            this.props.getTodoStatuses()
        }
    }


    render() {
        return (
            <div className='page'>
                <HandleTask
                    titleCard='Radni zadatak'
                    icon={faSave}
                    users={this.props.users}
                    vehicles={this.props.vehicles}
                    me={this.props.me}
                    loading={this.props.loading}
                    task={this.props.task}
                    edit={true}
                    serviceTypes={this.props.serviceTypes}
                    serviceCategories={this.props.serviceCategories}
                    todoStatuses={this.props.todoStatuses}
                    finishButton={true}
                />

            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Todo.loading,
        users: state.Users.users.data,
        vehicles: state.Vehicles.vehicles.data,
        me: state.Me.me,
        task: state.Todo.task,
        serviceTypes: state.StaticTypes.serviceTypes,
        serviceCategories: state.StaticTypes.serviceCategories,
        todoStatuses: state.StaticTypes.todoStatuses
    }
}

export default connect(mapStateToProps, { getUsers, getVehicles, getToDoTask, getServiceTypes, getServiceCategories, getTodoStatuses })(SingleTask)
