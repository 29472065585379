import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Loader from '../../components/Common/Loader';
import EditUser from '../../components/Forms/EditUser';

import { getUser, updateUser, getUserStatuses, deleteUser } from './../../store/actions';
import { getUrlId } from './../../helpers/common';


const SingleUser = () => {
    const user = useSelector(state => state.Users.user);
    const userStatuses = useSelector(state => state.StaticTypes.userStatuses);
    const loading = useSelector(state => state.Users.loading);
    const me = useSelector(state => state.Me.me);
    const dispatch = useDispatch();
    const id = getUrlId();
    const history = useHistory();
    useEffect(() => {
        dispatch(getUser(id))
        if (userStatuses.length === 0) {
            dispatch(getUserStatuses())
        }
    }, [dispatch, id, userStatuses.length])

    const handleSubmit = (status_id, first_name, last_name, email, phone, password, password_confirmation) => {
        dispatch(updateUser(id, me.business_id, status_id, first_name, last_name, email, phone, password, password_confirmation))
    }

    const delUser = () => {
        dispatch(deleteUser(id, history))
    }

    return (
        <div className='page'>
            {loading ?
                <Loader
                    marginTop="100px"
                />
                :
                <EditUser
                    user={user && user}
                    userType={true}
                    userStatuses={userStatuses}
                    delUser={delUser}
                    handleSubmit={handleSubmit}
                />
            }

        </div>
    )
}

export default SingleUser
