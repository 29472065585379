import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import HandleTask from '../../components/Lists/Todo/HandleTask';
import { faPlusCircle } from '@fortawesome/free-solid-svg-icons';
import { getUsers, getVehicles, getServiceTypes, getServiceCategories, getTodoStatuses, getToDo } from '../../store/actions';


const AddTask = () => {
    const vehicles = useSelector(state => state.Vehicles.vehicles.data)
    const users = useSelector(state => state.Users.users.data)
    const me = useSelector(state => state.Me.me)
    const serviceTypes = useSelector(state => state.StaticTypes.serviceTypes)
    const serviceCategories = useSelector(state => state.StaticTypes.serviceCategories)
    const todoStatuses = useSelector(state => state.StaticTypes.todoStatuses)

    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getUsers(1, 1000))
        dispatch(getVehicles(1, 1000))
    }, [dispatch])

    useEffect(() => {
        if (serviceTypes.length === 0) {
            dispatch(getServiceTypes())
        }
        if (serviceCategories.length === 0) {
            dispatch(getServiceCategories())
        }
        if (todoStatuses.length === 0) {
            dispatch(getTodoStatuses())
        }
    }, [dispatch, serviceTypes.length, serviceCategories.length, todoStatuses.length])

    return (
        <div className='page'>
            <HandleTask
                titleCard='Dodaj zadatak'
                icon={faPlusCircle}
                users={users}
                vehicles={vehicles}
                me={me}
                serviceTypes={serviceTypes}
                serviceCategories={serviceCategories}
                todoStatuses={todoStatuses}
                getTodos={getToDo}
            />
        </div>
    )
}

export default AddTask
