import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import LoginCard from '../../components/Common/Card/LoginCard';
import InputField from './../../components/Common/InputField';
import CommonButton from './../../components/Common/CommonButton';
import ErrorAlert from './../../components/Common/ErrorAlert';
import Label from '../../components/Common/Label';
import { resetPassword } from '../../store/actions';
import SuccessAlert from '../../components/Common/SuccessAlert';
import Loader from '../../components/Common/Loader';

const initialState = {
    token: '',
    email: '',
    password: '',
    password_confirmation: ''
}

const ResetPasswords = () => {
    const [values, setValues] = useState(initialState);
    const [passwordValidation, setPasswordValidation] = useState({ error: false, message: '' });
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const fp = useSelector(state => state.ForgotPassword)
    const dispatch = useDispatch()

    const url = window.location.search;
    const token = new URLSearchParams(url).get('token')
    const email = new URLSearchParams(url).get('email')

    useEffect(() => {
        setValues({
            token,
            email
        })
    }, [token, email])

    useEffect(() => {
        if (fp.error !== '') {
            setError(true)
            setMessage(false)
        } else if (fp.message !== '') {
            setError(false)
            setMessage(true)
        } else {
            setError(false)
            setMessage(false)
        }
    }, [fp])

    const handlePasswords = (e) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        const { token, email, password, password_confirmation } = values;

        if (password.length < 8 || password_confirmation.length < 8) {
            setPasswordValidation(v => ({ ...v, error: true, message: 'Lozinka mora biti izmedju 8 i 255 karaktera' }))
        } else if (password !== password_confirmation) {
            setPasswordValidation(v => ({ ...v, error: true, message: 'Lozinke se ne podudaraju.' }))
        } else {
            dispatch(resetPassword(token, email, password, password_confirmation));
            setPasswordValidation({ error: false, message: '' })
        }

    }
    return (
        <LoginCard>
            {/* <Label
                className='reset-token-label'
                text={values.token.length > 45 && `${values.token.substring(0, 45)}...`}
            /> */}
            <Label
                className='reset-email-label'
                text={values.email}
            />
            <InputField
                label='Lozinka'
                type='password'
                value={values.password || initialState.password}
                name='password'
                onChange={handlePasswords}
                infoText='Lozinka mora imati izmedju 8 i 255 karaktera'
            />
            <InputField
                label='Ponovi lozinku'
                type='password'
                value={values.password_confirmation || initialState.password_confirmation}
                name='password_confirmation'
                onChange={handlePasswords}
                infoText='Lozinka mora imati izmedju 8 i 255 karaktera'
            />
            <CommonButton
                label='Resetuj lozinku'
                type='submit'
                variant='danger'
                onClick={handleSubmit}
            />
            {passwordValidation.error && <ErrorAlert content={passwordValidation?.message} onClose={() => setPasswordValidation(v => ({ ...v, error: false, message: '' }))} />}
            {fp.loading ?
                <Loader marginTop='40px' marginBottom='40px' />
                : error ?
                    <ErrorAlert content={fp.error} />
                    : message &&
                    <SuccessAlert content={`${fp.message} Uskoro ćete biti reditektovani na stranicu za prijavu...`}
                    />
            }
        </LoginCard>
    )
}

export default ResetPasswords
