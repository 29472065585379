import React from 'react';
import { decodeToken } from "react-jwt";
import { Redirect, Route } from 'react-router-dom';
import Header from '../components/Layouts/Header';
import Sidebar from '../components/Layouts/Sidebar';

const loggedIn = localStorage.getItem('authUser');

const decodedToken = decodeToken(loggedIn);


export const PrivateRoutes = ({ component: Component, ...rest }) => {
    if (decodedToken.exp < (Date.now() / 1000)) {
        localStorage.removeItem('authUser')
        window.location.href = '/'
    }
    return (
        <Route
            {...rest}
            render={props => {
                if (!loggedIn || loggedIn === null) {
                    return <Redirect to={{ pathname: '/', state: { from: props.location } }} />
                }
                return (
                    <div className='container-app'>
                        <Sidebar />
                        <div className='right-panel'>
                            <Header />
                            <Component {...props} />
                        </div>
                    </div>
                )
            }
            }
        />

    )
}