import React from 'react';
import { Alert, Badge } from 'react-bootstrap';

function SuccessAlert({ content, onClose }) {

    return (
        <Alert
            variant='success'
            onClose={onClose}
            dismissible={true}
            style={{ marginTop: '15px' }}
        >
            <Badge variant="success">Uspešno</Badge>
            <div>{content}</div>
        </Alert>
    )
}

export default SuccessAlert
