import React, { useState, useEffect, Fragment, useRef } from 'react'
import { useDispatch } from 'react-redux'
import { CSVLink } from 'react-csv';

import { faAddressBook } from '@fortawesome/free-solid-svg-icons'
import CardForm from '../Common/Card/CardForm'
import CardTable from '../Common/Card/CardTable'
// import ReportSearch from '../Common/Search/ReportSearch'
import { tableHeadList } from '../../helpers/common'
import Pagination from '../Common/Pagination'
import Loader from '../Common/Loader'
import { deleteServiceLogReport, getServiceLogReports, showServiceLogReport } from '../../store/actions'
// import DownloadCsvModal from '../Common/DownloadCsvModal'
import SearchByDate from '../Common/Search/SearchByDate'

const ServiceLogReportsList = (props) => {
    const [current, setCurrent] = useState(1);
    const [reportData, setReportData] = useState([]);
    const [singleReportData, setSingleReportData] = useState([]);
    const [meta, setMeta] = useState({})
    const [modal, setModal] = useState(false)
    const perPage = 10;
    const csvLink = useRef(null)
    const dispatch = useDispatch()

    useEffect(() => {
        setReportData(props.reportsList)
        setMeta(props.reportsList.meta)
    }, [props.reportsList])

    useEffect(() => {
        let arr = []
        props.singleReport?.map((report) => {
            return arr.push(
                {
                    "Datum": report.date,
                    "Naslov": report.title,
                    "Opis": report.text,
                    "Kategorija": report.service_category?.name,
                    "Potkategorije": report.service_subcategories.map(subCat => subCat.name),
                    "Godina proizvodnje": report?.vehicle?.production_year,
                    "Proizvodjač": report?.vehicle?.vehicle_manufacturer.name,
                    "Model": report?.vehicle?.vehicle_model.name,
                    "Ime vozila": report?.vehicle?.label,
                    "Registarska oznaka": report?.vehicle?.vehicle_plate,
                    "Kilometraža": report.mileage,
                    "Korišćeni delovi": report.used_parts,
                    "Cena za delove": report.price_parts,
                    "Cena usluge": report.price_work,
                    "Ukupno": report.price_total
                }
            )
        })
        arr.push({
            "Korišćeni delovi": "Ukupno:",
            "Cena za delove": props.meta?.sum_parts,
            "Cena usluge": props.meta?.sum_work,
            "Ukupno": props.meta?.sum_total
        })

        setSingleReportData(arr)
    }, [props.singleReport, props.meta])

    useEffect(() => {
        let unmounted = false
        if (modal && !unmounted) {
            setTimeout(() => setModal(false), 500)
        }
        return () => {
            unmounted = true
        }
    }, [modal])

    useEffect(() => {
        const page = meta && meta.current_page;
        setCurrent(page)
    }, [meta])

    const handleClick = (e) => {
        const page = parseInt(e.target.id)
        setCurrent(page)
        dispatch(getServiceLogReports(page, perPage))
    }

    const handleModal = (id) => {
        dispatch(showServiceLogReport(id))
        setTimeout(() => {
            setModal(true)
            csvLink.current.link.click()
        }, 500)

    }

    const deleteReport = (reportId) => {
        dispatch(deleteServiceLogReport(reportId))
        setTimeout(() => dispatch(getServiceLogReports(current, perPage)), 500)

    }

    return (
        <div>
            <CardForm
                title='Pregled Izveštaja'
                icon={faAddressBook}
            >
                <SearchByDate />
                {props.loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        {reportData.data &&
                            reportData.data.length > 0 ?
                            <CardTable
                                headListItems={tableHeadList.serviceLogReports}
                            >

                                {reportData.data.map((report, index) => {
                                    return (
                                        <tr key={`${report.id}_${index}`} className='list-grid' >
                                            <td style={{ width: '50%' }}>{report.title}</td>
                                            <td onClick={() => handleModal(report.id)} style={{ textAlign: 'center' }}>
                                                <span style={{
                                                    background: '#dc3545',
                                                    padding: '3px 10px',
                                                    color: '#fff',
                                                    borderRadius: '3px'
                                                }}>Preuzmi</span>
                                            </td>
                                            <td onClick={() => deleteReport(report.id)} style={{ textAlign: 'center' }}>
                                                <span style={{
                                                    background: '#272c33',
                                                    padding: '3px 10px',
                                                    color: '#fff',
                                                    borderRadius: '3px'
                                                }}>Obriši</span>
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                            </CardTable>
                            :
                            <div className='no-filters'>Nema izvestaja.</div>
                        }
                        {meta && meta.total > 10 &&
                            <Pagination
                                data={meta && parseInt(meta.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }
            </CardForm>
            {modal && <CSVLink data={singleReportData} filename='Izveštaj.csv' ref={csvLink} />}
        </div>
    )
}

export default React.memo(ServiceLogReportsList)