import {
    GET_FUEL_TYPES,
    GET_FUEL_TYPES_SUCCESS,
    GET_FUEL_TYPES_ERROR,
    GET_SERVICE_TYPES,
    GET_SERVICE_TYPES_SUCCESS,
    GET_SERVICE_TYPES_ERROR,
    GET_SERVICE_CATEGORIES,
    GET_SERVICE_CATEGORIES_SUCCESS,
    GET_SERVICE_CATEGORIES_ERROR,
    GET_CUSTOMER_ROLES,
    GET_CUSTOMER_ROLES_SUCCESS,
    GET_CUSTOMER_ROLES_ERROR,
    GET_VEHICLE_TYPES,
    GET_VEHICLE_TYPES_SUCCESS,
    GET_VEHICLE_TYPES_ERROR,
    GET_VEHICLE_STATUSES,
    GET_VEHICLE_STATUSES_SUCCESS,
    GET_VEHICLE_STATUSES_ERROR,
    GET_TODO_STATUSES,
    GET_TODO_STATUSES_SUCCESS,
    GET_TODO_STATUSES_ERROR,
    GET_LOGS_STATUSES,
    GET_LOGS_STATUSES_SUCCESS,
    GET_LOGS_STATUSES_ERROR,
    GET_USER_STATUSES,
    GET_USER_STATUSES_SUCCESS,
    GET_USER_STATUSES_ERROR,
} from './actionTypes';

export const getFuelTypes = () => {
    return {
        type: GET_FUEL_TYPES
    }
}

export const getFuelTypesSuccess = (types) => {
    return {
        type: GET_FUEL_TYPES_SUCCESS,
        payload: types
    }
}

export const getFuelTypesError = (message) => {
    return {
        type: GET_FUEL_TYPES_ERROR,
        payload: message
    }
}

export const getServiceTypes = () => {
    return {
        type: GET_SERVICE_TYPES
    }
}

export const getServiceTypesSuccess = (types) => {
    return {
        type: GET_SERVICE_TYPES_SUCCESS,
        payload: types
    }
}

export const getServiceTypesError = (message) => {
    return {
        type: GET_SERVICE_TYPES_ERROR,
        payload: message
    }
}

export const getServiceCategories = () => {
    return {
        type: GET_SERVICE_CATEGORIES
    }
}

export const getServiceCategoriesSuccess = (categories) => {
    return {
        type: GET_SERVICE_CATEGORIES_SUCCESS,
        payload: categories
    }
}

export const getServiceCategoriesError = (message) => {
    return {
        type: GET_SERVICE_CATEGORIES_ERROR,
        payload: message
    }
}

export const getCustomerRoles = () => {
    return {
        type: GET_CUSTOMER_ROLES
    }
}

export const getCustomerRolesSuccess = (roles) => {
    return {
        type: GET_CUSTOMER_ROLES_SUCCESS,
        payload: roles
    }
}

export const getCustomerRolesError = (message) => {
    return {
        type: GET_CUSTOMER_ROLES_ERROR,
        payload: message
    }
}

export const getVehicleTypes = () => {
    return {
        type: GET_VEHICLE_TYPES
    }
}

export const getVehicleTypesSuccess = (types) => {
    return {
        type: GET_VEHICLE_TYPES_SUCCESS,
        payload: types
    }
}

export const getVehicleTypesError = (message) => {
    return {
        type: GET_VEHICLE_TYPES_ERROR,
        payload: message
    }
}

export const getVehicleStatuses = () => {
    return {
        type: GET_VEHICLE_STATUSES
    }
}

export const getVehicleStatusesSuccess = (statuses) => {
    return {
        type: GET_VEHICLE_STATUSES_SUCCESS,
        payload: statuses
    }
}

export const getVehicleStatusesError = (message) => {
    return {
        type: GET_VEHICLE_STATUSES_ERROR,
        payload: message
    }
}

export const getTodoStatuses = () => {
    return {
        type: GET_TODO_STATUSES
    }
}

export const getTodoStatusesSuccess = (statuses) => {
    return {
        type: GET_TODO_STATUSES_SUCCESS,
        payload: statuses
    }
}

export const getTodoStatusesError = (message) => {
    return {
        type: GET_TODO_STATUSES_ERROR,
        payload: message
    }
}

export const getLogsStatuses = () => {
    return {
        type: GET_LOGS_STATUSES
    }
}

export const getLogsStatusesSuccess = (statuses) => {
    return {
        type: GET_LOGS_STATUSES_SUCCESS,
        payload: statuses
    }
}

export const getLogsStatusesError = (message) => {
    return {
        type: GET_LOGS_STATUSES_ERROR,
        payload: message
    }
}

export const getUserStatuses = () => {
    return {
        type: GET_USER_STATUSES
    }
}

export const getUserStatusesSuccess = (statuses) => {
    return {
        type: GET_USER_STATUSES_SUCCESS,
        payload: statuses
    }
}

export const getUserStatusesError = (message) => {
    return {
        type: GET_USER_STATUSES_ERROR,
        payload: message
    }
}