import React, { useState, useEffect, Fragment } from 'react';
import { faPeopleArrows, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import CardForm from '../Common/Card/CardForm';
import CommonButton from '../Common/CommonButton';
import Modal from '../Common/Modal';
import InputField from '../Common/InputField';
import Select from '../Common/Select';
import TextAreaField from '../Common/TextAreaField';
import ErrorAlert from '../Common/ErrorAlert';
import { randomNum, roles, validateEmail } from '../../helpers/common';
import SelectSearch from '../Common/SelectSearch';
import { useParams } from 'react-router';

const initialState = {
    status_id: '',
    first_name: '',
    last_name: '',
    email: '',
    phone: '',
    address: '',
    postal_code: '',
    city: '',
    country_id: '194',
    note: '',
    role_id: '',
    vehicles: [],
    customer_vehicles: []
}

const EditUser = ({ getCustomer, user, handleSubmit, customer, userStatuses, userType, delUser, listOfVehicles, customerRoles, deleteCustomerVehicle, createCustomerVehicles, updateCustomerVehicles }) => {
    const [values, setValues] = useState(initialState);
    const [error, setError] = useState(false)
    const [message, setMessage] = useState('')
    const [modal, setModal] = useState(false)
    const [vehiclesList, setVehiclesList] = useState([])
    const [filteredVehiclesList, setFilteredVehiclesList] = useState([])
    const [disabledVehicle, setDisabledVehicle] = useState(-1)
    const { id: cId } = useParams()

    useEffect(() => {
        setValues(user || initialState)
        if (user?.vehicles?.length > 0) {
            const newArr = user.vehicles.map(item => item.customer_vehicle)
            setValues(prevState => ({
                ...prevState,
                customer_vehicles: newArr
            }))
        }
    }, [user])

    useEffect(() => {
        if (customer) {
            const results = listOfVehicles.filter(({ id: id1 }) => !values.customer_vehicles?.some(({ vehicle_id: id2 }) => id2 === id1));
            setVehiclesList(listOfVehicles)
            setFilteredVehiclesList(results)
        }
    }, [customer, listOfVehicles, values.customer_vehicles])

    const handleInput = (e, index) => {
        const { name, value } = e.target;
        setValues({
            ...values,
            [name]: value
        })

        if (name === 'customer_role_id') {
            let obj = [...values.customer_vehicles]
            obj[index] = {
                ...values.customer_vehicles[index],
                customer_role_id: value
            }
            setValues(prevState => ({
                ...prevState,
                customer_vehicles: obj
            }))
        }
    }

    //vehiclessss
    const handleVehicleSelect = (option, index) => {
        let obj = [...values.customer_vehicles]
        obj[index] = {
            ...values.customer_vehicles[index],
            vehicle_id: option.id
        }

        setValues(prevState => ({ ...prevState, customer_vehicles: obj }))
    }

    const removeInputVehicle = (index, id) => {
        let val = values.customer_vehicles
        val.splice(index, 1)
        setValues(prevState => ({ ...prevState, customer_vehicles: val }))

        if (id) {
            deleteCustomerVehicle(id)
            setTimeout(() => getCustomer(cId), 200)
        }

    }

    const changeVehicleData = (index) => {
        setDisabledVehicle(index)
    }

    const handleChangeVehicle = (id, vehicle_id, customer_id, customer_role_id, index) => {
        const filterVehicleId = user.vehicles ? user.vehicles[index].customer_vehicle.vehicle_id : null;
        if (filterVehicleId !== vehicle_id && filterVehicleId !== null) {
            //create
            let arr = [{ vehicle_id, customer_id, customer_role_id }]
            createCustomerVehicles(arr)

        } else {
            //update
            updateCustomerVehicles(id, vehicle_id, customer_id, Number(customer_role_id || 40))
        }
        setTimeout(() => getCustomer(cId), 200)
    }

    //end


    const handleModal = () => setModal(!modal)

    const updateUser = () => {
        const { role_id, status_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note, customer_vehicles } = values;
        if (customer) {
            let vehiclesArr = customer_vehicles.filter(item => item.new)
            let createVehicles = vehiclesArr.map(item => ({
                vehicle_id: item.vehicle_id,
                customer_id: cId,
                customer_role_id: item.customer_role_id
            }))
            if (first_name !== '' && last_name !== '') {
                handleSubmit(
                    first_name,
                    last_name,
                    email,
                    phone,
                    address,
                    postal_code,
                    city,
                    country_id,
                    note
                )
                createCustomerVehicles(createVehicles)
                setError(false)
                setMessage('')
            } else {
                setError(true)
                setMessage('Ime i Prezime su obavezna polja.')
            }
        } else {
            if (role_id !== '' && status_id !== '' && validateEmail(email) && first_name !== '' && last_name !== '') {
                handleSubmit(
                    role_id,
                    status_id,
                    first_name,
                    last_name,
                    email,
                    phone
                )
                setError(false)
                setMessage('')
            } else if (!validateEmail(email)) {
                setError(true)
                setMessage('Email nije validan.')
            } else {
                setError(true)
                setMessage('Polja označena zvezdicom su obavezna.')
            }
        }
    }

    return (
        <CardForm
            title={`${values.first_name} ${values.last_name}`}
            icon={faPeopleArrows}
        >
            <InputField
                label='Ime'
                type='text'
                required={true}
                value={values.first_name || ''}
                onChange={handleInput}
                name='first_name'
            />
            <InputField
                label='Prezime'
                type='text'
                required={true}
                value={values.last_name || ''}
                onChange={handleInput}
                name='last_name'
            />
            <InputField
                label='Email'
                type='text'
                value={values.email || ''}
                onChange={handleInput}
                name='email'
                required={!customer && true}
            />
            <InputField
                label='Telefon'
                type='text'
                name='phone'
                value={values.phone || ''}
                onChange={handleInput}
            />
            {userType &&
                <Fragment>
                    <Select
                        label='Uloga'
                        list={roles}
                        name='role_id'
                        required={true}
                        value={values.role_id || 0}
                        onChange={handleInput}
                    />
                    <Select
                        label='Status'
                        list={userStatuses}
                        name='status_id'
                        required={true}
                        value={values.status_id || ''}
                        onChange={handleInput}
                    />
                </Fragment>
            }
            {customer &&
                <Fragment>
                    <InputField
                        label='Adresa'
                        type='text'
                        name='address'
                        value={values.address || ''}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Poštanski broj'
                        type='text'
                        name='postal_code'
                        value={values.postal_code || ''}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Grad'
                        type='text'
                        name='city'
                        value={values.city || ''}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Id države'
                        type='text'
                        name='country_id'
                        value={values.country_id || ''}
                        onChange={handleInput}
                        display='display-none'
                    />
                    <TextAreaField
                        label='Opis'
                        name='note'
                        value={values.note || ''}
                        onChange={handleInput}
                    />
                    <div className='customer-vehicles-list'>
                        <Form.Label>Vozila od klijenta</Form.Label>
                        <hr style={{ marginTop: '0' }} />
                        {values.customer_vehicles &&
                            values.customer_vehicles.map((vehicle, index) => (
                                <div className='customer-vehicle' key={`${randomNum()}-${index}`}>
                                    <div className={`${disabledVehicle !== index && !vehicle.new ? 'disabledVehicle' : ''} customer-vehicle-select`} >
                                        <SelectSearch
                                            label='Vozilo'
                                            options={filteredVehiclesList}
                                            value={vehiclesList?.filter(x => x.id === vehicle.vehicle_id)}
                                            onChange={(option) => handleVehicleSelect(option, index)}
                                            styleContainer={{ width: '55%' }}
                                        />
                                        <Select
                                            label='Vlasništvo'
                                            list={customerRoles}
                                            name='customer_role_id'
                                            value={vehicle.customer_role_id || ''}
                                            onChange={(e) => handleInput(e, index)}
                                            styleContainer={{ width: '40%' }}
                                        />
                                    </div>
                                    <CommonButton
                                        label={`${disabledVehicle !== index ? 'Izmeni' : 'Sačuvaj'}`}
                                        cls={`customer-vehicle-btns ${vehicle.new && 'hidden'}`}
                                        onClick={() => {
                                            if (disabledVehicle !== index) {
                                                changeVehicleData(index)
                                            } else {
                                                handleChangeVehicle(vehicle.id, vehicle.vehicle_id, vehicle.customer_id, vehicle.customer_role_id, index)
                                            }
                                        }}
                                    />
                                    <CommonButton
                                        label='Obriši'
                                        cls='customer-vehicle-btns'
                                        onClick={() => removeInputVehicle(index, vehicle.id)}
                                    />
                                </div>
                            ))}
                        <CommonButton
                            label={values?.customer_vehicles?.length === 0 ? 'Dodaj vozilo' : 'Dodaj još jedno vozilo'}
                            cls='add-customer-vehicle'
                            variant='primary'
                            onClick={() => {
                                if (values.customer_vehicles) {
                                    setValues(prevState => ({ ...prevState, customer_vehicles: [...prevState.customer_vehicles, { vehicle_id: '', customer_role_id: '', new: true }] }))
                                } else {
                                    setValues(prevState => ({ ...prevState, customer_vehicles: [{ vehicle_id: '', customer_role_id: '', new: true }] }))
                                }
                            }}
                        />
                    </div>
                </Fragment>
            }
            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                <CommonButton
                    label='Sačuvaj'
                    variant='danger'
                    onClick={updateUser}
                    style={{ width: '20%' }}
                    icon={faSave}
                />
                <CommonButton
                    variant="dark"
                    label='Obriši'
                    onClick={handleModal}
                    icon={faTrash}
                    style={{ width: '20%' }}
                />

            </div>
            {error && <ErrorAlert content={message} />}
            {modal && <Modal close={handleModal} deleteItem={delUser} />}
        </CardForm>
    )
}


export default EditUser
