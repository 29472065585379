import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import { Row, Col, Form } from 'react-bootstrap';
import { faCar, faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import { useParams } from 'react-router';
import InputField from '../Common/InputField';
import Select from '../Common/Select';
import CommonButton from '../Common/CommonButton';
import { formatDateForServer, randomNum } from '../../helpers/common';
import SelectSearch from '../Common/SelectSearch';
import CommonDatePicker from '../Common/CommonDatePicker';
import CardForm from '../Common/Card/CardForm';
import Loader from '../Common/Loader';
import Modal from '../Common/Modal';
import ErrorAlert from '../Common/ErrorAlert';
import { generateArrayOfYears } from './../../helpers/common';
import { getVehicleModels } from '../../store/actions';


const initialState = {
    id: '',
    error: false,
    label: '',
    mileage: '',
    vehicle_plate: '',
    registration_date: '',
    vin: '',
    production_year: '',
    small_service_mileage: '',
    engine_power_hp: '',
    engine_power_kw: '',
    engine_code: '',
    daily_mileage: '',
    big_service_mileage: '',
    engine_displacement: '',
    vehicle_model_id: '',
    vehicle_manufacturer_id: '',
    fuel_type_id: '',
    status_id: '',
    vehicle_type_id: '',
    note: '',
    create_user_id: '',
    business_id: '',
    customer_vehicles: []
}

const VehicleForm = ({
    title,
    vehicle,
    submitVehicle,
    loading,
    vehicleCreated,
    manufacturers,
    fuelTypes,
    vehicleStatuses,
    vehicleTypes,
    editVehicle,
    deleteVehicle,
    customerRoles,
    deleteCustomerVehicle,
    createCustomerVehicles,
    updateCustomerVehicles,
    getVehicle,
    listOfCustomers
}) => {

    const [values, setValues] = useState(initialState);
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(false)
    const [filteredCustomersList, setFilteredCustomersList] = useState([])
    const [customersList, setCustomersList] = useState([])
    const [disabledCustomer, setDisabledCustomer] = useState(-1)
    const { id: cId } = useParams()
    const me = useSelector(state => state.Me.me);
    const models = useSelector(state => state.VehicleModels.models);
    const dispatch = useDispatch();
    const history = useHistory();

    const years = generateArrayOfYears()

    useEffect(() => {
        setValues(vehicle || initialState)
        if (vehicle?.customers?.length > 0) {
            const newArr = vehicle.customers.map(item => item.customer_vehicle)
            setValues(prevState => ({
                ...prevState,
                customer_vehicles: newArr
            }))
        }
    }, [vehicle])

    useEffect(() => {
        if (history.location?.state?.customer_id) {
            setValues(prevState => ({
                ...prevState,
                customer_vehicles: [...prevState.customer_vehicles, { vehicle_id: null, customer_id: Number(history.location?.state?.customer_id), customer_role_id: 40, new: true }]
            }))
        }
    }, [history.location?.state?.customer_id])

    useEffect(() => {
        dispatch(getVehicleModels(values.vehicle_manufacturer_id))
    }, [dispatch, values.vehicle_manufacturer_id])

    useEffect(() => {
        if (listOfCustomers) {
            let newCustomers = listOfCustomers?.map(item => ({ label: `${item.first_name} ${item.last_name}`, value: item.first_name, ...item }))
            const results = newCustomers?.filter(({ id: id1 }) => !values.customer_vehicles?.some(({ customer_id: id2 }) => id2 === id1));
            setCustomersList(newCustomers)
            setFilteredCustomersList(results)
        }
    }, [listOfCustomers, values.customer_vehicles])

    const handleInput = (e, index) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (name === 'customer_role_id') {
            let obj = [...values.customer_vehicles]
            obj[index] = {
                ...values.customer_vehicles[index],
                customer_role_id: value
            }
            setValues(prevState => ({
                ...prevState,
                customer_vehicles: obj
            }))
        }
    }

    const handleDate = (e) => {
        const date = formatDateForServer(e)
        setValues({
            ...values,
            registration_date: date
        })
    }

    const handleOnChange = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    //customer relation
    const handleVehicleSelect = (option, index) => {
        let obj = [...values.customer_vehicles]
        obj[index] = {
            ...values.customer_vehicles[index],
            customer_id: option.id
        }

        setValues(prevState => ({ ...prevState, customer_vehicles: obj }))
    }

    const removeInputVehicle = (index, id) => {
        let val = values.customer_vehicles
        val.splice(index, 1)
        setValues(prevState => ({ ...prevState, customer_vehicles: val }))

        if (id) {
            deleteCustomerVehicle(id)
        }

    }

    const changeVehicleData = (index) => {
        setDisabledCustomer(index)
    }

    const handleChangeVehicle = (id, vehicle_id, customer_id, customer_role_id, index) => {
        const filterVehicleId = vehicle.customers ? vehicle.customers[index].customer_vehicle.customer_id : null;
        if (filterVehicleId !== customer_id && filterVehicleId !== null) {
            //create
            let arr = [{ vehicle_id, customer_id, customer_role_id }]
            createCustomerVehicles(arr)

        } else {
            //update
            updateCustomerVehicles(id, vehicle_id, customer_id, Number(customer_role_id || 40))
        }
        setTimeout(() => getVehicle(cId), 200)
        setDisabledCustomer(-1)
    }
    //end customer relation

    const handleSubmit = () => {
        const { id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, customer_vehicles, registration_date } = values;
        const { business_id, id: create_user_id } = me
        const productionYear = production_year ? production_year.toString() : '';
        const dailyMileage = Number(daily_mileage)
        if (vehicle_manufacturer_id !== '' && vehicle_model_id !== '' && fuel_type_id !== '' && status_id !== '' && vehicle_type_id !== '' && label !== '' && mileage !== '') {
            if (editVehicle) {
                let customerArr = customer_vehicles.filter(item => item.new)
                let createCustomers = customerArr.map(item => ({
                    vehicle_id: cId,
                    customer_id: Number(item.customer_id),
                    customer_role_id: Number(item.customer_role_id)
                }))
                dispatch(submitVehicle(
                    id,
                    business_id,
                    create_user_id,
                    vehicle_model_id,
                    vehicle_manufacturer_id,
                    fuel_type_id,
                    status_id,
                    vehicle_type_id,
                    label,
                    vehicle_plate,
                    productionYear,
                    mileage,
                    vin,
                    engine_code,
                    engine_displacement,
                    engine_power_hp,
                    engine_power_kw,
                    dailyMileage,
                    small_service_mileage,
                    big_service_mileage,
                    note,
                    registration_date
                ))

                createCustomerVehicles(createCustomers)
            } else {
                dispatch(submitVehicle(
                    business_id,
                    create_user_id,
                    vehicle_model_id,
                    vehicle_manufacturer_id,
                    fuel_type_id,
                    status_id,
                    vehicle_type_id,
                    label,
                    vehicle_plate,
                    productionYear,
                    mileage,
                    vin,
                    engine_code,
                    engine_displacement,
                    engine_power_hp,
                    engine_power_kw,
                    dailyMileage,
                    small_service_mileage,
                    big_service_mileage,
                    note,
                    registration_date
                ))

                setTimeout(() => {
                    let vehicleId = Number(localStorage.getItem('vehicle_id'))
                    const customersData = customer_vehicles.map(v => (
                        {
                            vehicle_id: vehicleId,
                            customer_id: v.customer_id,
                            customer_role_id: Number(v.customer_role_id)
                        }))
                    dispatch(createCustomerVehicles(customersData))
                    localStorage.removeItem('vehicle_id')
                    if (vehicleCreated) {
                        history.push('/pregled-garaže')
                    }
                }, 500)
            }
            setError(false)
        } else {
            setError(true)
        }

    }

    const handleModal = () => setModal(!modal)

    const delVehicle = () => {
        dispatch(deleteVehicle(values.id))
        history.push('/pregled-garaže')
    }

    return (
        <React.Fragment>
            {loading ?
                <Loader />
                :
                <CardForm
                    className='add-vehicle-form'
                    title={title || values.label || initialState.label}
                    icon={faCar}
                >
                    <Row>
                        <Col sm={12} md={3}>
                            <Select
                                label='Godina proizvodnje'
                                list={years || []}
                                name='production_year'
                                onChange={handleOnChange}
                                value={values.production_year || initialState.production_year}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Select
                                label='Proizvodjač'
                                required={true}
                                list={manufacturers || []}
                                name='vehicle_manufacturer_id'
                                onChange={handleOnChange}
                                value={values.vehicle_manufacturer_id || ''}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Select
                                label='Model'
                                required={true}
                                list={models || []}
                                name='vehicle_model_id'
                                onChange={handleOnChange}
                                value={values.vehicle_model_id || ''}
                                disabled={values.vehicle_manufacturer_id === '' ? true : false}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Oznaka'
                                required={true}
                                name='label'
                                placeholder='1.5DCi, 2.0TDI'
                                value={values.label || initialState.label}
                                onChange={handleInput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Kilometraža'
                                name='mileage'
                                required={true}
                                value={values.mileage || initialState.mileage}
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Zapremina motora'
                                value={values.engine_displacement || initialState.engine_displacement}
                                name='engine_displacement'
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Kilovata'
                                name='engine_power_kw'
                                value={values.engine_power_kw || initialState.engine_power_kw}
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Konjska snaga'
                                name='engine_power_hp'
                                value={values.engine_power_hp || initialState.engine_power_hp}
                                onChange={handleInput}

                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Registracija'
                                value={values.vehicle_plate || initialState.vehicle_plate}
                                name='vehicle_plate'
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <CommonDatePicker
                                cardText='Datum registracije'
                                date={values.registration_date}
                                handleDate={handleDate}
                                className='picker-width-100'
                                name='registration_date'
                                style={{ marginBottom: '7px' }}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Select
                                label='Tip vozila'
                                list={vehicleTypes || []}
                                required={true}
                                name='vehicle_type_id'
                                onChange={handleOnChange}
                                value={values.vehicle_type_id || ''}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Mali servis'
                                name='small_service_mileage'
                                value={values.small_service_mileage || initialState.small_service_mileage}
                                infoText='Na koliko kilometara je odradjen mali servis'
                                onChange={handleInput}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Veliki servis'
                                value={values.big_service_mileage || initialState.big_service_mileage}
                                name='big_service_mileage'
                                infoText='Na koliko kilometara je odradjen veliki servis'
                                onChange={handleInput}
                            />

                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Kod motora'
                                value={values.engine_code || initialState.engine_code}
                                name='engine_code'
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Select
                                label='Gorivo'
                                list={fuelTypes || []}
                                required={true}
                                name='fuel_type_id'
                                onChange={handleOnChange}
                                value={values.fuel_type_id || ''}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <Select
                                label='Status vozila'
                                list={vehicleStatuses || []}
                                required={true}
                                name='status_id'
                                onChange={handleOnChange}
                                value={values.status_id || ''}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Dnevna kilometraža'
                                value={values.daily_mileage || initialState.daily_mileage}
                                name='daily_mileage'
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={3}>
                            <InputField
                                label='Broj šasije'
                                value={values.vin || initialState.vin}
                                name='vin'
                                infoText='Nije obavezno polje'
                                onChange={handleInput}
                            />
                        </Col>
                        <Col sm={12} md={6}>
                            <InputField
                                label='Napomena'
                                value={values.note || initialState.note}
                                name='note'
                                onChange={handleInput}
                            />
                        </Col>
                    </Row>
                    <div className='customer-vehicles-list'>
                        <Form.Label>Klijenti vozila</Form.Label>
                        <hr style={{ marginTop: '0' }} />
                        {values.customer_vehicles &&
                            values.customer_vehicles.map((customer, index) => (
                                <div className='customer-vehicle' key={`${randomNum()}-${index}`}>
                                    <div className={`${disabledCustomer !== index && !customer.new ? 'disabledVehicle' : ''} customer-vehicle-select`} >
                                        <SelectSearch
                                            label='Klijent'
                                            options={filteredCustomersList}
                                            value={customersList?.filter(x => x.id === customer.customer_id)}
                                            onChange={(option) => handleVehicleSelect(option, index)}
                                            styleContainer={{ width: '55%' }}
                                        />
                                        <Select
                                            label='Vlasništvo'
                                            list={customerRoles}
                                            name='customer_role_id'
                                            value={customer.customer_role_id || ''}
                                            onChange={(e) => handleInput(e, index)}
                                            styleContainer={{ width: '40%' }}
                                        />
                                    </div>
                                    <CommonButton
                                        label={`${disabledCustomer !== index ? 'Izmeni' : 'Sačuvaj'}`}
                                        cls={`customer-vehicle-btns ${customer.new && 'hidden'}`}
                                        onClick={() => {
                                            if (disabledCustomer !== index) {
                                                changeVehicleData(index)
                                            } else {
                                                handleChangeVehicle(customer.id, customer.vehicle_id, customer.customer_id, customer.customer_role_id, index)
                                            }
                                        }}
                                    />
                                    <CommonButton
                                        label='Obriši'
                                        cls='customer-vehicle-btns'
                                        onClick={() => removeInputVehicle(index, customer.id)}
                                    />
                                </div>
                            ))}
                        <CommonButton
                            label={values.customer_vehicles?.length === 0 ? 'Dodaj klijenta' : 'Dodaj još jednog klijenta'}
                            cls='add-customer-vehicle'
                            variant='primary'
                            onClick={() => {
                                if (values.customer_vehicles) {
                                    setValues(prevState => ({ ...prevState, customer_vehicles: [...prevState.customer_vehicles, { vehicle_id: '', customer_role_id: '', new: true }] }))
                                } else {
                                    setValues(prevState => ({ ...prevState, customer_vehicles: [{ vehicle_id: '', customer_role_id: '', new: true }] }))
                                }

                            }}
                        />
                    </div>


                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CommonButton
                            variant='danger'
                            label='Sačuvaj'
                            icon={faSave}
                            style={editVehicle ? { width: '20%' } : { width: '100%' }}
                            onClick={handleSubmit}
                        />
                        {editVehicle &&
                            <CommonButton
                                variant='dark'
                                label='Obriši'
                                icon={faTrash}
                                style={{ width: '20%' }}
                                onClick={handleModal}
                            />
                        }
                    </div>
                    {modal && <Modal close={handleModal} deleteItem={delVehicle} />}
                    {error && <ErrorAlert content='Polja označena zvezdicom su obavezna.' />}
                </CardForm>
            }
        </React.Fragment>
    )
}

export default VehicleForm
