import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    users: [],
    user: {},
    message: '',
    error: ''
}

const Users = (state = initialState, action) => {
    switch (action.type) {
        case GET_USERS:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case GET_USERS_SUCCESS:
            return {
                ...state,
                loading: false,
                users: action.payload
            }
        case GET_USERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CREATE_USER:
            return {
                ...state,
                loading: true
            }
        case CREATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                users: [action.payload.data, ...state.users.data]
            }
        case CREATE_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_USER:
            return {
                ...state,
                loading: true
            }
        case GET_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.data
            }
        case GET_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_USER:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                user: action.payload.data
            }
        case UPDATE_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_USER:
            return {
                ...state,
                loading: true
            }
        case DELETE_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                users: [...state.users.data]
            }
        case DELETE_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_USER_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case UPDATE_USER_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                message: action.payload.messages.general[0]
            }
        case UPDATE_USER_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default Users