import {
    GET_TODO,
    GET_TODO_SUCCESS,
    GET_TODO_ERROR,
    ADD_TODO,
    ADD_TODO_SUCCESS,
    ADD_TODO_ERROR,
    GET_TODO_TASK,
    GET_TODO_TASK_SUCCESS,
    GET_TODO_TASK_ERROR,
    DELETE_TODO_TASK,
    DELETE_TODO_TASK_SUCCESS,
    DELETE_TODO_TASK_ERROR,
    UPDATE_TODO_TASK,
    UPDATE_TODO_TASK_SUCCESS,
    UPDATE_TODO_TASK_ERROR
} from './actionTypes';

export const getToDo = (page, perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id) => {
    return {
        type: GET_TODO,
        payload: { page, perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id }
    }
}

export const getTodoSuccess = (message) => {
    return {
        type: GET_TODO_SUCCESS,
        payload: message
    }
}

export const getTodoError = (message) => {
    return {
        type: GET_TODO_ERROR,
        payload: message
    }
}

export const addToDo = (business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories) => {
    return {
        type: ADD_TODO,
        payload: { business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories }
    }
}

export const addTodoSuccess = (task) => {
    return {
        type: ADD_TODO_SUCCESS,
        payload: task
    }
}

export const addTodoError = (message) => {
    return {
        type: ADD_TODO_ERROR,
        payload: message
    }
}

export const getToDoTask = (id) => {
    return {
        type: GET_TODO_TASK,
        payload: { id }
    }
}

export const getToDoTaskSuccess = (task) => {
    return {
        type: GET_TODO_TASK_SUCCESS,
        payload: task
    }
}

export const getToDoTaskError = (message) => {
    return {
        type: GET_TODO_TASK_ERROR,
        payload: message
    }
}

export const deleteToDoTask = (id) => {
    return {
        type: DELETE_TODO_TASK,
        payload: { id }
    }
}

export const deleteToDoTaskSuccess = (message) => {
    return {
        type: DELETE_TODO_TASK_SUCCESS,
        payload: message
    }
}

export const deleteToDoTaskError = (message) => {
    return {
        type: DELETE_TODO_TASK_ERROR,
        payload: message
    }
}

export const updateToDoTask = (id, business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories) => {
    return {
        type: UPDATE_TODO_TASK,
        payload: { id, business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories }
    }
}

export const updateToDoTaskSuccess = (task) => {
    return {
        type: UPDATE_TODO_TASK_SUCCESS,
        payload: task
    }
}

export const updateToDoTaskError = (message) => {
    return {
        type: UPDATE_TODO_TASK_ERROR,
        payload: message
    }
}