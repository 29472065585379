import React, { Fragment, useState } from 'react';
import { Link, NavLink, withRouter } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faSlidersH, faCar, faWrench, faClipboard, faListAlt, faUsers, faPeopleArrows, faUser, faCog, faWarehouse, faIdCard, faPowerOff, faParking, faBars, faPlusCircle } from '@fortawesome/free-solid-svg-icons';

import sidebarLogo from './../../assets/images/logo-vozi-rs-new.png';
import { checkUserType, toggleBurgerMenu } from '../../helpers/common';

import { logout } from './../../store/actions';

const Sidebar = (props) => {
    const [toggleMenu, setToggleMenu] = useState(true);
    const dispatch = useDispatch();
    const me = useSelector(state => state.Me.me)

    const handleSidebar = () => {
        toggleBurgerMenu(toggleMenu);
        setToggleMenu(!toggleMenu);
    }

    const closeMenu = (e) => {
        if (e.target.classList.value === 'sidebar-menu-item') {
            if (window.innerWidth < 992) {
                setToggleMenu(!toggleMenu);
                toggleBurgerMenu(toggleMenu);
            }
        }
    }

    const handleLogout = (e) => {
        e.preventDefault();
        localStorage.removeItem('authUser')
        dispatch(logout());
        window.location.href = '/';
    }

    return (
        <React.Fragment>
            <div className="sidebar-menu-button" onClick={handleSidebar}>
                <FontAwesomeIcon icon={faBars} />
            </div>
            <div className='sidebar'>
                <Link to={{ pathname: '/kontrolna-tabla' }}>
                    <img src={sidebarLogo} alt='sidebarLogo' width='100%' className='sidebar-menu-item' />
                </Link>
                <ul onClick={closeMenu}>
                    <li>
                        <NavLink to={{ pathname: '/kontrolna-tabla' }}>
                            <FontAwesomeIcon icon={faSlidersH} /> <span className='sidebar-menu-item'>Kontrolna tabla</span>
                        </NavLink>
                    </li>
                    {checkUserType.admin(me?.role_id) &&
                        <Fragment>
                            <h3 className="menu-title"><FontAwesomeIcon icon={faWarehouse} /> <span className='sidebar-menu-item'>Moja garaža</span></h3>
                            <li>
                                <NavLink to={{ pathname: '/pregled-garaže' }}>
                                    <FontAwesomeIcon icon={faCar} /> <span className='sidebar-menu-item'>Pregled garaže</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/dodavanje-vozila' }}>
                                    <FontAwesomeIcon icon={faPlusCircle} /> <span className='sidebar-menu-item'>Dodavanje vozila</span>
                                </NavLink>
                            </li>
                            <h3 className="menu-title"><FontAwesomeIcon icon={faListAlt} /> <span className='sidebar-menu-item'>Knjižica</span></h3>
                            <li>
                                <NavLink to={{ pathname: '/istorija-zapisa' }}>
                                    <FontAwesomeIcon icon={faWrench} /> <span className='sidebar-menu-item'>Istorija zapisa</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/dodavanje-zapisa' }}>
                                    <FontAwesomeIcon icon={faPlusCircle} /> <span className='sidebar-menu-item'>Dodavanje zapisa</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/pregled-izveštaja' }}>
                                    <FontAwesomeIcon icon={faClipboard} /> <span className='sidebar-menu-item'>Pregled Izveštaja</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/novi-izveštaj' }}>
                                    <FontAwesomeIcon icon={faPlusCircle} /> <span className='sidebar-menu-item'>Novi izveštaj</span>
                                </NavLink>
                            </li>
                        </Fragment>
                    }
                    <h3 className="menu-title"><FontAwesomeIcon icon={faUsers} /> <span className='sidebar-menu-item'>Radni zadaci</span></h3>
                    <li>
                        <NavLink to={{ pathname: '/pregled-radnih-zadataka' }}>
                            <FontAwesomeIcon icon={faPeopleArrows} /> <span className='sidebar-menu-item'>Pregled radnih zadataka</span>
                        </NavLink>
                    </li>
                    <li>
                        <NavLink to={{ pathname: '/dodavanje-zadatka' }}>
                            <FontAwesomeIcon icon={faPlusCircle} /> <span className='sidebar-menu-item'>Dodavanje zadatka</span>
                        </NavLink>
                    </li>
                    {checkUserType.admin(me?.role_id) &&
                        <Fragment>
                            <h3 className="menu-title"><FontAwesomeIcon icon={faUsers} /> <span className='sidebar-menu-item'>Klijenti</span></h3>
                            <li>
                                <NavLink to={{ pathname: '/pregled-klijenata' }}>
                                    <FontAwesomeIcon icon={faPeopleArrows} /> <span className='sidebar-menu-item'>Pregled klijenata</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/dodavanje-klijenta' }}>
                                    <FontAwesomeIcon icon={faPlusCircle} /> <span className='sidebar-menu-item'>Dodavanje klijenta</span>
                                </NavLink>
                            </li>
                        </Fragment>
                    }
                    <h3 className="menu-title"><FontAwesomeIcon icon={faCog} /> <span className='sidebar-menu-item'>Profili</span></h3>
                    <li>
                        <NavLink to={{ pathname: '/profil' }}>
                            <FontAwesomeIcon icon={faIdCard} /> <span className='sidebar-menu-item'>Moj profil</span>
                        </NavLink>
                    </li>
                    {checkUserType.admin(me?.role_id) &&
                        <Fragment>
                            <li>
                                <NavLink to={{ pathname: '/korisnici' }}>
                                    <FontAwesomeIcon icon={faUser} /> <span className='sidebar-menu-item'>Korisnici</span>
                                </NavLink>
                            </li>
                            <li>
                                <NavLink to={{ pathname: '/profil-servisa' }}>
                                    <FontAwesomeIcon icon={faParking} /> <span className='sidebar-menu-item'>Profil servisa</span>
                                </NavLink>
                            </li>
                        </Fragment>
                    }
                    <br />
                    <li className='logout' onClick={handleLogout}>
                        <FontAwesomeIcon icon={faPowerOff} /> <span className='sidebar-menu-item'>Odjava</span>
                    </li>

                </ul>
            </div>
        </React.Fragment>
    )
}

export default withRouter(Sidebar)
