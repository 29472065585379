import {
    GET_SERVICE_LOGS,
    GET_SERVICE_LOGS_SUCCESS,
    GET_SERVICE_LOGS_ERROR,
    CREATE_SERVICE_LOG,
    CREATE_SERVICE_LOG_SUCCESS,
    CREATE_SERVICE_LOG_ERROR,
    GET_SERVICE_LOG,
    GET_SERVICE_LOG_SUCCESS,
    GET_SERVICE_LOG_ERROR,
    UPDATE_SERVICE_LOG,
    UPDATE_SERVICE_LOG_SUCCESS,
    UPDATE_SERVICE_LOG_ERROR,
    DELETE_SERVICE_LOG,
    DELETE_SERVICE_LOG_SUCCESS,
    DELETE_SERVICE_LOG_ERROR,
    GET_SERVICE_LOG_MEDIA,
    GET_SERVICE_LOG_MEDIA_SUCCESS,
    GET_SERVICE_LOG_MEDIA_ERROR,
    DELETE_SERVICE_LOG_MEDIA,
    DELETE_SERVICE_LOG_MEDIA_SUCCESS,
    DELETE_SERVICE_LOG_MEDIA_ERROR,
    ADD_SERVICE_LOG_MEDIA,
    ADD_SERVICE_LOG_MEDIA_SUCCESS,
    ADD_SERVICE_LOG_MEDIA_ERROR,
    GET_SERVICE_LOG_REPORTS,
    GET_SERVICE_LOG_REPORTS_SUCCESS,
    GET_SERVICE_LOG_REPORTS_ERROR,
    ADD_SERVICE_LOG_REPORT,
    ADD_SERVICE_LOG_REPORT_SUCCESS,
    ADD_SERVICE_LOG_REPORT_ERROR,
    DELETE_SERVICE_LOG_REPORT,
    DELETE_SERVICE_LOG_REPORT_SUCCESS,
    DELETE_SERVICE_LOG_REPORT_ERROR,
    SHOW_SERVICE_LOG_REPORT,
    SHOW_SERVICE_LOG_REPORT_SUCCESS,
    SHOW_SERVICE_LOG_REPORT_ERROR,
    CLEAR_SERVICE_LOG_REPORTS
} from './actionTypes';

const initialState = {
    loading: false,
    logs: [],
    log: [],
    media: [],
    reports: [],
    report: [],
    error: ''
}

const ServiceLogs = (state = initialState, action) => {
    switch (action.type) {
        case GET_SERVICE_LOGS:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                logs: action.payload
            }
        case GET_SERVICE_LOGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CREATE_SERVICE_LOG:
            return {
                ...state,
                loading: true
            }
        case CREATE_SERVICE_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                logs: [action.payload.data, ...state.logs.data]
            }
        case CREATE_SERVICE_LOG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_SERVICE_LOG:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                log: action.payload.data
            }
        case GET_SERVICE_LOG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_SERVICE_LOG:
            return {
                ...state,
                loading: true
            }
        case UPDATE_SERVICE_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                log: action.payload.data
            }
        case UPDATE_SERVICE_LOG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_SERVICE_LOG:
            return {
                ...state,
                loading: true
            }
        case DELETE_SERVICE_LOG_SUCCESS:
            return {
                ...state,
                loading: false,
                logs: [...state.logs.data]
            }
        case DELETE_SERVICE_LOG_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        //media
        case GET_SERVICE_LOG_MEDIA:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_LOG_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                media: action.payload.data
            }
        case GET_SERVICE_LOG_MEDIA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_SERVICE_LOG_MEDIA:
            return {
                ...state,
                loading: true
            }
        case ADD_SERVICE_LOG_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                media: action.payload.data
            }
        case ADD_SERVICE_LOG_MEDIA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_SERVICE_LOG_MEDIA:
            return {
                ...state,
                loading: true
            }
        case DELETE_SERVICE_LOG_MEDIA_SUCCESS:
            return {
                ...state,
                loading: false,
                media: [...state.media.data]
            }
        case DELETE_SERVICE_LOG_MEDIA_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        //service log reports
        case GET_SERVICE_LOG_REPORTS:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_LOG_REPORTS_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: action.payload
            }
        case GET_SERVICE_LOG_REPORTS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_SERVICE_LOG_REPORT:
            return {
                ...state,
                loading: true
            }
        case ADD_SERVICE_LOG_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: [action.payload.data, ...state.reports.data]
            }
        case ADD_SERVICE_LOG_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case SHOW_SERVICE_LOG_REPORT:
            return {
                ...state,
                loading: true
            }
        case SHOW_SERVICE_LOG_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                report: action.payload
            }
        case SHOW_SERVICE_LOG_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_SERVICE_LOG_REPORT:
            return {
                ...state,
                loading: true
            }
        case DELETE_SERVICE_LOG_REPORT_SUCCESS:
            return {
                ...state,
                loading: false,
                reports: [...state.media]
            }
        case DELETE_SERVICE_LOG_REPORT_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CLEAR_SERVICE_LOG_REPORTS:
            return {
                ...state,
                logs: []
            }
        default:
            return state
    }
}

export default ServiceLogs