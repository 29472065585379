import {
    LOGIN_USER,
    LOGIN_USER_SUCCESS,
    LOGIN_USER_ERROR
} from './actionTypes';

let initialState = {
    loading: false,
    user: null,
    error: "",
}

const Login = (state = initialState, action) => {
    switch (action.type) {
        case LOGIN_USER:
            return {
                ...state,
                loading: true,
                error: ""
            }
        case LOGIN_USER_SUCCESS:
            return {
                ...state,
                loading: false,
                error: "",
                user: action.payload.data
            }
        case LOGIN_USER_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default Login