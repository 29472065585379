import React from 'react';
import { connect } from 'react-redux';
import AddUser from '../../components/Forms/AddUser';

import {
    createCustomer,
    // getCustomerRoles, 
    getVehicles,
    createCustomerVehiclesData
} from '../../store/actions';


class AddCustomer extends React.PureComponent {
    constructor(props) {
        super(props)
        this.state = {
            vehicles: []
        }
    }

    componentDidMount() {
        // this.props.getCustomerRoles()
        this.props.getVehicles(1, 1000)
    }

    componentDidUpdate(prevState, prevProps) {
        if (prevState.vehicles !== this.props.vehicles) {
            let v = this.props.vehicles?.map(item => {
                item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`
                return item
            })
            this.setState({
                ...prevState,
                vehicles: v
            })
        }
    }

    render() {
        return (
            <div className='page'>
                <AddUser
                    title='Dodavanje klijenta'
                    customer={true}
                    addCustomer={this.props.createCustomer}
                    me={this.props.me}
                    customerRoles={this.props.customerRoles}
                    vehiclesList={this.state.vehicles}
                    createCustomerVehicles={this.props.createCustomerVehiclesData}
                />
            </div>
        )
    }

}

const mapStateToProps = (state) => {
    return {
        me: state.Me.me,
        customerRoles: state.StaticTypes.customerRoles,
        vehicles: state.Vehicles.vehicles.data
    }
}

export default connect(mapStateToProps, {
    createCustomer,
    // getCustomerRoles, 
    getVehicles,
    createCustomerVehiclesData
})(AddCustomer)
