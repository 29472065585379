import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import VehiclesList from '../../components/Lists/VehiclesList';

import { getVehicles, deleteVehicle } from './../../store/actions';

class Vehicles extends PureComponent {

    componentDidMount() {
        this.props.getVehicles(1, 10)
    }

    render() {
        return (
            <div className='page'>
                <VehiclesList
                    title='Lista vozila'
                    vehiclesList={this.props.vehicles}
                    deleteVehicle={this.props.deleteVehicle}
                    loading={this.props.loading}
                    meta={this.props.meta}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Vehicles.loading,
        vehicles: state.Vehicles.vehicles.data,
        meta: state.Vehicles.vehicles.meta
    }
}

export default connect(mapStateToProps, { getVehicles, deleteVehicle })(Vehicles)
