import React from "react";
import { Route, Switch } from "react-router-dom";

import { PrivateRoutes } from "./../helpers/PrivateRoutes";

import Login from "./../pages/Login";
import Dashboard from "./../pages/Dashboard";
import Vehicles from "../pages/Vehicles/Vehicles";
import ServiceBook from "../pages/ServiceBook";
import Users from "../pages/Users";
import SingleUser from "../pages/Users/SingleUser";
import Customers from "../pages/Customers";
import MyProfile from "../pages/Profiles/MyProfile";
import ServiceProfile from "../pages/Profiles/ServiceProfile";
import Reports from "../pages/Reports";
import AddVehicles from "../pages/Vehicles/AddVehicles";
import ForgotPassword from "../pages/Login/ForgotPassword";
import ResetPasswords from "../pages/Login/ResetPasswords";
import SingleVehicle from "../pages/Vehicles/SingleVehicle";
import SingleReport from "../pages/Reports/SingleReport";
import SingleCustomer from "../pages/Customers/SingleCustomer";
import SingleTask from "../pages/Dashboard/SingleTask";
import NotFound from "../pages/NotFound";
import AddCustomer from "../pages/Customers/AddCustomer";
import TodoPage from "../pages/Todo";
import AddTask from "../pages/Todo/AddTask";
import AddReport from "../pages/Reports/AddReport";
import ReportMedia from "../pages/Reports/ReportMedia";
import ServiceLogReports from "../pages/Reports/ServiceLogReports";
import Welcome from "../pages/Welcome/Welcome";
import PrivacyPolicy from "../pages/Login/PrivacyPolicy";

const Routes = () => {
    return (
        <Switch>
            <Route path='/' exact component={Login} />
            <Route path='/zaboravljena-lozinka' exact component={ForgotPassword} />
            <Route path='/api/password/:token' exact component={ResetPasswords} />
            <Route path='/dobrodošli' exact component={Welcome} />
            <Route path='/dobrodosli' exact component={Welcome} />
            <Route path='/politika-privatnosti' exact component={PrivacyPolicy} />
            <PrivateRoutes path='/kontrolna-tabla' exact component={Dashboard} />
            <PrivateRoutes path='/pregled-garaže' exact component={Vehicles} />
            <PrivateRoutes path='/pregled-garaže/:id' exact component={SingleVehicle} />
            <PrivateRoutes path='/dodavanje-vozila' exact component={AddVehicles} />
            <PrivateRoutes path='/istorija-zapisa' exact component={ServiceBook} />
            <PrivateRoutes path='/novi-izveštaj' exact component={Reports} />
            <PrivateRoutes path='/dodavanje-zapisa' exact component={AddReport} />
            <PrivateRoutes path='/istorija-zapisa/:id' exact component={SingleReport} />
            <PrivateRoutes path='/istorija-zapisa/:id/media' exact component={ReportMedia} />
            <PrivateRoutes path='/pregled-izveštaja' exact component={ServiceLogReports} />
            <PrivateRoutes path='/pregled-radnih-zadataka' exact component={TodoPage} />
            <PrivateRoutes path='/dodavanje-zadatka' exact component={AddTask} />
            <PrivateRoutes path='/pregled-radnih-zadataka/:id' exact component={SingleTask} />
            <PrivateRoutes path='/korisnici' exact component={Users} />
            <PrivateRoutes path='/korisnici/:id' exact component={SingleUser} />
            <PrivateRoutes path='/pregled-klijenata' exact component={Customers} />
            <PrivateRoutes path='/dodavanje-klijenta' exact component={AddCustomer} />
            <PrivateRoutes path='/pregled-klijenata/:id' exact component={SingleCustomer} />
            <PrivateRoutes path='/profil' exact component={MyProfile} />
            <PrivateRoutes path='/profil-servisa' exact component={ServiceProfile} />
            <Route path='*' exact={true} component={NotFound} />
        </Switch>
    );
}

export default Routes;
