import React from 'react';
import { useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import ProfileBanner from '../../components/Common/ProfileBanner';
import ChangePassword from '../../components/Forms/ChangePassword';

import { updateUserPassword } from '../../store/actions'

const MyProfile = () => {
    const me = useSelector(state => state.Me.me)

    return (
        <div className='page my-profile'>
            <ProfileBanner
                src={undefined}
                userName={`${me?.first_name} ${me?.last_name}`}
                email={me?.email}
            />
            <Row>
                <Col sm={12} xl={3}>
                    <ChangePassword
                        title='Promena lozinke'
                        updatePassword={updateUserPassword}
                        id={me?.id}
                    />
                </Col>
            </Row>


        </div>
    )
}

export default MyProfile
