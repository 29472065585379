import React, { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import ServiceLogReportsList from '../../components/Lists/ServiceLogReportsList'
import { getServiceLogReports, getServiceTypes, getServiceCategories, getCustomers, getVehicles } from '../../store/actions'

const ServiceLogReports = (props) => {
    const state = useSelector(state => state)
    const dispatch = useDispatch()

    useEffect(() => {
        dispatch(getServiceLogReports(1, 10))
        dispatch(getCustomers(1, 1000))
        dispatch(getVehicles(1, 1000))
    }, [dispatch])

    useEffect(() => {
        if (state.StaticTypes.serviceTypes.length === 0) {
            dispatch(getServiceTypes())
        }
        if (state.StaticTypes.serviceCategories.length === 0) {
            dispatch(getServiceCategories())
        }
        // dispatch(getVehicleManufacturers())
    }, [dispatch, state.StaticTypes.serviceTypes.length, state.StaticTypes.serviceCategories.length])

    return (
        <div className='page'>
            <ServiceLogReportsList
                reportsList={state.ServiceLogs.reports}
                singleReport={state.ServiceLogs.report.data}
                meta={state.ServiceLogs.report.meta}
                loading={state.ServiceLogs.loading}
                serviceTypes={state.StaticTypes.serviceTypes}
                serviceCategories={state.StaticTypes.serviceCategories}
                manufacturers={state.VehicleManufacturers.manufacturers}
                customers={state.Customers.customers.data}
                vehicles={state.Vehicles.vehicles.data}
            />
        </div>
    )
}

export default ServiceLogReports
