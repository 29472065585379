import { all, takeEvery, call, put, fork } from 'redux-saga/effects';

import {
    GET_CUSTOMERS,
    CREATE_CUSTOMER,
    GET_CUSTOMER,
    UPDATE_CUSTOMER,
    DELETE_CUSTOMER,
    GET_CUSTOMER_VEHICLES,
    GET_CUSTOMER_SERVICE_LOGS,
    GET_CUSTOMER_VEHICLES_DATA,
    CREATE_CUSTOMER_VEHICLES_DATA,
    SHOW_CUSTOMER_VEHICLES_DATA,
    UPDATE_CUSTOMER_VEHICLES_DATA,
    DELETE_CUSTOMER_VEHICLES_DATA
} from './actionTypes';
import {
    getCustomersSuccess,
    getCustomersError,
    createCustomerSuccess,
    createCustomerError,
    getCustomerSuccess,
    getCustomerError,
    updateCustomerSuccess,
    updateCustomerError,
    deleteCustomerSuccess,
    deleteCustomerError,
    getCustomerVehiclesSuccess,
    getCustomerVehiclesError,
    getCustomerServiceLogsSuccess,
    getCustomerServiceLogsError,
    getCustomerVehiclesDataSuccess,
    getCustomerVehiclesDataError,
    createCustomerVehiclesDataSuccess,
    createCustomerVehiclesDataError,
    showCustomerVehiclesDataSuccess,
    showCustomerVehiclesDataError,
    updateCustomerVehiclesDataSuccess,
    updateCustomerVehiclesDataError,
    deleteCustomerVehiclesDataSuccess,
    deleteCustomerVehiclesDataError
} from './actions';
import {
    getCustomers as apiGetCustomers,
    createCustomer as apiCreateCustomer,
    getCustomer as apiGetCustomer,
    updateCustomer as apiUpdateCustomer,
    deleteCustomer as apiDeleteCustomer,
    getCustomerVehicles as apiGetCustomerVehicles,
    getCustomerServiceLogs as apiGetCustomerServiceLogs,
    getCustomerVehiclesData as apiGetCustomerVehiclesData,
    createCustomerVehiclesData as apiCreateCustomerVehiclesData,
    showCustomerVehiclesData as apiShowCustomerVehiclesData,
    updateCustomerVehiclesData as apiUpdateCustomerVehiclesData,
    deleteCustomerVehiclesData as apiDeleteCustomerVehiclesData
} from './../../helpers/customers_service/customers_controller';

function* getCustomers({ payload }) {
    try {
        const response = yield call(apiGetCustomers, payload.page, payload.perPage, payload.query);
        if (response.code === 200 || response.status === 200) {
            yield put(getCustomersSuccess(response.data))
        }
        yield put(getCustomersError(response.error))
    } catch (error) {
        yield put(getCustomersError(error))
    }
}

function* createCustomer({ payload: { business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note } }) {
    try {
        const response = yield call(apiCreateCustomer, business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note);
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield* getCustomers({ payload: { page: 1, perPage: 10 } })
            yield put(createCustomerSuccess(response.data))
        }
        yield put(createCustomerError(response.message))
    } catch (error) {
        yield put(createCustomerError(error))
    }
}

function* getCustomer({ payload }) {
    try {
        const response = yield call(apiGetCustomer, payload.id)
        if (response.status === 200 || response.code === 200) {
            yield put(getCustomerSuccess(response.data))
        }
        yield put(getCustomerError(response.error))
    } catch (error) {
        yield put(getCustomerError(error))
    }
}

function* updateCustomer({ payload }) {
    try {
        const response = yield call(apiUpdateCustomer, payload.id, payload.business_id, payload.first_name, payload.last_name, payload.email, payload.phone, payload.address, payload.postal_code, payload.city, payload.country_id, payload.note);
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield put(updateCustomerSuccess(response.data))
            yield getCustomer({ payload })
        }
        yield put(updateCustomerError(response.message))
    } catch (error) {
        yield put(updateCustomerError(error))
    }
}

function* deleteCustomer({ payload }) {
    try {
        const response = yield call(apiDeleteCustomer, payload.id)
        if (response.status === 200 || response.code === 200) {
            yield put(deleteCustomerSuccess(response.data))
            yield* getCustomers({ payload: { page: 1, perPage: 10 } })
            payload.history.push('/pregled-klijenata')
        }
        yield put(deleteCustomerError(response.error))
    } catch (error) {
        yield put(deleteCustomerError(error))
    }
}

function* getCustomerVehicles({ payload }) {
    try {
        const response = yield call(apiGetCustomerVehicles, payload.id);
        if (response.code === 200 || response.status === 200) {
            yield put(getCustomerVehiclesSuccess(response.data))
        }
        yield put(getCustomerVehiclesError(response.error))
    } catch (error) {
        yield put(getCustomerVehiclesError(error))
    }
}

function* getCustomerServiceLogs({ payload }) {
    try {
        const response = yield call(apiGetCustomerServiceLogs, payload.id);
        if (response.code === 200 || response.status === 200) {
            yield put(getCustomerServiceLogsSuccess(response.data))
        }
        yield put(getCustomerServiceLogsError(response.error))
    } catch (error) {
        yield put(getCustomerServiceLogsError(error))
    }
}

// Customer vehicles endpoint

function* getCustomerVehiclesData() {
    try {
        const response = yield call(apiGetCustomerVehiclesData);
        if (response.code === 200 || response.status === 200) {
            yield put(getCustomerVehiclesDataSuccess(response.data))
        }
        yield put(getCustomerVehiclesDataError(response.error))
    } catch (error) {
        yield put(getCustomerVehiclesDataError(error))
    }
}

function* createCustomerVehiclesData({ payload }) {
    try {
        const response = yield call(apiCreateCustomerVehiclesData, payload.data);
        if (response.code === 200 || response.status === 200) {
            yield put(createCustomerVehiclesDataSuccess(response.data))
        }
        yield put(createCustomerVehiclesDataError(response.error))
    } catch (error) {
        yield put(createCustomerVehiclesDataError(error))
    }
}

function* showCustomerVehiclesData({ payload }) {
    try {
        const response = yield call(apiShowCustomerVehiclesData, payload.id);
        if (response.code === 200 || response.status === 200) {
            yield put(showCustomerVehiclesDataSuccess(response.data))
        }
        yield put(showCustomerVehiclesDataError(response.error))
    } catch (error) {
        yield put(showCustomerVehiclesDataError(error))
    }
}

function* updateCustomerVehiclesData({ payload }) {
    try {
        const response = yield call(apiUpdateCustomerVehiclesData, payload.id, payload.vehicle_id, payload.customer_id, payload.customer_role_id);
        if (response.code === 200 || response.status === 200) {
            yield put(updateCustomerVehiclesDataSuccess(response.data))
        }
        yield put(updateCustomerVehiclesDataError(response.error))
    } catch (error) {
        yield put(updateCustomerVehiclesDataError(error))
    }
}

function* deleteCustomerVehiclesData({ payload }) {
    try {
        const response = yield call(apiDeleteCustomerVehiclesData, payload.id);
        if (response.code === 200 || response.status === 200) {
            yield put(deleteCustomerVehiclesDataSuccess(response.data))
        }
        yield put(deleteCustomerVehiclesDataError(response.error))
    } catch (error) {
        yield put(deleteCustomerVehiclesDataError(error))
    }
}

function* watchGetCustomers() {
    yield takeEvery(GET_CUSTOMERS, getCustomers)
}

function* watchCreateCustomer() {
    yield takeEvery(CREATE_CUSTOMER, createCustomer)
}

function* watchGetCustomer() {
    yield takeEvery(GET_CUSTOMER, getCustomer)
}

function* watchUpdateCustomer() {
    yield takeEvery(UPDATE_CUSTOMER, updateCustomer)
}

function* watchDeleteCustomer() {
    yield takeEvery(DELETE_CUSTOMER, deleteCustomer)
}

function* watchGetCustomerVehicles() {
    yield takeEvery(GET_CUSTOMER_VEHICLES, getCustomerVehicles)
}

function* watchGetCustomerServiceLogs() {
    yield takeEvery(GET_CUSTOMER_SERVICE_LOGS, getCustomerServiceLogs)
}

function* watchGetCustomerVehiclesData() {
    yield takeEvery(GET_CUSTOMER_VEHICLES_DATA, getCustomerVehiclesData)
}

function* watchCreateCustomerVehiclesData() {
    yield takeEvery(CREATE_CUSTOMER_VEHICLES_DATA, createCustomerVehiclesData)
}

function* watchShowCustomerVehiclesData() {
    yield takeEvery(SHOW_CUSTOMER_VEHICLES_DATA, showCustomerVehiclesData)
}

function* watchUpdateCustomerVehiclesData() {
    yield takeEvery(UPDATE_CUSTOMER_VEHICLES_DATA, updateCustomerVehiclesData)
}

function* watchDeleteCustomerVehiclesData() {
    yield takeEvery(DELETE_CUSTOMER_VEHICLES_DATA, deleteCustomerVehiclesData)
}

function* sagaCustomers() {
    yield all([
        fork(watchGetCustomers),
        fork(watchCreateCustomer),
        fork(watchGetCustomer),
        fork(watchUpdateCustomer),
        fork(watchDeleteCustomer),
        fork(watchGetCustomerVehicles),
        fork(watchGetCustomerServiceLogs),
        fork(watchGetCustomerVehiclesData),
        fork(watchCreateCustomerVehiclesData),
        fork(watchShowCustomerVehiclesData),
        fork(watchUpdateCustomerVehiclesData),
        fork(watchDeleteCustomerVehiclesData),
    ])
}

export default sagaCustomers