import React from 'react';
import { Table } from 'react-bootstrap';

const CardTable = (props) => {
    return (
        <Table size='sm' responsive>
            <thead>
                <tr>
                    {props.headListItems.map((item, index) => (
                        <th style={props.customerListStyle} key={index}>{item}</th>
                    ))}
                </tr>
            </thead>
            <tbody>
                {props.children}
            </tbody>
        </Table>
    )
}

export default CardTable
