import { all, takeEvery, call, put, fork } from 'redux-saga/effects';

import {
    GET_USERS,
    CREATE_USER,
    DELETE_USER,
    GET_USER,
    UPDATE_USER,
    UPDATE_USER_PASSWORD
} from './actionTypes';

import {
    getUsersSuccess,
    getUsersError,
    createUserSuccess,
    createUserError,
    deleteUserSuccess,
    deleteUserError,
    getUserSuccess,
    getUserError,
    updateUserSuccess,
    updateUserError,
    updateUserPasswordSuccess,
    updateUserPasswordError
} from './actions';

import {
    getUsers as apiGetUsers,
    createUser as apiCreateUser,
    deleteUser as apiDeleteUser,
    getUser as apiGetUser,
    updateUser as apiUpdateUser,
    updateUserPassword as apiupdateUserPassword,
} from './../../helpers/users_service/users_controller';

function* getUsers({ payload }) {
    try {
        const response = yield call(apiGetUsers, payload.page, payload.perPage);
        if (response.code === 200 || response.status === 200) {
            yield put(getUsersSuccess(response.data))
        }
        yield put(getUsersError(response.message))
    } catch (error) {
        yield put(getUsersError(error))
    }
}

function* createUser({ payload }) {
    try {
        const response = yield call(apiCreateUser, payload.business_id, payload.role_id, payload.status_id, payload.first_name, payload.last_name, payload.email, payload.phone, payload.password, payload.password_confirmation);
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield put(createUserSuccess(response.data))
            yield getUsers({ payload: { page: 1, perPage: 10 } })
        }
        yield put(createUserError(response.message))
    } catch (error) {
        yield put(createUserError(error))
    }
}

function* getUser({ payload }) {
    try {
        const response = yield call(apiGetUser, payload.id);
        if (response.code === 200 || response.status === 200) {
            yield put(getUserSuccess(response.data))
        }
        yield put(getUserError(response.message))
    } catch (error) {
        yield put(getUserError(error))
    }
}

function* updateUser({ payload }) {
    try {
        const response = yield call(apiUpdateUser, payload.id, payload.business_id, payload.role_id, payload.status_id, payload.first_name, payload.last_name, payload.email, payload.phone);
        if (response.code === 200 || response.status === 200) {
            yield put(updateUserSuccess(response.data))
        }
        yield put(updateUserError(response.message))
    } catch (error) {
        yield put(updateUserError(error))
    }
}

function* deleteUser({ payload }) {
    try {
        const response = yield call(apiDeleteUser, payload.id);
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield getUsers({ payload: { page: 1, perPage: 10 } })
            yield put(deleteUserSuccess(response.data))
            payload.history.push('/korisnici')
        }
        yield put(deleteUserError(response.message))
    } catch (error) {
        yield put(deleteUserError(error))
    }
}
function* updateUserPassword({ payload }) {
    try {
        const response = yield call(apiupdateUserPassword, payload.id, payload.old_password, payload.password, payload.password_confirmation);
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield put(updateUserPasswordSuccess(response.data))
        }
        yield put(updateUserPasswordError(response.message))
    } catch (error) {
        yield put(updateUserPasswordError(error))
    }
}

function* watchGetUsers() {
    yield takeEvery(GET_USERS, getUsers);
}

function* watchGetUser() {
    yield takeEvery(GET_USER, getUser);
}

function* watchCreateUser() {
    yield takeEvery(CREATE_USER, createUser);
}

function* watchUpdateUser() {
    yield takeEvery(UPDATE_USER, updateUser);
}

function* watchDeleteUser() {
    yield takeEvery(DELETE_USER, deleteUser);
}

function* watchUpdateUserPassword() {
    yield takeEvery(UPDATE_USER_PASSWORD, updateUserPassword);
}


function* usersSaga() {
    yield all([
        fork(watchGetUsers),
        fork(watchCreateUser),
        fork(watchGetUser),
        fork(watchUpdateUser),
        fork(watchDeleteUser),
        fork(watchUpdateUserPassword)
    ])
}

export default usersSaga;