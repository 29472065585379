export const checkUserType = {
    admin: (role_id) => role_id === 1
}

export const randomNum = () => {
    return Math.random() * 1000
}

export const isOptionUnique = (option, options, valueKey) => {
    // const { option, options, valueKey } = prop;
    return !options.find(opt => option[valueKey] === opt[valueKey])
}

export const getUrlId = () => {
    const lastBackslash = window.location.pathname.lastIndexOf('/') + 1;
    const id = window.location.pathname.substr(lastBackslash);
    return id
}

export const formatDate = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear()
    return `${day}.${month}.${year}`;
}

export const formatDateForServer = (date) => {
    let d = new Date(date);
    let day = d.getDate();
    let month = d.getMonth() + 1;
    let year = d.getFullYear()
    return `${year}-${month}-${day}`;
}

export const setCookie = (name, value) => {
    document.cookie = name + "=" + value;
}

export const getCookie = (name) => {
    const nameEQ = name + "=";
    const ca = document.cookie.split(';');
    for (let i = 0; i < ca.length; i++) {
        let c = ca[i];
        while (c.charAt(0) === ' ') c = c.substring(1, c.length);
        if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length, c.length);
    }
    return null;
}

export const validateEmail = (email) => {
    const regex = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return regex.test(String(email).toLowerCase());
}

export const validatePassword = (password) => {
    // Minimum 8 characters
    // Maximum 20 characters
    // At least one uppercase character
    // At least one lowercase character
    // At least one digit
    // At least one special character
    const regex = /^(?=.*\d)(?=.*[A-Z])(?=.*[a-z])(?=.*[a-zA-Z!@#$%&? "])[a-zA-Z0-9!@#$%&?]{8,20}$/
    return regex.test(password);
}

export const toggleBurgerMenu = (toggleMenu) => {
    if (window.innerWidth > 992) {
        if (toggleMenu) {
            document.querySelector('.sidebar').style.width = '62px';
            document.querySelector('.sidebar-menu-button').style.left = '50px';
            document.querySelector('.right-panel').style.width = '96%';
            document.querySelectorAll('.sidebar-menu-item').forEach(e => e.style.display = 'none');
        } else {
            document.querySelector('.sidebar').style.width = '20%';
            document.querySelector('.sidebar-menu-button').style.left = '19%';
            document.querySelector('.right-panel').style.width = '80%';
            document.querySelectorAll('.sidebar-menu-item').forEach(e => e.style.display = 'inline-block');
        }
    } else {
        if (!toggleMenu) {
            document.querySelector('.sidebar').style.display = 'none';
            document.querySelector('.sidebar').style.width = '0';
            document.querySelector('.sidebar-menu-button').style.left = '10%';
            document.querySelector('.right-panel').style.display = 'block';
            document.querySelector('.sidebar-menu-button').style.background = '#da1a25';
            document.querySelector('.sidebar-menu-button').style.color = '#fff';
            document.querySelector('.right-panel').style.width = '100%';

        } else {
            document.querySelector('.sidebar').style.display = 'block';
            document.querySelector('.sidebar').style.width = '100%';
            document.querySelector('.sidebar-menu-button').style.left = '10%';
            document.querySelector('.sidebar-menu-button').style.background = '#fff';
            document.querySelector('.sidebar-menu-button').style.color = '#da1a25';
            document.querySelector('.right-panel').style.display = 'none';
        }
    }

}

// List data
export const listOfCurrencies = [{ name: 'RSD' }, { name: 'EUR' }, { name: 'USD' }, { name: 'NOK' }, { name: 'MKD' }, { name: 'BAM' }, { name: 'GBP' }, { name: 'HRK' }];
export const grades = [{ name: 1 }, { name: 2 }, { name: 3 }, { name: 4 }, { name: 5 }];
export const reportTypes = [
    { name: 'Redovan servis' },
    { name: 'Oštećenje/Kvar' },
    { name: 'Tjuning' },
    { name: 'Delovi' }
];
export const fuelTypes = [
    { name: 'Benzin' },
    { name: 'Dizel' },
    { name: 'Plin' },
    { name: 'Metan' }
];
export const units = [
    { name: 'Kilometar' },
    { name: 'Milja' }
];
export const statusList = [
    { name: 'New' },
    { name: 'In progress' },
    { name: 'Closed' }
];

export const orderByType = [{ id: 'asc', name: 'Uzlazni redosled' }, { id: 'desc', name: 'Silazni redosled' }]

export const tableHeadList = {
    todo: ['Datum', 'Naziv vozila', 'Registracija', 'Naslov', 'Opis', 'Status', 'Završi zadatak'],
    reports: ['Datum', 'Vozilo', 'Registracija', 'Ukupno', 'Naslov', 'Opis'],
    users: ['Ime', 'Prezime', 'Email', 'Telefon'],
    vehicles: ['Tip vozila', 'Naziv', 'Registracija', 'Kilometraza', 'Napomena'],
    serviceLogReports: ['Naziv izveštaja', 'Preuzmi izveštaj', 'Obriši izveštaj'],
    customers: ['Ime', 'Prezime', 'Email', 'Telefon', 'Vozila'],
}

export const reportCsvHeader = [
    { label: 'date', key: 'date' },
    { label: 'title', key: 'title' },
    { label: 'text', key: 'text' },
    { label: 'used_parts', key: 'used_parts' },
    { label: 'price_parts', key: 'price_parts' },
    { label: 'price_work', key: 'price_work' },
    { label: 'mileage', key: 'mileage' },
    { label: 'price_total', key: 'price_total' }
]

export const imageTypes = ['image/jpg', 'image/png', 'image/jpeg'];

export const addImageProcess = (file) => {
    return new Promise((resolve, reject) => {
        let reader = new FileReader();
        reader.readAsDataURL(file);
        reader.onload = function (e) {
            let img = new Image()
            img.onload = () => resolve(img)
            img.onerror = reject
            img.src = e.target.result
        }
    })
}

export const generateArrayOfYears = () => {
    var max = new Date().getFullYear()
    var min = max - 50
    var years = []

    for (var i = max; i >= min; i--) {
        years.push({ value: i.toString(), name: i.toString() })
    }
    return years
}


// dummy data for testing
export const reportsData = [
    { id: 1, date: '20.06.2020', carName: 'Fiat Stilo 1.9jtd', mileage: '148000', name: 'Mali servis', sum: '15000' },
    { id: 2, date: '20.06.2020', carName: 'Reno Clio 1.5dci', mileage: '148000', name: 'Mali servis', sum: '15000' },
    { id: 3, date: '20.06.2020', carName: 'VW Polo 1.3tdi', mileage: '148000', name: 'Mali servis', sum: '15000' },
]

export const vehiclesData = [
    { id: 1, img: 'https://www.servisna-knjizica.com/storage/458/conversions/Fiat-thumb.jpg', carName: 'Fiat stilo 1.9jtd', role: 'Vlasnik', registration: 'N/A', mileage: '148000' },
    { id: 2, img: 'https://www.servisna-knjizica.com/storage/469/conversions/Renault-small.jpg', carName: 'Renault Clio 1.5dci', role: 'Vlasnik', registration: 'N/A', mileage: '178000' },
    { id: 3, img: 'https://www.servisna-knjizica.com/storage/474/conversions/Vw-small.jpg', carName: 'VW Polo 1.3tdi', role: 'Vlasnik', registration: 'N/A', mileage: '165000' },
]

export const usersData = [
    { id: 1, firstName: 'Stefan', lastName: 'Todorovic', email: 'stefan@gmail.com' },
    { id: 2, firstName: 'Stefan1', lastName: 'Todorovic', email: 'stefan1@gmail.com' },
    { id: 3, firstName: 'Stefan2', lastName: 'Todorovic', email: 'stefan2@gmail.com' },
]

export const customersData = [
    { id: 1, first_name: 'musterija', last_name: 'Todorovic', email: 'musterija@gmail.com' },
    { id: 2, first_name: 'musterija1', last_name: 'Todorovic', email: 'musterija1@gmail.com' },
    { id: 3, first_name: 'musterija2', last_name: 'Todorovic', email: 'musterija2@gmail.com' },
]

export const tasksList = [
    { id: 1, date: '20.06.2020', assignee: 'Stef', description: 'Operi kola', status: 'New' },
    { id: 2, date: '20.06.2020', assignee: 'Stef', description: 'Zameni ulje', status: 'In progress' },
    { id: 3, date: '20.06.2020', assignee: 'Stef', description: 'Balansiraj gume', status: 'Closed' }
]

export const roles = [
    { id: 1, name: 'Administrator' },
    { id: 0, name: 'Korisnik' }
]