import React from 'react';
import { Card } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

const CardTitle = ({ icon, title, style }) => {
    return (
        <div className='icon-and-title' style={style}>
            <FontAwesomeIcon icon={icon} />
            <Card.Title>{title}</Card.Title>
        </div>
    )
}

export default CardTitle
