import {
    GET_VEHICLE_MANUFACTURERS,
    GET_VEHICLE_MANUFACTURERS_SUCCESS,
    GET_VEHICLE_MANUFACTURERS_ERROR
} from './actionTypes';

export const getVehicleManufacturers = () => {
    return {
        type: GET_VEHICLE_MANUFACTURERS
    }
}

export const getVehicleManufacturersSuccess = (manufacturers) => {
    return {
        type: GET_VEHICLE_MANUFACTURERS_SUCCESS,
        payload: manufacturers
    }
}

export const getVehicleManufacturersError = (message) => {
    return {
        type: GET_VEHICLE_MANUFACTURERS_ERROR,
        payload: message
    }
}