import { all } from 'redux-saga/effects';
import authSaga from './auth/login/saga';
import forgotPasswordSaga from './auth/forgotPassword/saga';
import meSaga from './me/saga';
import usersSaga from './users/saga';
import customersSaga from './customers/saga';
import todoSaga from './to_do/saga';
import serviceLogsSaga from './service_log/saga';
import vehiclesSaga from './vehicles/saga';
import vehicleManufacturersSaga from './vehicles/vehicleManufacturers/saga';
import vehicleModelsSaga from './vehicles/vehicleModels/saga';
import staticTypesSaga from './static_values/saga';
import businessSaga from './business/saga';
import globalSearchSaga from './search/saga';

export default function* rootSaga() {
    yield all([
        authSaga(),
        meSaga(),
        usersSaga(),
        customersSaga(),
        todoSaga(),
        serviceLogsSaga(),
        vehiclesSaga(),
        vehicleManufacturersSaga(),
        vehicleModelsSaga(),
        staticTypesSaga(),
        businessSaga(),
        forgotPasswordSaga(),
        globalSearchSaga()
    ])
}