import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getToDo, getUsers, getVehicles, deleteToDoTask, getCustomers, getTodoStatuses } from './../../store/actions';

import Todo from '../../components/Lists/Todo';

class TodoPage extends PureComponent {

    componentDidMount() {
        if (!this.props.tasks) {
            this.props.getToDo(1, 10)
        }
        this.props.getUsers(1, 1000)
        this.props.getVehicles(1, 1000)
        this.props.getCustomers(1, 1000)
        if (this.props.todoStatuses.length === 0) {
            this.props.getTodoStatuses()
        }
    }

    render() {
        return (
            <div className='page'>
                <Todo
                    loading={this.props.loading}
                    tasksList={this.props.tasks}
                    users={this.props.users}
                    vehicles={this.props.vehicles}
                    deleteTask={this.props.deleteToDoTask}
                    me={this.props.me}
                    meta={this.props.meta}
                    customers={this.props.customers}
                    todoStatuses={this.props.todoStatuses}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {

    return {
        loading: state.Todo.loading,
        tasks: state.Todo.tasks.data,
        users: state.Users.users.data,
        vehicles: state.Vehicles.vehicles.data,
        me: state.Me.me,
        meta: state.Todo.tasks.meta,
        customers: state.Customers.customers.data,
        todoStatuses: state.StaticTypes.todoStatuses
    }
}

export default connect(mapStateToProps, { getToDo, getUsers, getVehicles, deleteToDoTask, getCustomers, getTodoStatuses })(TodoPage)
