import React, { PureComponent } from 'react'
import ReportsList from '../../components/Lists/ReportsList'
import { connect } from 'react-redux';

import { getServiceLogs, deleteServiceLog, getServiceTypes, getServiceCategories, getVehicleManufacturers, getVehicleModels, getCustomers, getVehicles } from './../../store/actions';

class Reports extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        // this.props.getServiceLogs(1, 10)
        if (this.props.serviceTypes.length === 0) {
            this.props.getServiceTypes()
        }
        if (this.props.serviceCategories.length === 0) {
            this.props.getServiceCategories()
        }
        // this.props.getVehicleManufacturers()
        // this.props.getVehicleModels()
        this.props.getCustomers(1, 1000)
        this.props.getVehicles(1, 1000)
    }

    render() {
        return (
            <div className='page'>
                <ReportsList
                    title='Novi izveštaj'
                    loading={this.props.loading}
                    // reportsList={this.props.logs}
                    deleteServiceLog={this.props.deleteServiceLog}
                    meta={this.props.meta}
                    reports={true}
                    serviceTypes={this.props.serviceTypes}
                    serviceCategories={this.props.serviceCategories}
                    manufacturers={this.props.manufacturers}
                    // models={this.props.models}
                    customers={this.props.customers}
                    vehicles={this.props.vehicles}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.ServiceLogs.loading,
        logs: state.ServiceLogs.logs.data,
        meta: state.ServiceLogs.logs.meta,
        serviceTypes: state.StaticTypes.serviceTypes,
        serviceCategories: state.StaticTypes.serviceCategories,
        manufacturers: state.VehicleManufacturers.manufacturers,
        // models: state.VehicleModels.models,
        customers: state.Customers.customers.data,
        vehicles: state.Vehicles.vehicles.data
    }
}

export default connect(mapStateToProps, { getServiceLogs, deleteServiceLog, getServiceTypes, getServiceCategories, getVehicleManufacturers, getVehicleModels, getCustomers, getVehicles })(Reports)
