import React, { useState } from 'react';
import { faPortrait } from '@fortawesome/free-solid-svg-icons';
import { Form } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import UploadFile from './../Common/UploadFile';
import ErrorAlert from './../Common/ErrorAlert';
import { imageTypes, addImageProcess } from './../../helpers/common';
import CardForm from '../Common/Card/CardForm';
import { uploadBusinessLogo } from '../../store/actions';


const ChangeProfileImage = (props) => {
    const [imageName, setImageName] = useState('');
    const [error, setError] = useState(false);
    const dispatch = useDispatch()

    const handleUploadFile = (e) => {
        const file = e.target.files[0];
        addImageProcess(file).then(res => {
            if (!imageTypes.includes(file.type) || (res.width < 150 || res.height < 150 || res.width > 800 || res.height > 800)) {
                setError(true);
                setImageName('');
            } else {
                setError(false)
                setImageName(file.name);
                dispatch(uploadBusinessLogo(file))
            }
        })
    }

    return (
        <CardForm
            className='add-form'
            title={props.title}
            icon={faPortrait}
        >
            <UploadFile
                label='Dodajte logo servisa'
                placeholder={imageName || 'Fajl nije izabran'}
                onChange={handleUploadFile}
                id='upload-file'
                className='service-logo-upload-input'
            />
            <Form.Text className="text-muted">
                Rezolucija slike mora biti veća od 150x150 i manja od 800x800
            </Form.Text>
            {error &&
                <ErrorAlert
                    content='Slika mora da bude u formatu jpg, png ili jpeg. Minimalne dimenzije slike su 300x300'
                />
            }
        </CardForm>
    )
}

export default ChangeProfileImage
