import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import axios from 'axios';
import { saveAs } from 'file-saver';
import { useParams } from 'react-router';
import MediaImage from '../../components/Common/MediaImage';
import UploadFile from '../../components/Common/UploadFile';
import { addServiceLogMedia, deleteServiceLogMedia, getServiceLogMedia } from './../../store/actions';


import Loader from '../../components/Common/Loader';

const token = localStorage.getItem('authUser');

const ReportMedia = () => {
    const [loading, setloading] = useState(false)
    const media = useSelector(state => state.ServiceLogs.media)
    // const loading = useSelector(state => state.ServiceLogs.loading)
    const dispatch = useDispatch()
    const { id } = useParams();

    useEffect(() => {
        dispatch(getServiceLogMedia(id))
    }, [dispatch, id])

    const handleDownload = (file) => {
        let last = file.file_name.lastIndexOf('.')

        const options = {
            method: 'GET',
            mode: 'no-cors',
            url: file.download_url,
            responseType: 'arraybuffer',
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        };

        axios.request(options).then(function (response) {
            saveAs(new Blob([response.data], { type: `application/${file.file_name.slice(last + 1, file.file_name.length)}` }), file.file_name)
        }).catch(function (error) {
            console.error(error);
        });
    }

    const handleDelete = (fileId) => {
        dispatch(deleteServiceLogMedia(id, fileId))
        setloading(true)
        dispatch(getServiceLogMedia(id))
        setTimeout(() => {
            setloading(false)
        }, 1000)
    }

    const handleFiles = (e) => {
        dispatch(addServiceLogMedia(id, e.target.files))
        setloading(true)
        dispatch(getServiceLogMedia(id))
        setTimeout(() => {
            setloading(false)
        }, 4000)
    }


    return (
        <div className='page'>
            <UploadFile
                label='Dodavanje datoteka'
                name='attachments'
                infoText='Dozvoljeni fajlovi: pdf, jpg, jpeg, png.'
                onChange={handleFiles}
                className='files-upload-container'
            />

            {loading ?
                <Loader />
                :
                media &&
                    media.length > 0 ?
                    (<div className='media-files'>
                        {media.map(file => (
                            <MediaImage
                                key={file.id}
                                thumbnail={file.file_name}
                                originalUrl={file.original_url}
                                filename={file.file_name}
                                handleDownload={() => handleDownload(file)}
                                handleDelete={() => handleDelete(file.id)}
                            />
                        ))}
                    </div>)
                    :
                    <div className='no-media-files'>
                        Trenutno nema datoteka
                    </div>
            }

        </div>
    )
}

export default ReportMedia
