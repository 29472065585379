import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CommonButton from '../CommonButton';
import CommonDatePicker from '../CommonDatePicker';

import { formatDateForServer } from '../../../helpers/common';
import { getServiceLogs } from '../../../store/actions';

export class SearchByDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: ''
        }
    }
    handleDate = (value, name) => {
        const date = formatDateForServer(value)
        this.setState({
            [name]: date
        })
    }

    submitForm = () => {
        const { startDate, endDate } = this.state
        this.props.getServiceLogs(1, 10, startDate, endDate)
    }

    resetForm = () => {
        this.setState({
            startDate: '',
            endDate: ''
        })
    }
    render() {
        return (
            <div className='search-by-date'>
                <Row>
                    <Col sm={12} md={3}>
                        <CommonDatePicker date={this.state.startDate} handleDate={(e) => this.handleDate(e, 'startDate')} className='picker-width-100' />
                    </Col>
                    <Col sm={12} md={3}>
                        <CommonDatePicker date={this.state.endDate} handleDate={(e) => this.handleDate(e, 'endDate')} className='picker-width-100' />
                    </Col>
                    <Col sm={12} md={3}>
                        <CommonButton
                            variant="danger"
                            label='Pretraži'
                            onClick={this.submitForm}
                            icon={faSearch}
                        />
                    </Col>
                    <Col sm={12} md={3}>
                        <CommonButton
                            variant="dark"
                            label='Poništi'
                            onClick={this.resetForm}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}



export default connect(null, { getServiceLogs })(SearchByDate)
