import { takeEvery, call, put, fork, all } from 'redux-saga/effects';
import {
    GET_VEHICLES,
    GET_VEHICLE,
    CREATE_VEHICLE,
    UPDATE_VEHICLE,
    DELETE_VEHICLE,
    GET_VEHICLE_CUSTOMERS,
    GET_VEHICLE_SERVICE_LOGS
} from './actionType';
import {
    getVehiclesSuccess,
    getVehiclesError,
    getVehicleSuccess,
    getVehicleError,
    createVehicleSuccess,
    createVehicleError,
    updateVehicleSuccess,
    updateVehicleError,
    deleteVehicleSuccess,
    deleteVehicleError,
    getVehicleCustomersSuccess,
    getVehicleCustomersError,
    getVehicleServiceLogsSuccess,
    getVehicleServiceLogsError
} from './actions';
import {
    getVehicles as apiGetVehicles,
    getVehicle as apiGetVehicle,
    createVehicle as apiCreateVehicle,
    updateVehicle as apiUpdateVehicle,
    deleteVehicle as apiDeleteVehicle,
    getVehicleCustomers as apiGetVehicleCustomers,
    getVehicleServiceLogs as apiGetVehicleServiceLogs,
} from './../../helpers/vehicles_service/vehicles_controller';

function* getVehicles({ payload }) {
    try {
        const response = yield call(apiGetVehicles, payload.page, payload.perPage, payload.query)
        if (response.code === 200 || response.status === 200) {
            yield put(getVehiclesSuccess(response.data))
        }
    } catch (error) {
        yield put(getVehiclesError(error))
    }
}

function* getVehicle({ payload }) {
    try {
        const response = yield call(apiGetVehicle, payload.id)
        if (response.code === 200 || response.status === 200) {
            yield put(getVehicleSuccess(response.data))
        }
        yield put(getVehicleError(response.message))
    } catch (error) {
        yield put(getVehicleError(error))
    }
}

function* createVehicle({ payload }) {
    try {
        const response = yield call(apiCreateVehicle, payload.business_id, payload.create_user_id, payload.vehicle_model_id, payload.vehicle_manufacturer_id, payload.fuel_type_id, payload.status_id, payload.vehicle_type_id, payload.label, payload.vehicle_plate, payload.production_year, payload.mileage, payload.vin, payload.engine_code, payload.engine_displacement, payload.engine_power_hp, payload.engine_power_kw, payload.daily_mileage, payload.small_service_mileage, payload.big_service_mileage, payload.note, payload.registration_date)
        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            // yield getVehicles({ payload: { page: 1, perPage: 10 } })
            yield put(createVehicleSuccess(response.data))
        }
        yield put(createVehicleError(response.message))
    } catch (error) {
        yield put(createVehicleError(error))
    }
}

function* updateVehicle({ payload }) {
    try {
        const response = yield call(apiUpdateVehicle, payload.id, payload.business_id, payload.create_user_id, payload.vehicle_model_id, payload.vehicle_manufacturer_id, payload.fuel_type_id, payload.status_id, payload.vehicle_type_id, payload.label, payload.vehicle_plate, payload.production_year, payload.mileage, payload.vin, payload.engine_code, payload.engine_displacement, payload.engine_power_hp, payload.engine_power_kw, payload.daily_mileage, payload.small_service_mileage, payload.big_service_mileage, payload.note, payload.registration_date)

        if (response.code === 200 || response.status === 200 || response.code === 201 || response.status === 201) {
            yield put(updateVehicleSuccess(response.data))
            yield getVehicle({ payload })
        }
        yield put(updateVehicleError(response.message))
    } catch (error) {
        yield put(updateVehicleError(error))
    }
}

function* deleteVehicle({ payload }) {
    try {
        const response = yield call(apiDeleteVehicle, payload.id)
        if (response.code === 200 || response.status === 200) {
            yield put(deleteVehicleSuccess(response.data))
        }
        yield put(deleteVehicleError(response.message))
    } catch (error) {
        yield put(deleteVehicleError(error))
    }
}

function* getVehicleCustomers({ payload }) {
    try {
        const response = yield call(apiGetVehicleCustomers, payload.id)
        if (response.code === 200 || response.status === 200) {
            yield put(getVehicleCustomersSuccess(response.data))
            yield getVehicles({ payload: { page: 1, perPage: 10 } })
        }
        yield put(getVehicleCustomersError(response.message))
    } catch (error) {
        yield put(getVehicleCustomersError(error))
    }
}

function* getVehicleServiceLogs({ payload }) {
    try {
        const response = yield call(apiGetVehicleServiceLogs, payload.id)
        if (response.code === 200 || response.status === 200) {
            yield put(getVehicleServiceLogsSuccess(response.data))
            yield getVehicles({ payload: { page: 1, perPage: 10 } })
        }
        yield put(getVehicleServiceLogsError(response.message))
    } catch (error) {
        yield put(getVehicleServiceLogsError(error))
    }
}

function* watchGetVehicles() {
    yield takeEvery(GET_VEHICLES, getVehicles)
}

function* watchGetVehicle() {
    yield takeEvery(GET_VEHICLE, getVehicle)
}

function* watchCreateVehicle() {
    yield takeEvery(CREATE_VEHICLE, createVehicle)
}

function* watchUpdateVehicle() {
    yield takeEvery(UPDATE_VEHICLE, updateVehicle)
}

function* watchDeleteVehicle() {
    yield takeEvery(DELETE_VEHICLE, deleteVehicle)
}

function* watchGetVehicleCustomers() {
    yield takeEvery(GET_VEHICLE_CUSTOMERS, getVehicleCustomers)
}

function* watchGetVehicleServiceLogs() {
    yield takeEvery(GET_VEHICLE_SERVICE_LOGS, getVehicleServiceLogs)
}

function* vehiclesSaga() {
    yield all([
        fork(watchGetVehicles),
        fork(watchGetVehicle),
        fork(watchCreateVehicle),
        fork(watchUpdateVehicle),
        fork(watchDeleteVehicle),
        fork(watchGetVehicleCustomers),
        fork(watchGetVehicleServiceLogs),
    ])
}

export default vehiclesSaga