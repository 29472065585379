import {
    GET_SERVICE_LOGS,
    GET_SERVICE_LOGS_SUCCESS,
    GET_SERVICE_LOGS_ERROR,
    CREATE_SERVICE_LOG,
    CREATE_SERVICE_LOG_SUCCESS,
    CREATE_SERVICE_LOG_ERROR,
    GET_SERVICE_LOG,
    GET_SERVICE_LOG_SUCCESS,
    GET_SERVICE_LOG_ERROR,
    UPDATE_SERVICE_LOG,
    UPDATE_SERVICE_LOG_SUCCESS,
    UPDATE_SERVICE_LOG_ERROR,
    DELETE_SERVICE_LOG,
    DELETE_SERVICE_LOG_SUCCESS,
    DELETE_SERVICE_LOG_ERROR,
    GET_SERVICE_LOG_MEDIA,
    GET_SERVICE_LOG_MEDIA_SUCCESS,
    GET_SERVICE_LOG_MEDIA_ERROR,
    DELETE_SERVICE_LOG_MEDIA,
    DELETE_SERVICE_LOG_MEDIA_SUCCESS,
    DELETE_SERVICE_LOG_MEDIA_ERROR,
    ADD_SERVICE_LOG_MEDIA,
    ADD_SERVICE_LOG_MEDIA_SUCCESS,
    ADD_SERVICE_LOG_MEDIA_ERROR,
    GET_SERVICE_LOG_REPORTS,
    GET_SERVICE_LOG_REPORTS_SUCCESS,
    GET_SERVICE_LOG_REPORTS_ERROR,
    ADD_SERVICE_LOG_REPORT,
    ADD_SERVICE_LOG_REPORT_SUCCESS,
    ADD_SERVICE_LOG_REPORT_ERROR,
    DELETE_SERVICE_LOG_REPORT,
    DELETE_SERVICE_LOG_REPORT_SUCCESS,
    DELETE_SERVICE_LOG_REPORT_ERROR,
    SHOW_SERVICE_LOG_REPORT,
    SHOW_SERVICE_LOG_REPORT_SUCCESS,
    SHOW_SERVICE_LOG_REPORT_ERROR,
    CLEAR_SERVICE_LOG_REPORTS
} from './actionTypes';

export const getServiceLogs = (page, perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id) => {
    return {
        type: GET_SERVICE_LOGS,
        payload: { page, perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id }
    }
}

export const getServiceLogsSuccess = (message) => {
    return {
        type: GET_SERVICE_LOGS_SUCCESS,
        payload: message
    }
}

export const getServiceLogsError = (message) => {
    return {
        type: GET_SERVICE_LOGS_ERROR,
        payload: message
    }
}

export const createServiceLog = (vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories) => {
    return {
        type: CREATE_SERVICE_LOG,
        payload: { vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories }
    }
}

export const createServiceLogSuccess = (message) => {
    return {
        type: CREATE_SERVICE_LOG_SUCCESS,
        payload: message
    }
}

export const createServiceLogError = (message) => {
    return {
        type: CREATE_SERVICE_LOG_ERROR,
        payload: message
    }
}

export const getServiceLog = (id) => {
    return {
        type: GET_SERVICE_LOG,
        payload: { id }
    }
}

export const getServiceLogSuccess = (log) => {
    return {
        type: GET_SERVICE_LOG_SUCCESS,
        payload: log
    }
}

export const getServiceLogError = (message) => {
    return {
        type: GET_SERVICE_LOG_ERROR,
        payload: message
    }
}

export const updateServiceLog = (id, vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories) => {
    return {
        type: UPDATE_SERVICE_LOG,
        payload: { id, vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories }
    }
}

export const updateServiceLogSuccess = (log) => {
    return {
        type: UPDATE_SERVICE_LOG_SUCCESS,
        payload: log
    }
}

export const updateServiceLogError = (message) => {
    return {
        type: UPDATE_SERVICE_LOG_ERROR,
        payload: message
    }
}

export const deleteServiceLog = (id) => {
    return {
        type: DELETE_SERVICE_LOG,
        payload: { id }
    }
}

export const deleteServiceLogSuccess = (log) => {
    return {
        type: DELETE_SERVICE_LOG_SUCCESS,
        payload: log
    }
}

export const deleteServiceLogError = (message) => {
    return {
        type: DELETE_SERVICE_LOG_ERROR,
        payload: message
    }
}

//MEDIA

export const getServiceLogMedia = (id) => {
    return {
        type: GET_SERVICE_LOG_MEDIA,
        payload: { id }
    }
}

export const getServiceLogMediaSuccess = (media) => {
    return {
        type: GET_SERVICE_LOG_MEDIA_SUCCESS,
        payload: media
    }
}

export const getServiceLogMediaError = (error) => {
    return {
        type: GET_SERVICE_LOG_MEDIA_ERROR,
        payload: error
    }
}

export const addServiceLogMedia = (id, attachments) => {
    return {
        type: ADD_SERVICE_LOG_MEDIA,
        payload: { id, attachments }
    }
}

export const addServiceLogMediaSuccess = (files) => {
    return {
        type: ADD_SERVICE_LOG_MEDIA_SUCCESS,
        payload: files
    }
}

export const addServiceLogMediaError = (error) => {
    return {
        type: ADD_SERVICE_LOG_MEDIA_ERROR,
        payload: error
    }
}

export const deleteServiceLogMedia = (id, uuid) => {
    return {
        type: DELETE_SERVICE_LOG_MEDIA,
        payload: { id, uuid }
    }
}

export const deleteServiceLogMediaSuccess = (message) => {
    return {
        type: DELETE_SERVICE_LOG_MEDIA_SUCCESS,
        payload: message
    }
}

export const deleteServiceLogMediaError = (error) => {
    return {
        type: DELETE_SERVICE_LOG_MEDIA_ERROR,
        payload: error
    }
}

//SERVICE LOG REPORTS
export const getServiceLogReports = (page, perPage, startDate, endDate, orderByType) => {
    return {
        type: GET_SERVICE_LOG_REPORTS,
        payload: { page, perPage, startDate, endDate, orderByType }
    }
}

export const getServiceLogReportsSuccess = (reports) => {
    return {
        type: GET_SERVICE_LOG_REPORTS_SUCCESS,
        payload: reports
    }
}

export const getServiceLogReportsError = (error) => {
    return {
        type: GET_SERVICE_LOG_REPORTS_ERROR,
        payload: error
    }
}

export const addServiceLogReport = (title, report_attributes) => {
    return {
        type: ADD_SERVICE_LOG_REPORT,
        payload: { title, report_attributes }
    }
}

export const addServiceLogReportSuccess = (message) => {
    return {
        type: ADD_SERVICE_LOG_REPORT_SUCCESS,
        payload: message
    }
}

export const addServiceLogReportError = (error) => {
    return {
        type: ADD_SERVICE_LOG_REPORT_ERROR,
        payload: error
    }
}

export const showServiceLogReport = (id) => {
    return {
        type: SHOW_SERVICE_LOG_REPORT,
        payload: { id }
    }
}

export const showServiceLogReportSuccess = (report) => {
    return {
        type: SHOW_SERVICE_LOG_REPORT_SUCCESS,
        payload: report
    }
}

export const showServiceLogReportError = (error) => {
    return {
        type: SHOW_SERVICE_LOG_REPORT_ERROR,
        payload: error
    }
}

export const deleteServiceLogReport = (id) => {
    return {
        type: DELETE_SERVICE_LOG_REPORT,
        payload: { id }
    }
}

export const deleteServiceLogReportSuccess = (message) => {
    return {
        type: DELETE_SERVICE_LOG_REPORT_SUCCESS,
        payload: message
    }
}

export const deleteServiceLogReportError = (error) => {
    return {
        type: DELETE_SERVICE_LOG_REPORT_ERROR,
        payload: error
    }
}

export const clearServiceLogReports = () => {
    return {
        type: CLEAR_SERVICE_LOG_REPORTS
    }
}