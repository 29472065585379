import React from 'react';
import { Form } from 'react-bootstrap';

const UploadFile = ({ label, placeholder, infoText, onChange, name, className }) => {
    return (
        <Form.Group className={className} style={{ position: 'relative' }}>
            <Form.Label className='files-upload-label'>{label}</Form.Label>
            <Form.Control
                type='file'
                multiple
                id='files'
                label={placeholder}
                data-browse='Izaberi'
                name={name}
                onChange={onChange}
                className={`files-upload-input ${className}`}
                custom
                accept=".png, .jpeg, .jpg, .pdf"
            />
            <Form.Text className="text-muted">
                {infoText}
            </Form.Text>
        </Form.Group>
    )
}

export default UploadFile
