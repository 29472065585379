import { takeEvery, call, put, fork, all } from 'redux-saga/effects';

import { GET_ME, LOGOUT } from './actionTypes';
import {
    getMeSuccess,
    getMeError,
    logoutSuccess,
    logoutError
} from './actions';

import {
    getMe as apiGetMe,
    logout as apiLogout
} from '../../helpers/user_service/user_controller';

function* getMe() {
    try {
        const response = yield call(apiGetMe)
        yield put(getMeSuccess(response.data))
    } catch (error) {
        yield put(getMeError(error))
    }
}

function* logout() {
    try {
        const response = yield call(apiLogout);
        if (response.code === 200) {
            localStorage.removeItem('authUser')
            yield put(logoutSuccess(response.data))
        }
        yield put(logoutError(response.messages.error))
    } catch (error) {
        yield put(logoutError(error))
    }
}

export function* watchGetMe() {
    yield takeEvery(GET_ME, getMe)
}

export function* watchLogout() {
    yield takeEvery(LOGOUT, logout)
}

function* meSaga() {
    yield all([
        fork(watchGetMe),
        fork(watchLogout)
    ])
}

export default meSaga