import { faTrash } from '@fortawesome/free-solid-svg-icons';
import React from 'react';
import { Card } from 'react-bootstrap';
import { useDispatch } from 'react-redux';
import { deleteBusinessLogo } from '../../store/actions';
// import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';

// import { faMapMarker } from '@fortawesome/free-solid-svg-icons';
import Avatar from './Avatar';
import CommonButton from './CommonButton';

const ProfileBanner = ({ src, serviceName, userName, email, country, address, city, postal_code, phone, businessId, sp, hasLogo }) => {
    const dispatch = useDispatch()
    const deleteLogo = () => {
        dispatch(deleteBusinessLogo(businessId))
    }
    return (
        <Card className='profile-banner-container'>
            <Card.Body className='profile-banner'>
                <Avatar
                    width='120px'
                    marginLeft='0'
                    src={src}
                />
                <h3>{serviceName || userName}</h3>
                {/* <p className='text-20'>{email}</p>
                <p className='text-20'>{address}</p>
                <p className='text-20'>{city}</p>
                <p className='text-20'>{postal_code}</p>
                <p className='text-20'>{phone}</p>
                {country && <p><FontAwesomeIcon icon={faMapMarker} /> {country}</p>} */}
                {(sp && hasLogo) &&
                    <CommonButton
                        variant="dark"
                        label='Obriši logo'
                        onClick={deleteLogo}
                        icon={faTrash}
                        cls='delete-service-logo'
                    />
                }
            </Card.Body>
        </Card>
    )
}

export default ProfileBanner
