import {
    GET_ME,
    GET_ME_SUCCESS,
    GET_ME_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR
} from './actionTypes';

export const getMe = () => {
    return {
        type: GET_ME
    }
}

export const getMeSuccess = (message) => {
    return {
        type: GET_ME_SUCCESS,
        payload: message
    }
}

export const getMeError = (message) => {
    return {
        type: GET_ME_ERROR,
        payload: message
    }
}

export const logout = () => {
    return {
        type: LOGOUT
    }
}

export const logoutSuccess = (message) => {
    return {
        type: LOGOUT_SUCCESS,
        payload: message
    }
}

export const logoutError = (message) => {
    return {
        type: LOGOUT_ERROR,
        payload: message
    }
}
