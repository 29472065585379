import React, { useState, useEffect, Fragment } from 'react';
import { faWrench, faSave, faTrash, faFile } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';

import CommonButton from '../Common/CommonButton';
import Select from '../Common/Select';
import InputField from '../Common/InputField';
import CommonDatePicker from '../Common/CommonDatePicker';
import ErrorAlert from '../Common/ErrorAlert';
import CardForm from '../Common/Card/CardForm';
import TextAreaField from '../Common/TextAreaField';
import Loader from '../Common/Loader';
import Modal from '../Common/Modal';
import UploadFile from '../Common/UploadFile';
import SuccessAlert from '../Common/SuccessAlert';

import { formatDateForServer } from '../../helpers/common';
import SelectSearch from '../Common/SelectSearch';
import MultipleSelect from '../Common/MultipleSelect';

import { getServiceLog, getServiceLogs } from '../../store/actions';
import PdfReport from '../Common/PdfReport';


const initialState = {
    id: '',
    vehicle_id: '',
    service_type_id: '',
    service_category_id: '',
    status_id: '',
    title: '',
    text: '',
    used_parts: '',
    price_parts: '',
    price_work: '',
    mileage: '',
    price_total: '',
    label: '',
    attachments: [],
    service_subcategories: [],
    mediaCount: 0,
    pdf_download_url: '',
    pdf_show_url: '',
    date: ''
}

const ReportForm = ({ submitServiceLog, me, log, vehicles, serviceTypes, serviceCategories, title, loading, reportEdit, delLog, createReport, idFromVehicle }) => {
    const [values, setValues] = useState(initialState);
    const [successUpload, setSuccessUpload] = useState(false);
    const [vehiclesList, setVehiclesList] = useState([])
    // const [date, setDate] = useState(formatDateForServer(''));
    const [modal, setModal] = useState(false);
    const [error, setError] = useState(false);
    const [serviceSubcategoriesList, setServiceSubcategoriesList] = useState([])
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        if (idFromVehicle) {
            setValues(values => ({ ...values, vehicle_id: Number(idFromVehicle) }))
        }
    }, [idFromVehicle])

    useEffect(() => {
        if (log) {
            setValues({ ...log, service_subcategories: log.service_subcategories })
        }
    }, [log])

    useEffect(() => {
        //load subcategories on edit report
        if (serviceCategories) {
            if (values.service_category_id) {
                const cat = serviceCategories.find(category => category.id === Number(values.service_category_id))
                setServiceSubcategoriesList(cat?.service_subcategories)
            }
        }
    }, [serviceCategories, values.service_category_id])


    useEffect(() => {
        setVehiclesList(vehicles?.map(item => {
            item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`
            return item
        }))
    }, [vehicles])

    const handleInput = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })

        if (name === 'service_category_id') {
            //get subcategories based on category id
            const cat = serviceCategories.find(category => category.id === Number(value))
            setServiceSubcategoriesList(cat?.service_subcategories || [])
            setValues({
                ...values,
                service_category_id: value,
                service_subcategories: []
            })
        }
    }

    const handleMultipleValues = (e) => {
        //select subcategory
        setValues({ ...values, service_subcategories: e })
    }

    const removeMultipleValues = (selectedList, removedItem) => {
        setValues({ ...values, service_subcategories: selectedList })
    }

    const handleFiles = (e) => {
        const { files } = e.target
        setValues({
            ...values,
            attachments: files
        })
        setSuccessUpload(true)
    }

    const handleDate = (val) => {
        const d = formatDateForServer(val)
        setValues({
            ...values,
            date: d
        })
    }

    const handleModal = () => setModal(!modal)

    const deleteLog = () => {
        dispatch(delLog(values.id))
        history.push('/istorija-zapisa')

    }

    const submitForm = () => {
        const { id, vehicle_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories } = values;
        const priceParts = Number(price_parts);
        const priceWork = Number(price_work);
        const subcategoriesIds = service_subcategories.map(sub => sub.id)
        const newLogDate = date || formatDateForServer(new Date())
        if (title === '' || text === '' || vehicle_id === '' || service_type_id === '' || service_category_id === '') {
            setError(true)
        } else {
            if (id !== '') {
                submitServiceLog(
                    id,
                    vehicle_id,
                    me.id,
                    service_type_id,
                    service_category_id,
                    date,
                    title,
                    text,
                    used_parts,
                    priceParts,
                    priceWork,
                    mileage,
                    subcategoriesIds
                )
                setTimeout(() => dispatch(getServiceLog(id)), 100) //load subcategories on edit *quick fix*
            } else {
                submitServiceLog(
                    vehicle_id,
                    me.id,
                    service_type_id,
                    service_category_id,
                    newLogDate,
                    title,
                    text,
                    used_parts,
                    priceParts,
                    priceWork,
                    mileage,
                    attachments,
                    subcategoriesIds
                )
                history.push('/istorija-zapisa')
                dispatch(getServiceLogs(1, 10))
            }

            setError(false)

        }
    }


    return (
        <CardForm
            className='add-form'
            title={title}
            icon={faWrench}
            price_total={values.price_total}
        >
            {loading ?
                <Loader
                    marginTop='100px'
                />
                :
                <React.Fragment>
                    <SelectSearch
                        label='Vozilo'
                        required={true}
                        options={vehiclesList}
                        value={vehiclesList?.filter(x => x.id === values.vehicle_id)}
                        onChange={(option) => {
                            setValues({ ...values, vehicle_id: option.id, label: option.label })
                        }}
                    />
                    <Select
                        label='Tip servisa'
                        list={serviceTypes}
                        name='service_type_id'
                        required={true}
                        value={values.service_type_id || initialState.service_type_id}
                        onChange={handleInput}
                    />
                    <Select
                        label='Kategorija'
                        list={serviceCategories}
                        name='service_category_id'
                        required={true}
                        value={values.service_category_id || initialState.service_category_id}
                        onChange={handleInput}
                    />
                    <MultipleSelect
                        label='Potkategorije'
                        options={serviceSubcategoriesList}
                        selectedValues={values.service_subcategories}
                        onSelect={handleMultipleValues}
                        onRemove={removeMultipleValues}
                        disable={values.service_category_id === "" ? true : false}
                    />
                    <CommonDatePicker
                        cardText='Datum'
                        required={true}
                        handleDate={handleDate}
                        date={log?.date}
                        className='picker-width-20'
                    />
                    <InputField
                        label='Naslov'
                        required={true}
                        name='title'
                        value={values.title || initialState.title}
                        onChange={handleInput}
                    />
                    <TextAreaField
                        label='Opis'
                        required={true}
                        name='text'
                        value={values.text || initialState.text}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Korišćeni delovi'
                        type='text'
                        name='used_parts'
                        value={values.used_parts || initialState.used_parts}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Cena za delove'
                        type='number'
                        name='price_parts'
                        value={values.price_parts || initialState.price_parts}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Cena usluge'
                        type='number'
                        name='price_work'
                        value={values.price_work || initialState.price_work}
                        onChange={handleInput}
                    />
                    <InputField
                        label='Kilometraža'
                        type='number'
                        name='mileage'
                        value={values.mileage || initialState.mileage}
                        onChange={handleInput}
                    />
                    {createReport ?
                        <Fragment>
                            <UploadFile
                                label='Dodavanje datoteka'
                                name='attachments'
                                infoText={`Dozvoljeni fajlovi: pdf, jpg, jpeg, png. Trenutno fajlova: ${values.attachments?.length || initialState.attachments.length}`}
                                onChange={handleFiles}
                            />
                            {successUpload && <SuccessAlert content='Datoteke su dodate.' onClose={() => setSuccessUpload(false)} />}
                        </Fragment>
                        :
                        <div className='media-and-pdf'>
                            <CommonButton
                                variant="primary"
                                label={`Datoteke ${values.mediaCount}`}
                                onClick={() => history.push(`/istorija-zapisa/${values.id}/media`)}
                                icon={faFile}
                                style={{ width: '20%' }}
                            />
                            <PdfReport pdfUrl={values.pdf_show_url} />
                        </div>
                    }
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CommonButton
                            variant="danger"
                            label='Sačuvaj'
                            onClick={submitForm}
                            icon={faSave}
                            style={reportEdit ? { width: '20%' } : { width: '100%' }}
                        />
                        {reportEdit &&
                            <CommonButton
                                variant="dark"
                                label='Obriši'
                                onClick={handleModal}
                                icon={faTrash}
                                style={{ width: '20%' }}
                            />
                        }
                    </div>
                </React.Fragment>
            }
            {error && (
                <ErrorAlert
                    content='Polja označena zvezdicom su obavezna.'
                />
            )}
            {modal && <Modal close={handleModal} deleteItem={deleteLog} />}
        </CardForm>
    )
}

export default React.memo(ReportForm)
