export const GET_TODO = 'GET_TODO';
export const GET_TODO_SUCCESS = 'GET_TODO_SUCCESS';
export const GET_TODO_ERROR = 'GET_TODO_ERROR';

export const ADD_TODO = 'ADD_TODO';
export const ADD_TODO_SUCCESS = 'ADD_TODO_SUCCESS';
export const ADD_TODO_ERROR = 'ADD_TODO_ERROR';

export const GET_TODO_TASK = 'GET_TODO_TASK';
export const GET_TODO_TASK_SUCCESS = 'GET_TODO_TASK_SUCCESS';
export const GET_TODO_TASK_ERROR = 'GET_TODO_TASK_ERROR';

export const DELETE_TODO_TASK = 'DELETE_TODO_TASK';
export const DELETE_TODO_TASK_SUCCESS = 'DELETE_TODO_TASK_SUCCESS';
export const DELETE_TODO_TASK_ERROR = 'DELETE_TODO_TASK_ERROR';

export const UPDATE_TODO_TASK = 'UPDATE_TODO_TASK';
export const UPDATE_TODO_TASK_SUCCESS = 'UPDATE_TODO_TASK_SUCCESS';
export const UPDATE_TODO_TASK_ERROR = 'UPDATE_TODO_TASK_ERROR';