import React, { Fragment, useState, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import CardForm from '../../Common/Card/CardForm';
import CardTable from '../../Common/Card/CardTable';
import { formatDate, tableHeadList } from '../../../helpers/common';
import Loader from '../../Common/Loader';
import Pagination from './../../Common/Pagination';
import MultileParametersSearch from '../../Common/Search/MultileParametersSearch';

import { getToDo, updateToDoTask } from './../../../store/actions';


const TodoList = (props) => {
    const [current, setCurrent] = useState(1);
    const [meta, setMeta] = useState({});
    const perPage = 10;
    const dispatch = useDispatch();
    const history = useHistory();
    const state = useSelector(state => state.Me)

    useEffect(() => {
        setMeta(props.meta)
    }, [props.meta])

    useEffect(() => {
        const page = meta && meta.current_page;
        setCurrent(page)
    }, [meta])

    const handleClick = (e) => {
        const page = parseInt(e.target.id)
        setCurrent(page)
        dispatch(getToDo(page, perPage))
    }

    const clickableRow = (id) => {
        history.push(`/pregled-radnih-zadataka/${id}`)
    }

    const handleFinishTask = (task) => {
        const { id, assignee_id, vehicle_id, service_type_id, service_category_id, date, title, text } = task
        const status_id = 20;
        dispatch(updateToDoTask(
            id,
            state?.me?.business_id,
            vehicle_id,
            assignee_id,
            service_type_id,
            service_category_id,
            status_id,
            date,
            title,
            text,
        ))
    }

    return (
        <CardForm
            title={props.title}
            icon={faListAlt}
        >
            <div className='list-of-tasks'>
                <MultileParametersSearch
                    users={props.users}
                    vehicles={props.vehicles}
                    customers={props.customers}
                    todoStatuses={props.todoStatuses}
                    current={current}
                    perPage={perPage}
                />
                {props.loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        <CardTable
                            headListItems={tableHeadList.todo}
                        >
                            {props.tasksList &&
                                props.tasksList.length > 0 &&
                                props.tasksList.map((task, index) => {
                                    let vehicle = [task.vehicle];
                                    return (
                                        <tr className='list-grid' key={index} >
                                            <td onClick={() => clickableRow(task.id)}>{formatDate(task.date)}</td>
                                            {vehicle.map((item, index) => (
                                                <Fragment key={index}>
                                                    <td onClick={() => clickableRow(task.id)}>{item.production_year} {item.vehicle_manufacturer.name} {item.vehicle_model.name} <br /> {item.label}</td>
                                                    <td onClick={() => clickableRow(task.id)}>{item.vehicle_plate}</td>
                                                </Fragment>
                                            ))}

                                            <td onClick={() => clickableRow(task.id)}>{task.title}</td>
                                            <td onClick={() => clickableRow(task.id)}>{task.text}</td>
                                            <td onClick={() => clickableRow(task.id)}>{task.status_id_string}</td>
                                            <td>
                                                <input
                                                    style={{ width: '15px', height: '15px', cursor: 'pointer' }}
                                                    type='checkbox'
                                                    checked={task.status_id === 20 ? true : false}
                                                    disabled={task.status_id === 20 ? true : false}
                                                    onChange={() => handleFinishTask(task)}
                                                />
                                            </td>
                                        </tr>
                                    )
                                }
                                )}
                        </CardTable>
                        {meta && meta.total > 10 &&
                            <Pagination
                                data={meta && parseInt(meta.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }
            </div>
        </CardForm>
    )
}

export default TodoList
