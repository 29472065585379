import React from 'react';
import { Card, Accordion, Badge } from 'react-bootstrap';
import { faUser, faCar, faStickyNote } from '@fortawesome/free-solid-svg-icons';

import CardTitle from './CardTitle';
import CommonButton from '../CommonButton';

const CardForm = (props) => {
    return (
        <Card className={props.className}>
            <Accordion defaultActiveKey="0">
                <Card.Header>
                    <CardTitle
                        title={props.title}
                        icon={props.icon}
                    />
                    {props.finishButton ?
                        <CommonButton
                            label='Završeno'
                            variant='danger'
                            onClick={props.onClick}
                            style={{ width: '20%' }}
                        />
                        :
                        props.price_total ?
                            <h4><Badge variant="danger">Ukupna cena: {props.price_total}</Badge></h4>
                            :
                            props.addVehicleBtn ?
                                <CommonButton
                                    style={{ width: '20%' }}
                                    variant='danger'
                                    type='button'
                                    name='vehicle'
                                    onClick={props.onClick}
                                    icon={faCar}
                                    label='Dodaj vozilo'
                                />
                                :
                                props.addReportBtn ?
                                    <CommonButton
                                        style={{ width: '20%' }}
                                        variant='danger'
                                        type='button'
                                        name='report'
                                        onClick={props.onClick}
                                        icon={faStickyNote}
                                        label='Dodaj zapis'
                                    />
                                    :
                                    props.addCustomerBtn &&
                                    <CommonButton
                                        style={{ width: '20%' }}
                                        variant='danger'
                                        type='button'
                                        name='customer'
                                        onClick={props.onClick}
                                        icon={faUser}
                                        label='Dodaj klijenta'
                                    />
                        // :
                        // <Accordion.Toggle as={Button} variant="link" eventKey="0">
                        //     <FontAwesomeIcon icon={faEye} color="#000" />
                        // </Accordion.Toggle>
                    }


                </Card.Header>
                <Accordion.Collapse eventKey="0">
                    <Card.Body>
                        {props.children}
                    </Card.Body>
                </Accordion.Collapse>
            </Accordion>
        </Card>
    )
}

export default CardForm
