import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Tabs, Tab } from 'react-bootstrap';
import {
    getCustomer,
    updateCustomer,
    deleteCustomer,
    getCustomerVehicles,
    getCustomerServiceLogs,
    getVehicles,
    // getCustomerRoles,
    deleteCustomerVehiclesData,
    createCustomerVehiclesData,
    updateCustomerVehiclesData,
    // getCustomerVehiclesData
} from './../../store/actions';
import EditUser from '../../components/Forms/EditUser';
import Loader from '../../components/Common/Loader';
import VehiclesList from './../../components/Lists/VehiclesList';
import CustomerDataList from './../../components/Lists/CustomerDataList';
import { getUrlId } from './../../helpers/common';
import { faCar, faStickyNote } from '@fortawesome/free-solid-svg-icons';


class SingleCustomer extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            listOfVehicles: []
        };
        this.id = getUrlId();
    }

    componentDidMount() {
        this.props.getCustomer(this.id)
        this.props.getCustomerVehicles(this.id)
        this.props.getCustomerServiceLogs(this.id)
        // this.props.getCustomerRoles()
        this.props.getVehicles(1, 1000)
        // this.props.getCustomerVehiclesData()
    }

    componentDidUpdate(prevProps) {
        if (this.props.vehicles !== undefined) {
            if (this.state.listOfVehicles.length === 0) {

                let v = this.props.vehicles?.map(item => {
                    item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`.split(' ').filter((value, index, self) => {
                        return self.indexOf(value) === index;
                    }).join(' ')
                    return item
                })

                this.setState({
                    listOfVehicles: v
                })
            }
        }

        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.getCustomer(this.props.match.params.id)
        }
    }

    handleSubmit = (first_name, last_name, email, phone, address, postal_code, city, country_id, note) => {
        this.props.updateCustomer(this.id, this.props.me.business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note);
    }

    delCustomer = () => {
        this.props.deleteCustomer(this.id, this.props.history)
    }

    render() {
        return (
            <div className='page'>
                <Tabs defaultActiveKey="info" style={{ color: '#000' }} onSelect={(k) => {
                    if (k === 'vehicles') {
                        this.props.getCustomerVehicles(this.id)
                    }
                }}>
                    <Tab eventKey="info" title="Osnovne informacije">
                        {this.props.loading ?
                            <Loader
                                marginTop='200px'
                            />
                            :
                            <EditUser
                                customer={true}
                                delUser={this.delCustomer}
                                user={this.props.customer}
                                handleSubmit={this.handleSubmit}
                                getCustomer={this.props.getCustomer}
                                getCustomerVehiclesData={this.props.getCustomerVehiclesData}
                                listOfVehicles={this.state.listOfVehicles}
                                customerRoles={this.props.customerRoles}
                                deleteCustomerVehicle={this.props.deleteCustomerVehiclesData}
                                createCustomerVehicles={this.props.createCustomerVehiclesData}
                                updateCustomerVehicles={this.props.updateCustomerVehiclesData}
                            // customerVehiclesData={this.props.customerVehiclesData}
                            />
                        }
                    </Tab>
                    <Tab eventKey="vehicles" title="Vozila" >
                        <VehiclesList
                            title='Spisak vozila klijenta'
                            icon={faCar}
                            vehiclesList={this.props.customerVehicles.data}
                            style={{ display: 'none' }}
                            addVehicleBtn={true}
                            onClick={() => this.props.history.push({ pathname: '/dodavanje-vozila', state: { customer_id: this.id } })}
                        />
                    </Tab>
                    <Tab eventKey="reports" title="Servisni zapisi">
                        <CustomerDataList
                            title='Spisak servisnih zapisa'
                            icon={faStickyNote}
                            reportsList={this.props.customerServiceLogs.data}
                            meta={this.props.customerServiceLogs.meta}
                            addReportBtn={true}
                            onClick={() => this.props.history.push('/dodavanje-zapisa')}
                        />
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Customers.loading,
        customer: state.Customers.customer.data,
        me: state.Me.me,
        customerVehicles: state.Customers.customerVehicles,
        customerServiceLogs: state.Customers.customerServiceLogs,
        vehicles: state.Vehicles.vehicles.data,
        customerRoles: state.StaticTypes.customerRoles,
        customerVehiclesData: state.Customers.customerVehiclesData.data
    }
}

export default connect(mapStateToProps, {
    getCustomer,
    updateCustomer,
    deleteCustomer,
    getCustomerVehicles,
    getCustomerServiceLogs,
    getVehicles,
    // getCustomerRoles,
    deleteCustomerVehiclesData,
    createCustomerVehiclesData,
    updateCustomerVehiclesData,
    // getCustomerVehiclesData
})(withRouter(SingleCustomer))
