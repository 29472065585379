import React, { useState } from 'react';
import { faLock, faExchangeAlt } from '@fortawesome/free-solid-svg-icons';
import { useDispatch, useSelector } from 'react-redux';
import CommonButton from '../Common/CommonButton';
import CardForm from '../Common/Card/CardForm';
import InputField from './../Common/InputField';
import ErrorAlert from './../Common/ErrorAlert';
import SuccessAlert from '../Common/SuccessAlert';

const initialState = {
    old_password: '',
    password: '',
    password_confirmation: ''
}

const ChangePassword = (props) => {
    const [values, setValues] = useState(initialState);
    const [error, setError] = useState(false);
    const [success, setSuccess] = useState(false)
    const dispatch = useDispatch()
    const data = useSelector(state => state.Users)

    const handleInput = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const submitForm = () => {
        const { old_password, password, password_confirmation } = values
        if (values.password !== '' && values.password_confirmation !== '' && values.password.length >= 8 && values.password_confirmation.length >= 8) {
            if (values.password === values.password_confirmation) {
                setError(false)
                setSuccess(true)
                dispatch(props.updatePassword(
                    props.id,
                    old_password,
                    password,
                    password_confirmation
                ))
                setValues(initialState)
            } else {
                setError(true)
            }
        } else {
            setError(true)
        }

    }

    return (
        <CardForm
            title={props.title}
            icon={faLock}
        >
            <InputField
                label='Stara lozinka'
                type='password'
                name='old_password'
                value={values.old_password}
                onChange={handleInput}
                required={true}
            />
            <InputField
                label='Lozinka'
                type='password'
                name='password'
                value={values.password}
                onChange={handleInput}
                required={true}
                infoText='Lozinka mora imati izmedju 8 i 255 karaktera'
            />
            <InputField
                label='Potvrda lozinke'
                type='password'
                name='password_confirmation'
                value={values.password_confirmation}
                onChange={handleInput}
                required={true}
                infoText='Lozinka mora imati izmedju 8 i 255 karaktera'
            />
            <CommonButton
                variant="danger"
                label='Promeni lozinku'
                onClick={submitForm}
                icon={faExchangeAlt}
            />
            {error && <ErrorAlert content='Lozinke se ne podudaraju' />}
            {success && <SuccessAlert content={data.message} />}
        </CardForm>

    )
}

export default ChangePassword
