import React from 'react'

const Loader = (props) => {
    return (
        <div className="loading" style={{ marginTop: props.marginTop, marginBottom: props.marginBottom }}>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
            <div className="dot"></div>
        </div>
    )
}

export default Loader
