import {
    GET_CUSTOMERS,
    GET_CUSTOMERS_SUCCESS,
    GET_CUSTOMERS_ERROR,
    CREATE_CUSTOMER,
    CREATE_CUSTOMER_SUCCESS,
    CREATE_CUSTOMER_ERROR,
    GET_CUSTOMER,
    GET_CUSTOMER_SUCCESS,
    GET_CUSTOMER_ERROR,
    DELETE_CUSTOMER,
    DELETE_CUSTOMER_SUCCESS,
    DELETE_CUSTOMER_ERROR,
    UPDATE_CUSTOMER,
    UPDATE_CUSTOMER_SUCCESS,
    UPDATE_CUSTOMER_ERROR,
    GET_CUSTOMER_VEHICLES,
    GET_CUSTOMER_VEHICLES_SUCCESS,
    GET_CUSTOMER_VEHICLES_ERROR,
    GET_CUSTOMER_SERVICE_LOGS,
    GET_CUSTOMER_SERVICE_LOGS_SUCCESS,
    GET_CUSTOMER_SERVICE_LOGS_ERROR,
    GET_CUSTOMER_VEHICLES_DATA,
    GET_CUSTOMER_VEHICLES_DATA_SUCCESS,
    GET_CUSTOMER_VEHICLES_DATA_ERROR,
    CREATE_CUSTOMER_VEHICLES_DATA,
    CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    CREATE_CUSTOMER_VEHICLES_DATA_ERROR,
    SHOW_CUSTOMER_VEHICLES_DATA,
    SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS,
    SHOW_CUSTOMER_VEHICLES_DATA_ERROR,
    UPDATE_CUSTOMER_VEHICLES_DATA,
    UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    UPDATE_CUSTOMER_VEHICLES_DATA_ERROR,
    DELETE_CUSTOMER_VEHICLES_DATA,
    DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS,
    DELETE_CUSTOMER_VEHICLES_DATA_ERROR,
} from './actionTypes';

export const getCustomers = (page, perPage, query) => {
    return {
        type: GET_CUSTOMERS,
        payload: { page, perPage, query }
    }
}

export const getCustomersSuccess = (customers) => {
    return {
        type: GET_CUSTOMERS_SUCCESS,
        payload: customers
    }
}

export const getCustomersError = (message) => {
    return {
        type: GET_CUSTOMERS_ERROR,
        payload: message
    }
}

export const createCustomer = (business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note) => {
    return {
        type: CREATE_CUSTOMER,
        payload: { business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note }
    }
}

export const createCustomerSuccess = (message) => {
    return {
        type: CREATE_CUSTOMER_SUCCESS,
        payload: message
    }
}

export const createCustomerError = (error) => {
    return {
        type: CREATE_CUSTOMER_ERROR,
        payload: error
    }
}

export const getCustomer = (id) => {
    return {
        type: GET_CUSTOMER,
        payload: { id }
    }
}

export const getCustomerSuccess = (customer) => {
    return {
        type: GET_CUSTOMER_SUCCESS,
        payload: customer
    }
}

export const getCustomerError = (message) => {
    return {
        type: GET_CUSTOMER_ERROR,
        payload: message
    }
}

export const updateCustomer = (id, business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note) => {
    return {
        type: UPDATE_CUSTOMER,
        payload: { id, business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note }
    }
}

export const updateCustomerSuccess = (customer) => {
    return {
        type: UPDATE_CUSTOMER_SUCCESS,
        payload: customer
    }
}

export const updateCustomerError = (message) => {
    return {
        type: UPDATE_CUSTOMER_ERROR,
        payload: message
    }
}

export const deleteCustomer = (id, history) => {
    return {
        type: DELETE_CUSTOMER,
        payload: { id, history }
    }
}

export const deleteCustomerSuccess = (message) => {
    return {
        type: DELETE_CUSTOMER_SUCCESS,
        payload: message
    }
}

export const deleteCustomerError = (message) => {
    return {
        type: DELETE_CUSTOMER_ERROR,
        payload: message
    }
}


export const getCustomerVehicles = (id) => {
    return {
        type: GET_CUSTOMER_VEHICLES,
        payload: { id }
    }
}

export const getCustomerVehiclesSuccess = (vehicles) => {
    return {
        type: GET_CUSTOMER_VEHICLES_SUCCESS,
        payload: vehicles
    }
}

export const getCustomerVehiclesError = (message) => {
    return {
        type: GET_CUSTOMER_VEHICLES_ERROR,
        payload: message
    }
}


export const getCustomerServiceLogs = (id) => {
    return {
        type: GET_CUSTOMER_SERVICE_LOGS,
        payload: { id }
    }
}

export const getCustomerServiceLogsSuccess = (serviceLogs) => {
    return {
        type: GET_CUSTOMER_SERVICE_LOGS_SUCCESS,
        payload: serviceLogs
    }
}

export const getCustomerServiceLogsError = (message) => {
    return {
        type: GET_CUSTOMER_SERVICE_LOGS_ERROR,
        payload: message
    }
}


// Customer vehicles endpoint

export const getCustomerVehiclesData = () => {
    return {
        type: GET_CUSTOMER_VEHICLES_DATA
    }
}

export const getCustomerVehiclesDataSuccess = (vehiclesData) => {
    return {
        type: GET_CUSTOMER_VEHICLES_DATA_SUCCESS,
        payload: vehiclesData
    }
}

export const getCustomerVehiclesDataError = (message) => {
    return {
        type: GET_CUSTOMER_VEHICLES_DATA_ERROR,
        payload: message
    }
}

export const createCustomerVehiclesData = (data) => {
    return {
        type: CREATE_CUSTOMER_VEHICLES_DATA,
        payload: { data }
    }
}

export const createCustomerVehiclesDataSuccess = (vehiclesData) => {
    return {
        type: CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
        payload: vehiclesData
    }
}

export const createCustomerVehiclesDataError = (message) => {
    return {
        type: CREATE_CUSTOMER_VEHICLES_DATA_ERROR,
        payload: message
    }
}

export const showCustomerVehiclesData = (id) => {
    return {
        type: SHOW_CUSTOMER_VEHICLES_DATA,
        payload: { id }
    }
}

export const showCustomerVehiclesDataSuccess = (vehicle) => {
    return {
        type: SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS,
        payload: vehicle
    }
}

export const showCustomerVehiclesDataError = (message) => {
    return {
        type: SHOW_CUSTOMER_VEHICLES_DATA_ERROR,
        payload: message
    }
}

export const updateCustomerVehiclesData = (id, vehicle_id, customer_id, customer_role_id) => {
    return {
        type: UPDATE_CUSTOMER_VEHICLES_DATA,
        payload: { id, vehicle_id, customer_id, customer_role_id }
    }
}

export const updateCustomerVehiclesDataSuccess = (vehiclesData) => {
    return {
        type: UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS,
        payload: vehiclesData
    }
}

export const updateCustomerVehiclesDataError = (message) => {
    return {
        type: UPDATE_CUSTOMER_VEHICLES_DATA_ERROR,
        payload: message
    }
}

export const deleteCustomerVehiclesData = (id) => {
    return {
        type: DELETE_CUSTOMER_VEHICLES_DATA,
        payload: { id }
    }
}

export const deleteCustomerVehiclesDataSuccess = (message) => {
    return {
        type: DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS,
        payload: message
    }
}

export const deleteCustomerVehiclesDataError = (message) => {
    return {
        type: DELETE_CUSTOMER_VEHICLES_DATA_ERROR,
        payload: message
    }
}