import React, { Component } from 'react';
import { FormText } from 'react-bootstrap';
import { Link, withRouter } from 'react-router-dom';
import { connect } from 'react-redux';

import InputField from '../../components/Common/InputField';
import CommonButton from '../../components/Common/CommonButton';
import ErrorAlert from '../../components/Common/ErrorAlert';
import { getCookie, setCookie, validateEmail } from '../../helpers/common';
import LoginCard from '../../components/Common/Card/LoginCard';
import Checkbox from '../../components/Common/Checkbox';

import { loginUser } from './../../store/actions';
import Loader from './../../components/Common/Loader'

const authUser = localStorage.getItem('authUser');

class Login extends Component {
    constructor(props) {
        super(props);
        this.state = {
            email: '',
            password: '',
            rememberMe: false,
            error: false
        };
    }

    componentDidMount() {
        if (authUser) {
            this.props.history.push('/kontrolna-tabla')
        }
        const rememberMeCookie = getCookie('rememberMe')
        if (rememberMeCookie) {
            const emailCookie = getCookie('email')
            this.setState({
                email: emailCookie
            })
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (prevProps.error !== this.props.error) {
            if (this.props.error) {
                this.setState({
                    error: true
                })
            } else {
                this.setState({
                    error: false
                })
            }
        }
    }

    handleInput = (e) => {
        if (e.target.name !== '') {
            this.setState({
                [e.target.name]: e.target.value,
            })
        }
    }

    handleCheckbox = () => {
        this.setState({
            rememberMe: !this.state.rememberMe
        })
    }

    handleSubmit = (e) => {
        e.preventDefault();
        const { email, password, rememberMe } = this.state;
        if (!validateEmail(email) || password === '') {
            this.setState({
                error: true
            })
        } else if (this.props.error) {
            this.setState({
                error: true
            })
        } else {
            this.setState({
                error: false
            })
        }

        setCookie('email', email)
        setCookie('rememberMe', rememberMe)
        this.props.loginUser({ email, password }, this.props.history)

    }
    render() {
        return (
            <LoginCard>
                <InputField
                    label='Email adresa'
                    type='email'
                    name='email'
                    value={this.state.email}
                    onChange={this.handleInput}
                />
                <InputField
                    label='Lozinka'
                    type='password'
                    name='password'
                    value={this.state.password}
                    onChange={this.handleInput}
                />
                <div className='remember-forgot-container'>
                    <Checkbox
                        label='Zapamti me'
                        value={this.state.rememberMe}
                        onChange={this.handleCheckbox}
                    />
                    <Link to={{ pathname: '/zaboravljena-lozinka' }}>Zaboravljena lozinka</Link>
                </div>
                <CommonButton
                    label='Prijavi se'
                    type='submit'
                    variant='danger'
                    onClick={this.handleSubmit}
                />
                <FormText>Ukoliko imate bilo kakve predloge, probleme ili pitanja, možete nas kontaktirati preko <a href='https://www.facebook.com/servisnaknjizicaonline' target="_blank" rel="noreferrer">Servisna Knjižica</a> stranice na Fejsbuku.</FormText>
                {this.props.loading ?
                    <Loader marginBottom='40px' />
                    :
                    this.state.error &&
                    <ErrorAlert
                        onClose={() => this.setState({ error: false })}
                        content='Email adresa i lozinka ne odgovaraju nijednom nalogu.'
                    />
                }
            </LoginCard>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Login.loading,
        error: state.Login.error
    }
}

export default withRouter(connect(mapStateToProps, { loginUser })(Login))
