import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getVehicles, getMe, createServiceLog, getServiceTypes, getServiceCategories } from '../../store/actions';

import ReportForm from '../../components/Forms/ReportForm';

class AddReport extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getVehicles(1, 1000)
        if (this.props.serviceTypes.length === 0) {
            this.props.getServiceTypes()
        }
        if (this.props.serviceCategories.length === 0) {
            this.props.getServiceCategories()
        }
        if (!this.props.me) {
            this.props.getMe()
        }
    }

    render() {
        return (
            <div className='page'>
                <ReportForm
                    title='Dodavanje zapisa'
                    vehicles={this.props.vehicles}
                    me={this.props.me}
                    submitServiceLog={this.props.createServiceLog}
                    serviceTypes={this.props.serviceTypes}
                    serviceCategories={this.props.serviceCategories}
                    createReport={true}
                    idFromVehicle={this.props.location.state?.vehicle_id}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.ServiceLogs.loading,
        vehicles: state.Vehicles.vehicles.data,
        serviceTypes: state.StaticTypes.serviceTypes,
        serviceCategories: state.StaticTypes.serviceCategories,
        me: state.Me.me
    }
}

export default connect(mapStateToProps, {
    getVehicles,
    getMe,
    createServiceLog,
    getServiceTypes,
    getServiceCategories
})(AddReport)
