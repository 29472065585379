import {
    GET_VEHICLES,
    GET_VEHICLES_SUCCESS,
    GET_VEHICLES_ERROR,
    GET_VEHICLE,
    GET_VEHICLE_SUCCESS,
    GET_VEHICLE_ERROR,
    CREATE_VEHICLE,
    CREATE_VEHICLE_SUCCESS,
    CREATE_VEHICLE_ERROR,
    UPDATE_VEHICLE,
    UPDATE_VEHICLE_SUCCESS,
    UPDATE_VEHICLE_ERROR,
    DELETE_VEHICLE,
    DELETE_VEHICLE_SUCCESS,
    DELETE_VEHICLE_ERROR,
    GET_VEHICLE_CUSTOMERS,
    GET_VEHICLE_CUSTOMERS_SUCCESS,
    GET_VEHICLE_CUSTOMERS_ERROR,
    GET_VEHICLE_SERVICE_LOGS,
    GET_VEHICLE_SERVICE_LOGS_SUCCESS,
    GET_VEHICLE_SERVICE_LOGS_ERROR
} from './actionType';

export const getVehicles = (page, perPage, query) => {
    return {
        type: GET_VEHICLES,
        payload: { page, perPage, query }
    }
}

export const getVehiclesSuccess = (vehicles) => {
    return {
        type: GET_VEHICLES_SUCCESS,
        payload: vehicles
    }
}

export const getVehiclesError = (message) => {
    return {
        type: GET_VEHICLES_ERROR,
        payload: message
    }
}

export const getVehicle = (id) => {
    return {
        type: GET_VEHICLE,
        payload: { id }
    }
}

export const getVehicleSuccess = (vehicle) => {
    return {
        type: GET_VEHICLE_SUCCESS,
        payload: vehicle
    }
}

export const getVehicleError = (message) => {
    return {
        type: GET_VEHICLE_ERROR,
        payload: message
    }
}

export const createVehicle = (business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date) => {
    return {
        type: CREATE_VEHICLE,
        payload: { business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date }
    }
}

export const createVehicleSuccess = (vehicle) => {
    return {
        type: CREATE_VEHICLE_SUCCESS,
        payload: vehicle
    }
}

export const createVehicleError = (message) => {
    return {
        type: CREATE_VEHICLE_ERROR,
        payload: message
    }
}

export const updateVehicle = (id, business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date) => {
    return {
        type: UPDATE_VEHICLE,
        payload: { id, business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date }
    }
}

export const updateVehicleSuccess = (vehicle) => {
    return {
        type: UPDATE_VEHICLE_SUCCESS,
        payload: vehicle
    }
}

export const updateVehicleError = (message) => {
    return {
        type: UPDATE_VEHICLE_ERROR,
        payload: message
    }
}

export const deleteVehicle = (id) => {
    return {
        type: DELETE_VEHICLE,
        payload: { id }
    }
}

export const deleteVehicleSuccess = (message) => {
    return {
        type: DELETE_VEHICLE_SUCCESS,
        payload: message
    }
}

export const deleteVehicleError = (message) => {
    return {
        type: DELETE_VEHICLE_ERROR,
        payload: message
    }
}

export const getVehicleCustomers = (id) => {
    return {
        type: GET_VEHICLE_CUSTOMERS,
        payload: { id }
    }
}

export const getVehicleCustomersSuccess = (customers) => {
    return {
        type: GET_VEHICLE_CUSTOMERS_SUCCESS,
        payload: customers
    }
}

export const getVehicleCustomersError = (message) => {
    return {
        type: GET_VEHICLE_CUSTOMERS_ERROR,
        payload: message
    }
}

export const getVehicleServiceLogs = (id) => {
    return {
        type: GET_VEHICLE_SERVICE_LOGS,
        payload: { id }
    }
}

export const getVehicleServiceLogsSuccess = (serviceLogs) => {
    return {
        type: GET_VEHICLE_SERVICE_LOGS_SUCCESS,
        payload: serviceLogs
    }
}

export const getVehicleServiceLogsError = (message) => {
    return {
        type: GET_VEHICLE_SERVICE_LOGS_ERROR,
        payload: message
    }
}