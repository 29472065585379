import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getToDo = async (page, perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id) => {
    let url = `${process.env.REACT_APP_API_URL}/to_dos?per_page=${perPage}&page=${page}&ranges[created_at][from]=${created_at_from || ""}&ranges[created_at][to]=${created_at_to || ""}&ranges[date][from]=${date_from || ""}&ranges[date][to]=${date_to || ""}&filters[assigner_id][]=${assigner_id || ""}&filters[assignee_id][]=${assignee_id || ""}&filters[vehicle_id][]=${vehicle_id || ""}&filters[customer_id][]=${customer_id || ""}&filters[status_id][]=${status_id || ""}`;

    return await axios.get(url, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const addToDo = async (business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/to_dos`, {
        vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategory: service_subcategories
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getTodoTask = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/to_dos/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteTodoTask = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/to_dos/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateToDoTask = async (id, business_id, vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategories) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/to_dos/${id}`, { vehicle_id, assignee_id, service_type_id, service_category_id, status_id, date, title, text, service_subcategory: service_subcategories }, {
        headers: {
            Accept: 'multipart/form-data',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}