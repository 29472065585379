import {
    GET_VEHICLE_MANUFACTURERS,
    GET_VEHICLE_MANUFACTURERS_SUCCESS,
    GET_VEHICLE_MANUFACTURERS_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    manufacturers: [],
    error: ''
}

const VehicleManufacturers = (state = initialState, action) => {
    switch (action.type) {
        case GET_VEHICLE_MANUFACTURERS:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_MANUFACTURERS_SUCCESS:
            return {
                ...state,
                loading: false,
                manufacturers: action.payload.data
            }
        case GET_VEHICLE_MANUFACTURERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default VehicleManufacturers