import { takeEvery, call, put, all, fork } from 'redux-saga/effects';

import { LOGIN_USER } from './actionTypes';
import { loginUserSuccess, loginUserError } from './actions';
import { loginUser as apiLoginUser } from './../../../helpers/user_service/auth_controller';

function* loginUser({ payload }) {
    try {
        let response = yield call(apiLoginUser, payload.data);

        if (response.status === 200 || response.code === 200) {
            yield put(loginUserSuccess(payload.data))
            localStorage.setItem('authUser', response.data.token)
            window.location.pathname = '/kontrolna-tabla'
        } else {
            yield put(loginUserError('Email adresa i lozinka ne odgovaraju nijednom nalogu.'))
        }
    } catch (error) {
        yield put(loginUserError('Email adresa i lozinka ne odgovaraju nijednom nalogu.'))
    }
}

export function* watchLoginUser() {
    yield takeEvery(LOGIN_USER, loginUser)
}

function* authSaga() {
    yield all([
        fork(watchLoginUser)
    ])
}

export default authSaga