import React, { Component, Fragment } from 'react';
import { faSave, faUser } from '@fortawesome/free-solid-svg-icons';
import { withRouter } from 'react-router-dom';



import { roles, validateEmail } from './../../helpers/common';

import InputField from './../Common/InputField';
import Select from './../Common/Select';
import TextAreaField from './../Common/TextAreaField';
import CommonButton from './../Common/CommonButton';
import ErrorAlert from './../Common/ErrorAlert';
import CardForm from '../Common/Card/CardForm';
import SelectSearch from '../Common/SelectSearch';
import { Form } from 'react-bootstrap';

class AddUser extends Component {
    constructor(props) {
        super(props);
        this.state = {
            status_id: '',
            first_name: '',
            last_name: '',
            email: '',
            phone: '',
            address: '',
            postal_code: '',
            city: '',
            country_id: '194',
            note: '',
            password: '',
            password_confirmation: '',
            error: false,
            role_id: '',
            message: '',
            vehiclesForm: [],
            vehicles: []
        }
    }

    componentDidMount() {
        if (this.props.history?.location?.state?.vehicle_id) {
            this.setState(prevState => ({
                ...prevState,
                vehiclesForm: [...prevState.vehiclesForm, { vehicle_id: Number(this.props.history?.location?.state?.vehicle_id), customer_id: null, customer_role_id: 40 }]
            }))
        }
    }

    componentDidUpdate(prevProps, prevState) {
        if (this.state.vehiclesForm !== prevState.vehiclesForm) {
            const results = this.props.vehiclesList.filter(({ id: id1 }) => !this.state.vehiclesForm?.some(({ vehicle_id: id2 }) => id2 === id1));
            this.setState(prevState => ({
                ...prevState,
                vehicles: results
            }))
        }
    }

    handleInput = (e, index) => {
        this.setState({
            [e.target.name]: e.target.value
        })
        if (e.target.name === 'customer_role_id') {
            let obj = [...this.state.vehiclesForm]
            obj[index] = {
                ...this.state.vehiclesForm[index],
                customer_role_id: e.target.value
            }
            this.setState(prevState => ({
                ...prevState,
                vehiclesForm: obj
            }))
        }
    }

    handleVehicleSelect = (option, index) => {
        let obj = [...this.state.vehiclesForm]
        obj[index] = {
            ...this.state.vehiclesForm[index],
            vehicle_id: option.id,
            label: option.label
        }

        this.setState(prevState => ({ ...prevState, vehiclesForm: obj }))
    }

    removeInputVehicle = (index) => {
        let values = this.state.vehiclesForm
        values.splice(index, 1)
        this.setState(prevState => ({ ...prevState, vehiclesForm: values }))
    }

    submitForm = async () => {
        const { role_id, status_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note, password, password_confirmation, vehiclesForm } = this.state

        if (first_name === '' || last_name === '') {
            this.setState({
                error: true,
                message: 'Ime i Prezime su obavezna polja.'
            })
        } else {
            if (this.props.customer) {
                this.props.addCustomer(
                    this.props.me.business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note
                )
                setTimeout(() => {
                    const vehiclesData = vehiclesForm.map(v => ({ vehicle_id: v.vehicle_id, customer_id: localStorage.getItem('customer_id'), customer_role_id: v.customer_role_id }))
                    this.props.createCustomerVehicles(vehiclesData)
                    localStorage.removeItem('customer_id')
                    this.props.history.push('/pregled-klijenata')
                }, 500)

            } else {

                if (role_id !== '' && status_id !== '' && (password === password_confirmation) && validateEmail(email)) {
                    this.props.createUser(
                        this.props.me.business_id,
                        role_id,
                        status_id,
                        first_name,
                        last_name,
                        email,
                        phone,
                        password,
                        password_confirmation
                    )
                    this.setState({
                        error: false,
                        status_id: '',
                        first_name: '',
                        last_name: '',
                        email: '',
                        phone: '',
                        password: '',
                        password_confirmation: '',
                        role_id: '',
                        message: ''
                    })
                } else if (!validateEmail(email)) {
                    this.setState({
                        error: true,
                        message: 'Email nije validan.'
                    })
                } else if (password !== password_confirmation) {
                    this.setState({
                        error: true,
                        message: 'Lozinke se ne podudaraju.'
                    })
                } else if (password.length < 8 || password_confirmation.length < 8) {
                    this.setState({
                        error: true,
                        message: 'Lozinka mora da ima najmanje 8 karaktera.'
                    })
                } else {
                    this.setState({
                        error: true,
                        message: 'Polja označena zvezdicom su obavezna.'
                    })
                }

            }
        }

    }

    render() {
        const { role_id, status_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note, password, password_confirmation } = this.state
        return (
            <CardForm
                className='add-form'
                title={this.props.title}
                icon={faUser}
            >
                <InputField
                    label='Ime'
                    type='text'
                    required={true}
                    value={first_name || ''}
                    onChange={this.handleInput}
                    name='first_name'
                />
                <InputField
                    label='Prezime'
                    type='text'
                    required={true}
                    value={last_name || ''}
                    onChange={this.handleInput}
                    name='last_name'
                />
                <InputField
                    label='Email'
                    type='text'
                    required={!this.props.customer && true}
                    value={email || ''}
                    onChange={this.handleInput}
                    name='email'
                />
                <InputField
                    label='Telefon'
                    type='text'
                    name='phone'
                    value={phone || ''}
                    onChange={this.handleInput}
                />
                {this.props.user &&
                    <Fragment>
                        <Select
                            label='Uloga'
                            list={roles}
                            name='role_id'
                            required={true}
                            value={role_id || ''}
                            onChange={this.handleInput}
                        />
                        <Select
                            label='Status'
                            list={this.props.userStatuses}
                            name='status_id'
                            required={true}
                            value={status_id || ''}
                            onChange={this.handleInput}
                        />
                        <InputField
                            label='Lozinka'
                            type='password'
                            name='password'
                            required={true}
                            value={password || ''}
                            onChange={this.handleInput}
                        />
                        <InputField
                            label='Potvrda lozinke'
                            type='password'
                            name='password_confirmation'
                            required={true}
                            value={password_confirmation || ''}
                            onChange={this.handleInput}
                        />
                    </Fragment>
                }
                {this.props.customer &&
                    <Fragment>
                        <InputField
                            label='Adresa'
                            type='text'
                            name='address'
                            value={address || ''}
                            onChange={this.handleInput}
                        />
                        <InputField
                            label='Poštanski broj'
                            type='text'
                            name='postal_code'
                            value={postal_code || ''}
                            onChange={this.handleInput}
                        />
                        <InputField
                            label='Grad'
                            type='text'
                            name='city'
                            value={city || ''}
                            onChange={this.handleInput}
                        />
                        <InputField
                            label='Id države'
                            type='text'
                            name='country_id'
                            value={country_id || ''}
                            onChange={this.handleInput}
                            display='display-none'
                        />
                        <TextAreaField
                            label='Opis'
                            name='note'
                            value={note || ''}
                            onChange={this.handleInput}
                        />
                        <div className='customer-vehicles-list'>
                            <Form.Label>Vozila od klijenta</Form.Label>
                            <hr style={{ marginTop: '0' }} />
                            {
                                this.state.vehiclesForm.map((vehicle, index) => (
                                    <div className='customer-vehicle' key={index}>
                                        <SelectSearch
                                            label='Vozilo'
                                            options={this.state.vehicles}
                                            value={this.props.vehiclesList?.filter(x => x.id === vehicle.vehicle_id)}
                                            onChange={(option) => this.handleVehicleSelect(option, index)}
                                            styleContainer={{ width: '45%' }}
                                        />
                                        <Select
                                            label='Vlasništvo'
                                            list={this.props.customerRoles}
                                            name='customer_role_id'
                                            value={vehicle.customer_role_id || ''}
                                            onChange={(e) => this.handleInput(e, index)}
                                            styleContainer={{ width: '30%' }}
                                        />
                                        <CommonButton
                                            label='Obrisi'
                                            cls='customer-vehicle-btns'
                                            onClick={() => this.removeInputVehicle(index)}
                                        />
                                    </div>
                                ))}
                            <CommonButton
                                label={this.state.vehiclesForm.length === 0 ? 'Dodaj vozilo' : 'Dodaj još jedno vozilo'}
                                cls='add-customer-vehicle'
                                variant='primary'
                                onClick={() => {
                                    this.setState(prevState => ({ ...prevState, vehiclesForm: [...prevState.vehiclesForm, { vehicle_id: '', label: '', customer_role_id: '' }] }))
                                }}
                            />
                        </div>
                    </Fragment>
                }
                <CommonButton
                    variant="danger"
                    label='Sačuvaj'
                    onClick={this.submitForm}
                    icon={faSave}
                />
                {this.state.error && (
                    <ErrorAlert
                        content={this.state.message}
                    />
                )}
            </CardForm >
        )
    }
}

export default withRouter(AddUser)
