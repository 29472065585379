import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';
import Select from "react-select";

const SelectSearch = ({ options, onChange, value, styleContainer, label, required, hideSelectedOptions }) => {
    return (
        <Form.Group style={styleContainer}>
            <Form.Label>{label} {required && <FontAwesomeIcon icon={faAsterisk} color='#dc3545' />}</Form.Label>
            <Select
                placeholder='Izaberi'
                isSearchable
                value={value}
                options={options}
                onChange={onChange}
                hideSelectedOptions={hideSelectedOptions}
            />
        </Form.Group>
    )
}

export default SelectSearch
