import { takeEvery, all, call, put, fork } from 'redux-saga/effects';
import { GET_BUSINESS, UPLOAD_BUSINESS_LOGO, DELETE_BUSINESS_LOGO } from './actionTypes';
import {
    getBusinessSuccess,
    getBusinessError,
    uploadBusinessLogoSuccess,
    uploadBusinessLogoError,
    deleteBusinessLogoSuccess,
    deleteBusinessLogoError
} from './actions';
import {
    getBusiness as apiGetBusiness,
    uploadBusinessLogo as apiUploadBusinessLogo,
    deleteBusinessLogo as apiDeleteBusinessLogo
} from './../../helpers/business_service/business_controller';

function* getBusiness({ payload }) {
    try {
        const response = yield call(apiGetBusiness, payload.id);
        if (response.status === 200 || response.code === 200) {
            yield put(getBusinessSuccess(response.data))
        }
    } catch (error) {
        yield put(getBusinessError(error))
    }
}

function* uploadBusinessLogo({ payload }) {
    try {
        const response = yield call(apiUploadBusinessLogo, payload.file);
        if (response.status === 200 || response.code === 200) {
            yield put(uploadBusinessLogoSuccess(response.data))
        }
    } catch (error) {
        yield put(uploadBusinessLogoError(error))
    }
}

function* deleteBusinessLogo({ payload }) {
    try {
        const response = yield call(apiDeleteBusinessLogo);
        if (response.status === 200 || response.code === 200) {
            yield put(deleteBusinessLogoSuccess(response.data))
            yield* getBusiness({ payload })
        }
    } catch (error) {
        yield put(deleteBusinessLogoError(error))
    }
}

function* watchGetBusiness() {
    yield takeEvery(GET_BUSINESS, getBusiness)
}
function* watchUploadBusinessLogo() {
    yield takeEvery(UPLOAD_BUSINESS_LOGO, uploadBusinessLogo)
}
function* watchDeleteBusinessLogo() {
    yield takeEvery(DELETE_BUSINESS_LOGO, deleteBusinessLogo)
}

function* businessSaga() {
    yield all([
        fork(watchGetBusiness),
        fork(watchUploadBusinessLogo),
        fork(watchDeleteBusinessLogo)
    ])
}

export default businessSaga