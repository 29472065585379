import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    message: '',
    error: ''
}

const ForgotPassword = (state = initialState, action) => {
    switch (action.type) {
        case FORGOT_PASSWORD:
            return {
                ...state,
                loading: true
            }
        case FORGOT_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                message: action.payload
            }
        case FORGOT_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case RESET_PASSWORD:
            return {
                ...state,
                loading: true,
            }
        case RESET_PASSWORD_SUCCESS:
            return {
                ...state,
                loading: false,
                error: '',
                message: action.payload
            }
        case RESET_PASSWORD_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default ForgotPassword