import axios from 'axios';
import React, { useEffect, useState } from 'react'
import { faFilePdf } from '@fortawesome/free-solid-svg-icons';
import CommonButton from './CommonButton';
import { useParams } from 'react-router';
const token = localStorage.getItem('authUser')

const PdfReport = ({ pdfUrl }) => {
    const { id } = useParams()
    const [pdf, setPdf] = useState('')
    const [loading, setLoading] = useState(false)

    useEffect(() => {
        setLoading(true)
        axios.get(pdfUrl || `${process.env.REACT_APP_API_URL}/service_logs/${id}/pdf/download`, {
            mode: 'no-cors',
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        }).then(response => {
            const url = new Blob([response.data], { type: response.headers['content-type'] })
            var blobUrl = URL.createObjectURL(url);
            setPdf(blobUrl)
            setLoading(false)
        }).catch(function (error) {
            console.error('error:', error);
        });
        return () => setLoading(false)
    }, [pdfUrl, id])

    return (
        <CommonButton
            variant="danger"
            label='Izveštaj'
            onClick={() => !loading && window.open(pdf, '_blank').focus()}
            icon={faFilePdf}
            style={{ width: '20%' }}
            loading={loading}
        />
    )
}

export default PdfReport
