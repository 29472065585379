import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

function Select({ list, controlId, label, style, value, styleContainer, onChange, name, required, disabled, multiple }) {
    return (
        <Form.Group controlId={controlId} style={styleContainer}>
            {label && <Form.Label>{label} {required && <FontAwesomeIcon icon={faAsterisk} color='#dc3545' />}</Form.Label>}
            <Form.Control as="select" multiple={multiple} style={style} onChange={onChange} name={name} value={value} disabled={disabled}>
                <option value="">Izaberi</option>
                {list &&
                    list.length > 0 &&
                    list.map((item, index) => (
                        <option key={index} value={item.id}>{item.name ? item.name : item.label ? item.label : item.first_name}</option>
                    )
                    )}
            </Form.Control>
        </Form.Group>

    )
}

export default Select
