import React from 'react';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const Checkbox = ({ label, value, name, onChange, controlId, required }) => {
    return (
        <Form.Group controlId={controlId} className='checkbox-container'>
            <Form.Control
                type='checkbox'
                value={value}
                name={name}
                onChange={onChange}
            />
            <Form.Label>{label} {required && <FontAwesomeIcon icon={faAsterisk} color='#dc3545' />}</Form.Label>
        </Form.Group>
    )
}


export default Checkbox
