import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import { getServiceLogs, getVehicles, getMe, createServiceLog, getServiceTypes, getServiceCategories, deleteServiceLog } from './../../store/actions';
import ReportsList from '../../components/Lists/ReportsList';

class ServiceBook extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getServiceLogs(1, 10)
        this.props.getVehicles(1, 1000)
        if (this.props.serviceTypes.length === 0) {
            this.props.getServiceTypes()
        }
        if (this.props.serviceCategories.length === 0) {
            this.props.getServiceCategories()
        }
        if (!this.props.me) {
            this.props.getMe()
        }
    }

    render() {
        return (
            <div className='page'>
                <ReportsList
                    title='Istorija zapisa'
                    loading={this.props.loading}
                    // reportsList={this.props.logs}
                    deleteServiceLog={this.props.deleteServiceLog}
                    meta={this.props.meta}
                    historyServiceBook={true}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.ServiceLogs.loading,
        logs: state.ServiceLogs.logs.data,
        vehicles: state.Vehicles.vehicles.data,
        serviceTypes: state.StaticTypes.serviceTypes,
        serviceCategories: state.StaticTypes.serviceCategories,
        me: state.Me.me,
        meta: state.ServiceLogs.logs.meta
    }
}

export default connect(mapStateToProps, {
    getServiceLogs,
    getVehicles,
    getMe,
    createServiceLog,
    getServiceTypes,
    getServiceCategories,
    deleteServiceLog
})(ServiceBook)
