import React from 'react'
import Multiselect from 'multiselect-react-dropdown';
import { Form } from 'react-bootstrap';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAsterisk } from '@fortawesome/free-solid-svg-icons';

const MultipleSelect = ({ options, label, selectedValues, onSelect, onRemove, styleContainer, required, ref, disable }) => {
    return (
        <Form.Group style={styleContainer}>
            {label && <Form.Label>{label} {required && <FontAwesomeIcon icon={faAsterisk} color='#dc3545' />}</Form.Label>}
            <Multiselect
                placeholder='Izaberi/Kucaj za pretragu'
                emptyRecordMsg='Nema potkategorija'
                options={options} // Options to display in the dropdown
                selectedValues={selectedValues} // Preselected value to persist in dropdown
                onSelect={onSelect} // Function will trigger on select event
                onRemove={onRemove} // Function will trigger on remove event
                displayValue="name" // Property name to display in the dropdown options
                ref={ref}
                disable={disable}
            />
        </Form.Group>
    )
}

export default MultipleSelect
