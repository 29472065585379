export const GET_BUSINESS = 'GET_BUSINESS';
export const GET_BUSINESS_SUCCESS = 'GET_BUSINESS_SUCCESS';
export const GET_BUSINESS_ERROR = 'GET_BUSINESS_ERROR';

export const UPLOAD_BUSINESS_LOGO = 'UPLOAD_BUSINESS_LOGO';
export const UPLOAD_BUSINESS_LOGO_SUCCESS = 'UPLOAD_BUSINESS_LOGO_SUCCESS';
export const UPLOAD_BUSINESS_LOGO_ERROR = 'UPLOAD_BUSINESS_LOGO_ERROR';

export const DELETE_BUSINESS_LOGO = 'DELETE_BUSINESS_LOGO';
export const DELETE_BUSINESS_LOGO_SUCCESS = 'DELETE_BUSINESS_LOGO_SUCCESS';
export const DELETE_BUSINESS_LOGO_ERROR = 'DELETE_BUSINESS_LOGO_ERROR';