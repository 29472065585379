import React, { PureComponent } from 'react';
import { connect } from 'react-redux';
import ReportForm from '../../components/Forms/ReportForm';
import { getUrlId } from '../../helpers/common';

import { getServiceLog, updateServiceLog, getServiceTypes, getServiceCategories, getVehicles, deleteServiceLog } from './../../store/actions';

class SingleReport extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        const id = getUrlId();
        this.props.getServiceLog(id);
        if (this.props.serviceTypes.length === 0) {
            this.props.getServiceTypes()
        }
        if (this.props.serviceCategories.length === 0) {
            this.props.getServiceCategories()
        }
        this.props.getVehicles(1, 1000)
    }

    render() {
        return (
            <div className='page'>
                <ReportForm
                    title='Izmeni izvestaj'
                    log={this.props.serviceLog}
                    submitServiceLog={this.props.updateServiceLog}
                    vehicles={this.props.vehicles}
                    serviceTypes={this.props.serviceTypes}
                    serviceCategories={this.props.serviceCategories}
                    me={this.props.me}
                    loading={this.props.loading}
                    reportEdit={true}
                    delLog={this.props.deleteServiceLog}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        serviceLog: state.ServiceLogs.log,
        vehicles: state.Vehicles.vehicles.data,
        serviceTypes: state.StaticTypes.serviceTypes,
        serviceCategories: state.StaticTypes.serviceCategories,
        me: state.Me.me,
        loading: state.ServiceLogs.loading,
    }
}

export default connect(mapStateToProps, { getServiceLog, updateServiceLog, getServiceTypes, getServiceCategories, getVehicles, deleteServiceLog })(SingleReport)
