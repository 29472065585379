import {
    GET_VEHICLE_MODELS,
    GET_VEHICLE_MODELS_SUCCESS,
    GET_VEHICLE_MODELS_ERROR,
} from './actionTypes';

const initialState = {
    loading: false,
    models: [],
    error: ''
}

const VehicleModels = (state = initialState, action) => {
    switch (action.type) {
        case GET_VEHICLE_MODELS:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_MODELS_SUCCESS:
            return {
                ...state,
                loading: false,
                models: action.payload.data
            }
        case GET_VEHICLE_MODELS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default VehicleModels