import React, { useState, useEffect } from 'react';
import { faTrash } from '@fortawesome/free-solid-svg-icons';
import { useDispatch } from 'react-redux';
import { useHistory } from 'react-router';
import InputField from '../../Common/InputField';
import ErrorAlert from '../../Common/ErrorAlert';
import CommonButton from '../../Common/CommonButton';
import Select from '../../Common/Select';
import TextAreaField from '../../Common/TextAreaField';
import CardForm from '../../Common/Card/CardForm';
import Modal from '../../Common/Modal';
import { addToDo, updateToDoTask, deleteToDoTask, getToDoTask } from '../../../store/actions';

import Loader from '../../Common/Loader';
import { getUrlId, formatDateForServer } from '../../../helpers/common';
import CommonDatePicker from '../../Common/CommonDatePicker';
import SelectSearch from '../../Common/SelectSearch';
import MultipleSelect from '../../Common/MultipleSelect';

const initialState = {
    assignee_id: '',
    title: '',
    text: '',
    vehicle_id: '',
    status_id: '',
    service_type_id: '',
    service_category_id: '',
    error: false,
    label: '',
    service_subcategories: []
}

const HandleTask = (props) => {
    const [values, setValues] = useState(initialState)
    const [vehiclesList, setVehiclesList] = useState([])
    const [date, setDate] = useState(formatDateForServer(new Date()))
    const [modal, setModal] = useState(false)
    const [serviceSubcategoriesList, setServiceSubcategoriesList] = useState([])
    const [id, setId] = useState('')
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const id = getUrlId()
        setId(id)
    }, [])

    useEffect(() => {
        setValues(props.task || initialState)
        if (props.task) {
            setValues({ ...props.task, service_subcategories: props.task.service_subcategories })
        }
    }, [props.task])

    useEffect(() => {
        setVehiclesList(props.vehicles?.map(item => {
            item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`.split(' ').filter((value, index, self) => {
                return self.indexOf(value) === index;
            }).join(' ')
            return item
        }))
    }, [props.vehicles])

    useEffect(() => {
        //load subcategories on edit report
        if (props.serviceCategories) {
            if (values.service_category_id) {
                const cat = props.serviceCategories.find(category => category.id === Number(values.service_category_id))
                setServiceSubcategoriesList(cat?.service_subcategories)
            }
        }
    }, [props.serviceCategories, values.service_category_id])

    const handleModal = () => setModal(!modal)

    const handleInput = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (name === 'service_category_id') {
            //get subcategories based on category id
            const cat = props.serviceCategories.find(category => category.id === Number(value))
            setServiceSubcategoriesList(cat?.service_subcategories || [])
            setValues({
                ...values,
                service_category_id: value,
                service_subcategories: []
            })
        }
    }

    const handleMultipleValues = (e) => {
        //select subcategory
        setValues({ ...values, service_subcategories: e })
    }

    const removeMultipleValues = (selectedList, removedItem) => {
        setValues({ ...values, service_subcategories: selectedList })
    }

    const handleDate = (date) => {
        const d = formatDateForServer(date)
        setDate(d)
    }

    const delTask = () => {
        dispatch(deleteToDoTask(id))
        history.push('/pregled-radnih-zadataka')
    }

    const handleSubmit = (e) => {
        const { assignee_id, vehicle_id, title, text, service_type_id, service_category_id, status_id } = values
        const subcategoriesIds = values.service_subcategories.map(sub => sub.id)
        e.preventDefault();
        if (assignee_id === '' || vehicle_id === '' || service_type_id === '' || service_category_id === '' || status_id === '' || title === '' || text === '') {
            setValues({
                ...values,
                error: true
            })
        } else {
            if (props.edit) {
                dispatch(updateToDoTask(
                    id,
                    props.me.business_id,
                    vehicle_id,
                    assignee_id,
                    service_type_id,
                    service_category_id,
                    status_id,
                    date,
                    title,
                    text,
                    subcategoriesIds
                ))
                setTimeout(() => dispatch(getToDoTask(id)), 100) //load subcategories on edit *quick fix*
            } else {
                dispatch(addToDo(
                    props.me.business_id,
                    vehicle_id,
                    assignee_id,
                    service_type_id,
                    service_category_id,
                    status_id,
                    date,
                    title,
                    text,
                    subcategoriesIds
                ))
                setValues({
                    error: false,
                    assignee_id: '',
                    title: '',
                    text: '',
                    vehicle_id: '',
                    service_type_id: '',
                    service_category_id: '',
                    status_id: ''
                })
                history.push('/pregled-radnih-zadataka')
                dispatch(props.getTodos(1, 10))
            }
        }
    }

    const handleFinishTask = (e) => {
        const { assignee_id, vehicle_id, title, text, service_type_id, service_category_id, subcategoriesIds } = values;
        const status_id = 20;
        e.preventDefault();
        dispatch(updateToDoTask(
            id,
            props.me.business_id,
            vehicle_id,
            assignee_id,
            service_type_id,
            service_category_id,
            status_id,
            date,
            title,
            text,
            subcategoriesIds
        ))
        history.push('/pregled-radnih-zadataka')
    }

    return (
        <CardForm
            className='add-form'
            title={props.titleCard}
            icon={props.icon}
            finishButton={props.finishButton}
            onClick={handleFinishTask}
        >
            {props.loading ?
                <Loader marginTop='50px' />
                :
                <React.Fragment>
                    <CommonDatePicker
                        handleDate={handleDate}
                        date={props.task?.date}
                        className='picker-width-20'
                        cardText='Datum'
                    />
                    <SelectSearch
                        label='Vozilo'
                        required={true}
                        options={vehiclesList}
                        value={vehiclesList?.filter(x => x.id === values.vehicle_id)}
                        onChange={(option) => {
                            setValues({ ...values, vehicle_id: option.id, label: option.label })
                        }}
                    />
                    <Select
                        label='Korisnik'
                        styleContainer={{ width: '100%' }}
                        name='assignee_id'
                        required={true}
                        list={props.users}
                        value={values.assignee_id || ''}
                        onChange={handleInput}
                    />
                    <Select
                        label='Tip servisa'
                        styleContainer={{ width: '100%' }}
                        name='service_type_id'
                        required={true}
                        list={props.serviceTypes}
                        value={values.service_type_id || ''}
                        onChange={handleInput}
                    />
                    <Select
                        label='Kategorija'
                        styleContainer={{ width: '100%' }}
                        name='service_category_id'
                        required={true}
                        list={props.serviceCategories}
                        value={values.service_category_id || ''}
                        onChange={handleInput}
                    />
                    {/* ovo su subcategories */}
                    <MultipleSelect
                        label='Potkategorije'
                        options={serviceSubcategoriesList}
                        selectedValues={values.service_subcategories}
                        onSelect={handleMultipleValues}
                        onRemove={removeMultipleValues}
                        disable={values.service_category_id === "" ? true : false}
                    />
                    {/* ----------------- */}
                    <Select
                        label='Status'
                        styleContainer={{ width: '100%' }}
                        name='status_id'
                        required={true}
                        list={props.todoStatuses}
                        value={values.status_id || ''}
                        onChange={handleInput}
                    />
                    <InputField
                        type='text'
                        label='Naslov'
                        required={true}
                        name='title'
                        value={values.title || ''}
                        onChange={handleInput}
                    />
                    <TextAreaField
                        label='Opis'
                        name='text'
                        required={true}
                        value={values.text || ''}
                        onChange={handleInput}
                    />
                    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                        <CommonButton
                            variant='danger'
                            label='Sačuvaj'
                            icon={props.icon}
                            style={props.edit ? { width: '20%' } : { width: '100%' }}
                            onClick={handleSubmit}
                            type='submit'
                        />
                        {props.edit &&
                            <CommonButton
                                variant='dark'
                                label='Obrisi'
                                icon={faTrash}
                                style={{ width: '20%' }}
                                onClick={handleModal}
                                type='submit'
                            />
                        }
                    </div>
                </React.Fragment>
            }
            {values.error &&
                <ErrorAlert
                    content='Polja označena zvezdicom su obavezna.'
                />
            }
            {modal && <Modal close={handleModal} deleteItem={delTask} />}
        </CardForm>
    )
}

export default React.memo(HandleTask)
