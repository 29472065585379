export const GET_VEHICLES = 'GET_VEHICLES';
export const GET_VEHICLES_SUCCESS = 'GET_VEHICLES_SUCCESS';
export const GET_VEHICLES_ERROR = 'GET_VEHICLES_ERROR';

export const GET_VEHICLE = 'GET_VEHICLE';
export const GET_VEHICLE_SUCCESS = 'GET_VEHICLE_SUCCESS';
export const GET_VEHICLE_ERROR = 'GET_VEHICLE_ERROR';

export const CREATE_VEHICLE = 'CREATE_VEHICLE';
export const CREATE_VEHICLE_SUCCESS = 'CREATE_VEHICLE_SUCCESS';
export const CREATE_VEHICLE_ERROR = 'CREATE_VEHICLE_ERROR';

export const UPDATE_VEHICLE = 'UPDATE_VEHICLE';
export const UPDATE_VEHICLE_SUCCESS = 'UPDATE_VEHICLE_SUCCESS';
export const UPDATE_VEHICLE_ERROR = 'UPDATE_VEHICLE_ERROR';

export const DELETE_VEHICLE = 'DELETE_VEHICLE';
export const DELETE_VEHICLE_SUCCESS = 'DELETE_VEHICLE_SUCCESS';
export const DELETE_VEHICLE_ERROR = 'DELETE_VEHICLE_ERROR';

export const GET_VEHICLE_CUSTOMERS = 'GET_VEHICLE_CUSTOMERS';
export const GET_VEHICLE_CUSTOMERS_SUCCESS = 'GET_VEHICLE_CUSTOMERS_SUCCESS';
export const GET_VEHICLE_CUSTOMERS_ERROR = 'GET_VEHICLE_CUSTOMERS_ERROR';

export const GET_VEHICLE_SERVICE_LOGS = 'GET_VEHICLE_SERVICE_LOGS';
export const GET_VEHICLE_SERVICE_LOGS_SUCCESS = 'GET_VEHICLE_SERVICE_LOGS_SUCCESS';
export const GET_VEHICLE_SERVICE_LOGS_ERROR = 'GET_VEHICLE_SERVICE_LOGS_ERROR';