import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getMe = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/auth/me`,
        {
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response.data;
            }
        })
        .catch(error => {
            return error;
        })
}

export const logout = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/auth/logout`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}