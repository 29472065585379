import {
    FORGOT_PASSWORD,
    FORGOT_PASSWORD_SUCCESS,
    FORGOT_PASSWORD_ERROR,
    RESET_PASSWORD,
    RESET_PASSWORD_SUCCESS,
    RESET_PASSWORD_ERROR
} from './actionTypes';

export const forgotPassword = (email) => {
    return {
        type: FORGOT_PASSWORD,
        payload: { email }
    }
}

export const forgotPasswordSuccess = (message) => {
    return {
        type: FORGOT_PASSWORD_SUCCESS,
        payload: message
    }
}

export const forgotPasswordError = (message) => {
    return {
        type: FORGOT_PASSWORD_ERROR,
        payload: message
    }
}

export const resetPassword = (token, email, password, password_confirmation) => {
    return {
        type: RESET_PASSWORD,
        payload: { token, email, password, password_confirmation }
    }
}

export const resetPasswordSuccess = (message) => {
    return {
        type: RESET_PASSWORD_SUCCESS,
        payload: message
    }
}

export const resetPasswordError = (message) => {
    return {
        type: RESET_PASSWORD_ERROR,
        payload: message
    }
}