import { combineReducers } from 'redux';

import Login from './auth/login/reducer';
import ForgotPassword from './auth/forgotPassword/reducer';
import Me from './me/reducer';
import Users from './users/reducer';
import Customers from './customers/reducer';
import Todo from './to_do/reducer';
import ServiceLogs from './service_log/reducer';
import Vehicles from './vehicles/reducer';
import VehicleManufacturers from './vehicles/vehicleManufacturers/reducer';
import VehicleModels from './vehicles/vehicleModels/reducer';
import StaticTypes from './static_values/reducer';
import Business from './business/reducer';
import Search from './search/reducer';



const rootReducer = combineReducers({
    Login,
    Me,
    Users,
    Customers,
    Todo,
    ServiceLogs,
    Vehicles,
    VehicleManufacturers,
    VehicleModels,
    StaticTypes,
    Business,
    ForgotPassword,
    Search
})

export default rootReducer