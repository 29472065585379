import React, { Component } from 'react';
import { connect } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import { faSearch } from '@fortawesome/free-solid-svg-icons';
import CommonButton from '../CommonButton';
import CommonDatePicker from '../CommonDatePicker';
import Select from '../Select';
import { formatDateForServer, orderByType } from '../../../helpers/common';
import { getServiceLogReports } from '../../../store/actions';

export class SearchByDate extends Component {
    constructor(props) {
        super(props);
        this.state = {
            startDate: '',
            endDate: '',
            orderByType: ''
        }
    }
    handleDate = (value, name) => {
        const date = formatDateForServer(value)
        this.setState({
            [name]: date
        })
    }
    handleSelect = (e) => {
        this.setState({
            orderByType: e.target.value
        })
    }
    submitForm = () => {
        const { startDate, endDate, orderByType } = this.state
        this.props.getServiceLogReports(1, 10, startDate, endDate, orderByType)
    }

    resetForm = () => {
        this.setState({
            startDate: '',
            endDate: '',
            orderByType: ''
        })
    }
    render() {
        return (
            <div className='search-by-date'>
                <Row>
                    <Col sm={12} md={3}>
                        <CommonDatePicker date={this.state.startDate} handleDate={(e) => this.handleDate(e, 'startDate')} className='picker-width-100' />
                    </Col>
                    <Col sm={12} md={3}>
                        <CommonDatePicker date={this.state.endDate} handleDate={(e) => this.handleDate(e, 'endDate')} className='picker-width-100' />
                    </Col>
                    <Col sm={12} md={2}>
                        <Select
                            list={orderByType}
                            name='orderBy'
                            value={this.state.orderByType}
                            onChange={this.handleSelect}
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <CommonButton
                            variant="danger"
                            label='Pretraži'
                            onClick={this.submitForm}
                            icon={faSearch}
                        />
                    </Col>
                    <Col sm={12} md={2}>
                        <CommonButton
                            variant="dark"
                            label='Poništi'
                            onClick={this.resetForm}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}



export default connect(null, { getServiceLogReports })(SearchByDate)
