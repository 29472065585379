import React from 'react';
import { connect } from 'react-redux';
import { Tabs, Tab, Col } from 'react-bootstrap';
import {
    getVehicle,
    // getVehicleManufacturers,
    getVehicleModels,
    // getFuelTypes,
    // getVehicleStatuses,
    // getVehicleTypes,
    updateVehicle,
    deleteVehicle,
    getVehicleCustomers,
    getVehicleServiceLogs,
    // getCustomerVehiclesData,
    deleteCustomerVehiclesData,
    createCustomerVehiclesData,
    updateCustomerVehiclesData,
    getCustomers,
    // getCustomerRoles
} from './../../store/actions';
import { getUrlId } from './../../helpers/common';
import VehicleForm from '../../components/Forms/VehicleForm';
import BasicInfo from './../../components/Common/BasicInfo';
import CustomerDataList from './../../components/Lists/CustomerDataList';
import { faStickyNote } from '@fortawesome/free-solid-svg-icons';

class Vehicle extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
        this.id = getUrlId()
    }

    componentDidMount() {
        this.props.getVehicle(this.id)
        // this.props.getVehicleManufacturers()
        this.props.getVehicleModels()
        this.props.getCustomers(1, 1000)
        // this.props.getFuelTypes()
        // this.props.getVehicleStatuses()
        // this.props.getVehicleTypes()
        this.props.getVehicleCustomers(this.id)
        this.props.getVehicleServiceLogs(this.id)
        // this.props.getCustomerVehiclesData()
        // this.props.getCustomerRoles()
    }

    componentDidUpdate(prevProps) {
        if (this.props.match.params.id !== prevProps.match.params.id) {
            this.props.getVehicle(this.props.match.params.id)
        }
    }

    render() {
        return (
            <div className='page'>
                <Tabs defaultActiveKey="info" id="uncontrolled-tab-example" onSelect={(k) => {
                    if (k === 'owner') {
                        this.props.getVehicleCustomers(this.id)
                    }
                }}>
                    <Tab eventKey="info" title="Osnovne informacije">
                        <VehicleForm
                            vehicle={this.props.vehicle}
                            loading={this.props.loading}
                            submitVehicle={this.props.updateVehicle}
                            manufacturers={this.props.manufacturers}
                            models={this.props.models}
                            fuelTypes={this.props.fuelTypes}
                            vehicleStatuses={this.props.vehicleStatuses}
                            vehicleTypes={this.props.vehicleTypes}
                            editVehicle={true}
                            deleteVehicle={this.props.deleteVehicle}
                            // getCustomerVehiclesData={this.props.getCustomerVehiclesData}
                            deleteCustomerVehicle={this.props.deleteCustomerVehiclesData}
                            createCustomerVehicles={this.props.createCustomerVehiclesData}
                            updateCustomerVehicles={this.props.updateCustomerVehiclesData}
                            getVehicle={this.props.getVehicle}
                            listOfCustomers={this.props.customers}
                            // customerVehiclesData={this.props.customerVehiclesData}
                            customerRoles={this.props.customerRoles}
                        />
                    </Tab>
                    <Tab eventKey="reports" title="Servisni zapisi">
                        <CustomerDataList
                            title='Spisak servisnih zapisa'
                            icon={faStickyNote}
                            reportsList={this.props.vehicleServiceLogs}
                            meta={this.props.vehicleServiceLogs.meta}
                            addReportBtn={true}
                            onClick={() => this.props.history.push({ pathname: '/dodavanje-zapisa', state: { vehicle_id: this.id } })}
                        />
                    </Tab>
                    <Tab eventKey="owner" title="Vlasnik">
                        <Col sm={12}>
                            <BasicInfo
                                className='basic-info-box'
                                vehicleCustomers={this.props.vehicleCustomers}
                                addCustomerBtn={true}
                                onClick={() => this.props.history.push({ pathname: '/dodavanje-klijenta', state: { vehicle_id: this.id } })}
                            />
                        </Col>
                    </Tab>
                </Tabs>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Vehicles.loading,
        vehicle: state.Vehicles.vehicle,
        manufacturers: state.VehicleManufacturers.manufacturers,
        models: state.VehicleModels.models,
        fuelTypes: state.StaticTypes.fuelTypes,
        vehicleStatuses: state.StaticTypes.vehicleStatuses,
        vehicleTypes: state.StaticTypes.vehicleTypes,
        vehicleCustomers: state.Vehicles.vehicleCustomers,
        vehicleServiceLogs: state.Vehicles.vehicleServiceLogs,
        customers: state.Customers.customers.data,
        // customerVehiclesData: state.Customers.customerVehiclesData.data,
        customerRoles: state.StaticTypes.customerRoles
    }
}

export default connect(mapStateToProps, {
    getVehicle,
    // getVehicleManufacturers,
    getVehicleModels,
    // getFuelTypes,
    // getVehicleStatuses,
    // getVehicleTypes,
    updateVehicle,
    deleteVehicle,
    getVehicleCustomers,
    getVehicleServiceLogs,
    // getCustomerVehiclesData,
    deleteCustomerVehiclesData,
    createCustomerVehiclesData,
    updateCustomerVehiclesData,
    getCustomers,
    // getCustomerRoles
})(Vehicle)
