import { takeEvery, call, put, fork, all } from 'redux-saga/effects';
import {
    GET_VEHICLE_MODELS
} from './actionTypes';
import {
    getVehicleModelsSuccess,
    getVehicleModelsError
} from './actions';
import {
    getVehicleModels as apiGetVehicleModels
} from './../../../helpers/vehicles_service/models_controller';

function* getVehicleModels({ payload }) {
    try {
        const response = yield call(apiGetVehicleModels, payload.id)
        if (response.code === 200 || response.status === 200) {
            yield put(getVehicleModelsSuccess(response.data))
        }
        yield put(getVehicleModelsError(response.message))
    } catch (error) {
        yield put(getVehicleModelsSuccess(error))
    }
}


function* watchGetVehicleModels() {
    yield takeEvery(GET_VEHICLE_MODELS, getVehicleModels)
}


function* vehicleModelsSaga() {
    yield all([
        fork(watchGetVehicleModels)
    ])
}

export default vehicleModelsSaga