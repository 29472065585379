import {
    GET_FUEL_TYPES,
    GET_FUEL_TYPES_SUCCESS,
    GET_FUEL_TYPES_ERROR,
    GET_SERVICE_TYPES,
    GET_SERVICE_TYPES_SUCCESS,
    GET_SERVICE_TYPES_ERROR,
    GET_SERVICE_CATEGORIES,
    GET_SERVICE_CATEGORIES_SUCCESS,
    GET_SERVICE_CATEGORIES_ERROR,
    GET_CUSTOMER_ROLES,
    GET_CUSTOMER_ROLES_SUCCESS,
    GET_CUSTOMER_ROLES_ERROR,
    GET_VEHICLE_TYPES,
    GET_VEHICLE_TYPES_SUCCESS,
    GET_VEHICLE_TYPES_ERROR,
    GET_VEHICLE_STATUSES,
    GET_VEHICLE_STATUSES_SUCCESS,
    GET_VEHICLE_STATUSES_ERROR,
    GET_TODO_STATUSES,
    GET_TODO_STATUSES_SUCCESS,
    GET_TODO_STATUSES_ERROR,
    GET_LOGS_STATUSES,
    GET_LOGS_STATUSES_SUCCESS,
    GET_LOGS_STATUSES_ERROR,
    GET_USER_STATUSES,
    GET_USER_STATUSES_SUCCESS,
    GET_USER_STATUSES_ERROR,
} from './actionTypes';

const initialState = {
    loading: false,
    fuelTypes: [],
    serviceTypes: [],
    serviceCategories: [],
    customerRoles: [],
    vehicleTypes: [],
    vehicleStatuses: [],
    todoStatuses: [],
    logs_statuses: [],
    userStatuses: [],
    error: ''
}

const StaticTypes = (state = initialState, action) => {
    switch (action.type) {
        case GET_FUEL_TYPES:
            return {
                ...state,
                loading: true
            }
        case GET_FUEL_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                fuelTypes: action.payload.data
            }
        case GET_FUEL_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_SERVICE_TYPES:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceTypes: action.payload.data
            }
        case GET_SERVICE_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_SERVICE_CATEGORIES:
            return {
                ...state,
                loading: true
            }
        case GET_SERVICE_CATEGORIES_SUCCESS:
            return {
                ...state,
                loading: false,
                serviceCategories: action.payload.data
            }
        case GET_SERVICE_CATEGORIES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_CUSTOMER_ROLES:
            return {
                ...state,
                loading: true
            }
        case GET_CUSTOMER_ROLES_SUCCESS:
            return {
                ...state,
                loading: false,
                customerRoles: action.payload.data
            }
        case GET_CUSTOMER_ROLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_VEHICLE_TYPES:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_TYPES_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicleTypes: action.payload.data
            }
        case GET_VEHICLE_TYPES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case GET_VEHICLE_STATUSES:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicleStatuses: action.payload.data
            }
        case GET_VEHICLE_STATUSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_TODO_STATUSES:
            return {
                ...state,
                loading: true
            }
        case GET_TODO_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                todoStatuses: action.payload.data
            }
        case GET_TODO_STATUSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_LOGS_STATUSES:
            return {
                ...state,
                loading: true
            }
        case GET_LOGS_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                logsStatuses: action.payload.data
            }
        case GET_LOGS_STATUSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_USER_STATUSES:
            return {
                ...state,
                loading: true
            }
        case GET_USER_STATUSES_SUCCESS:
            return {
                ...state,
                loading: false,
                userStatuses: action.payload.data
            }
        case GET_USER_STATUSES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default StaticTypes