import axios from 'axios';
const token = localStorage.getItem('authUser')

export const getFuelTypes = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/fuel_types`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getServiceTypes = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/service_types`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getServiceCategories = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_categories`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getCustomerRoles = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/customer_roles`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getVehicleTypes = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/vehicle_types`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getVehicleStatuses = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/vehicle_statuses`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getTodoStatuses = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/to_do_statuses`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getLogsStatuses = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/service_log_statuses`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getUserStatuses = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/static/user_statuses`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}