import React, { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import BasicInfo from '../../components/Common/BasicInfo';
import { Row, Col } from 'react-bootstrap';
import ProfileBanner from '../../components/Common/ProfileBanner';
import ChangeProfileImage from '../../components/Forms/ChangeProfileImage';
import Loader from './../../components/Common/Loader';

import { getBusiness } from './../../store/actions';


const ServiceProfile = () => {
    const state = useSelector(state => state);
    const id = state.Me.me?.business_id;
    const business = state.Business.business;
    const dispatch = useDispatch();

    useEffect(() => {
        if (id) dispatch(getBusiness(id))
    }, [id, dispatch])

    return (
        <div className='page'>
            {state.Business.loading ?
                <Loader marginTop="100px" />
                :
                <React.Fragment>
                    <ProfileBanner
                        src={business.logo_url}
                        serviceName={business.name}
                        businessId={id}
                        hasLogo={business.has_logo}
                        sp={true}
                    />
                    <Row>
                        <Col sm={6}>
                            <BasicInfo
                                address={business.address}
                                phone={business.phone}
                                city={business.city}
                                postal_code={business.postal_code}
                            />
                        </Col>
                        <Col sm={6}>
                            <ChangeProfileImage
                                title='Logo servisa'
                            />
                        </Col>
                    </Row>
                </React.Fragment>
            }
        </div>
    )
}

export default ServiceProfile
