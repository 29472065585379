import {
    GLOBAL_SEARCH,
    GLOBAL_SEARCH_SUCCESS,
    GLOBAL_SEARCH_ERROR
} from './actionTypes';

export const globalSearch = (query) => {
    return {
        type: GLOBAL_SEARCH,
        payload: { query }
    }
}

export const globalSearchSuccess = (results) => {
    return {
        type: GLOBAL_SEARCH_SUCCESS,
        payload: results
    }
}

export const globalSearchError = (message) => {
    return {
        type: GLOBAL_SEARCH_ERROR,
        payload: message
    }
}