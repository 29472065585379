import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getVehicleManufacturers = async (perPage = 1000, page = 1) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/vehicle_manufacturers?per_page=${perPage}&page=${page}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}