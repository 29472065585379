import React, { useState, useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux';
import { useHistory } from 'react-router-dom';
import {
    getMe,
    globalSearch,
    getFuelTypes,
    getCustomerRoles,
    getVehicleStatuses,
    getVehicleTypes,
    getVehicleManufacturers
} from './../../store/actions';
import Avatar from '../Common/Avatar';
import SearchByText from '../Common/Search/SearchByText';
import { checkUserType } from './../../helpers/common';

function Header() {
    const [value, setValue] = useState('')
    const [searchValues, setSearchValues] = useState({})
    const [dropdown, setDropdown] = useState(false)
    const me = useSelector(state => state.Me.me);
    const s = useSelector(state => state.Search);
    const history = useHistory();
    const dispatch = useDispatch();
    useEffect(() => {
        dispatch(getMe())
        dispatch(getFuelTypes())
        dispatch(getCustomerRoles())
        dispatch(getVehicleStatuses())
        dispatch(getVehicleTypes())
        dispatch(getVehicleManufacturers())
    }, [dispatch])

    useEffect(() => {
        setSearchValues(s)
        if (Object.keys(searchValues).length !== 0 && s.error === '' && (searchValues?.search?.vehicles?.length > 0 || searchValues?.search?.customers?.length > 0)) {
            setDropdown(true)
        } else {
            setDropdown(false)
        }
    }, [s, searchValues])


    const handleSearch = (e) => {
        setValue(e.target.value)
        dispatch(globalSearch(e.target.value))
    }

    const handleItemFromDropdown = (id, type) => {
        if (type === 'vehicle') {
            history.push(`/pregled-garaže/${id}`);
        } else {
            history.push(`/pregled-klijenata/${id}`);
        }
        setValue('')
        setDropdown(false)
    }

    const handleOnBlur = (e) => {
        if (e.relatedTarget === null) {
            setValue('')
            setDropdown(false)
        }
    }

    const clearInput = (e) => {
        setValue("")
        setDropdown(false)
    }

    return (
        <div className='header'>
            <div className='header-search-container' tabIndex="0" onBlur={handleOnBlur}>
                {checkUserType.admin(me?.role_id) &&
                    <SearchByText
                        className='header-search'
                        handleSearch={handleSearch}
                        value={value}
                        clearInput={clearInput}
                    />
                }
                {dropdown &&
                    <div className='header-dropdown' >
                        <div className='header-dropdown-items'>
                            <h3>Vozila</h3>
                            {searchValues?.search?.vehicles?.length > 0 &&
                                searchValues.search.vehicles.map(item => (
                                    <span onClick={() => handleItemFromDropdown(item.id, 'vehicle')} key={item.id}>
                                        {item.production_year} {item.vehicle_manufacturer.name} {item.vehicle_model.name} {item.label} {item.vehicle_plate}
                                    </span>
                                ))
                            }
                        </div>
                        <div className='header-dropdown-items'>
                            <h3>Klijenti</h3>
                            {searchValues?.search?.customers?.length > 0 &&
                                searchValues.search.customers.map(item => (
                                    <span onClick={() => handleItemFromDropdown(item.id)} key={item.id}>
                                        {item.first_name}  {item.last_name}
                                    </span>
                                ))
                            }
                        </div>
                    </div>
                }

            </div>
            <div className='user-account'>
                <div>
                    <span>{me?.first_name}</span> <span>{me?.last_name}</span>
                    <br />
                    <span>{me?.email}</span>
                </div>
                <Avatar
                    width='60px'
                    marginLeft='10px'
                    src={undefined}
                />
            </div>
        </div>
    )
}

export default Header
