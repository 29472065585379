import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import CardForm from '../Common/Card/CardForm';
import CardTable from '../Common/Card/CardTable';
import { tableHeadList } from '../../helpers/common';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';

import { formatDate } from '../../helpers/common';
import { getServiceLogs } from '../../store/actions';

const CustomerDataList = (props) => {
    const [current, setCurrent] = useState(1);
    const [meta, setMeta] = useState({})
    const perPage = 10;
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        setMeta(props.meta)
    }, [props.meta])

    useEffect(() => {
        const page = meta && meta.current_page;
        setCurrent(page)
    }, [meta])


    const handleClick = (e) => {
        const page = parseInt(e.target.id)
        setCurrent(page)
        dispatch(getServiceLogs(page, perPage))
    }

    const clickableRow = (id) => {
        history.push(`/istorija-zapisa/${id}`)
    }

    return (
        <CardForm
            title={props.title}
            icon={faListAlt}
            addReportBtn={props.addReportBtn}
            onClick={props.onClick}
        >
            <div className='list-of-reports'>
                {props.loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        {props.reportsList &&
                            props.reportsList.length > 0 &&
                            <CardTable
                                headListItems={tableHeadList.reports}
                            >

                                {props.reportsList.map((report, index) => {
                                    const vehicle = [report.vehicle];
                                    return (
                                        <tr key={`${report.id}_${index}`} className='list-grid' onClick={() => clickableRow(report.id)}>
                                            <td>{formatDate(report.date)}</td>
                                            {vehicle.map((item, index) => (
                                                <Fragment key={`${item.id}_${index}`}>
                                                    <td >{item.production_year} {item.vehicle_manufacturer.name} {item.vehicle_model.name} <br /> {item.label}</td>
                                                    <td>{item.vehicle_plate}</td>
                                                </Fragment>

                                            ))}
                                            <td>{report.price_total}</td>
                                            <td>{report.title}</td>
                                            <td>{report.text}</td>
                                        </tr>
                                    )
                                })}
                            </CardTable>
                        }
                        {meta && meta.total > 10 &&
                            <Pagination
                                data={meta && parseInt(meta.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }
            </div>
        </CardForm>
    )
}

export default CustomerDataList
