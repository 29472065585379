import { takeEvery, call, put, all, fork } from 'redux-saga/effects';

import {
    FORGOT_PASSWORD,
    RESET_PASSWORD
} from './actionTypes';

import {
    forgotPasswordSuccess,
    forgotPasswordError,
    resetPasswordSuccess,
    resetPasswordError
} from './actions';

import {
    forgotPassword as apiForgotPassword,
    resetPassword as apiResetPassword
} from './../../../helpers/user_service/auth_controller';

function* forgotPassword({ payload }) {
    try {
        const response = yield call(apiForgotPassword, payload.email);
        if (response.status === 200) {
            yield put(forgotPasswordSuccess('Link za reset lozinke smo poslali na vasu email adresu!'))
        }
        // yield put(forgotPasswordError('Ne postoji korisnik sa tom email adresom.'))
    } catch (error) {
        yield put(forgotPasswordError('Ne postoji korisnik sa tom email adresom.'))
    }
}

function* resetPassword({ payload: { token, email, password, password_confirmation } }) {
    try {
        const response = yield call(apiResetPassword, token, email, password, password_confirmation);

        if (response.status === 200) {
            yield put(resetPasswordSuccess(response.data.message))
            setTimeout(() => {
                window.location.pathname = '/'
            }, 3000)
        } else {
            yield put(resetPasswordError(response.data.message))
        }

    } catch (error) {
        yield put(resetPasswordError(error))
    }
}

function* watchForgotPassword() {
    yield takeEvery(FORGOT_PASSWORD, forgotPassword)
}

function* watchResetPassword() {
    yield takeEvery(RESET_PASSWORD, resetPassword)
}

function* forgotPasswordSaga() {
    yield all([
        fork(watchResetPassword),
        fork(watchForgotPassword)
    ])
}

export default forgotPasswordSaga