import React, { useState, useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import CommonButton from '../CommonButton';
import CommonDatePicker from '../CommonDatePicker';
import Select from '../Select';
import SelectSearch from '../SelectSearch';
import { getServiceLogs, getVehicleModels, addServiceLogReport, getServiceLogReports } from './../../../store/actions';
import { formatDateForServer, generateArrayOfYears } from '../../../helpers/common';
import SaveModal from '../SaveModal';
import { useHistory } from 'react-router-dom';

const initialValues = {
    date_from: '',
    date_to: '',
    service_type_id: '',
    service_category_id: '',
    production_year_from: '',
    production_year_to: '',
    vehicle_manufacturer_id: '',
    vehicle_model_id: '',
    customer_id: '',
    vehicle_id: '',
    label: '',
    customer_label: '',
    service_subcategory_id: ''
}

const ReportSearch = (props) => {
    const [values, setValues] = useState(initialValues)
    const [modal, setModal] = useState(false)
    const [reportTitle, setReportTitle] = useState('')
    const [customersList, setCustomersList] = useState([])
    const [vehiclesList, setVehiclesList] = useState([])
    const [serviceSubcategoriesList, setServiceSubcategoriesList] = useState([])
    const dispatch = useDispatch()
    const models = useSelector(state => state.VehicleModels.models)

    const history = useHistory()

    useEffect(() => {
        dispatch(getVehicleModels(values.vehicle_manufacturer_id))
    }, [dispatch, values.vehicle_manufacturer_id])

    useEffect(() => {
        setCustomersList(props.customers?.map(item => ({ label: `${item.first_name} ${item.last_name}`, value: item.first_name, ...item })))
        setVehiclesList(props.vehicles?.map(item => {
            item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`
            return item
        }))
    }, [props.customers, props.vehicles])

    const handleInput = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
        if (name === 'service_category_id') {
            //get subcategories based on category id
            if (value === "") {
                setServiceSubcategoriesList([])
            } else {
                const cat = props.serviceCategories.find(category => category.id === Number(value))
                setServiceSubcategoriesList(cat.service_subcategories)
            }
        }
    }

    const years = generateArrayOfYears()

    const handleDate = (e, name) => {
        const date = formatDateForServer(e);
        setValues({
            ...values,
            [name]: date
        })
    }

    //modal

    const handleModal = () => {
        setModal(!modal)
    }

    const handleModalInput = (e) => {
        setReportTitle(e.target.value)
    }

    const handleSaveReport = () => {
        const { date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id } = values;

        let dateFrom = date_from && `ranges[date][from]=${date_from}`
        let dateTo = date_to && `&ranges[date][to]=${date_to}`
        let serviceTypeId = service_type_id && `&filters[service_type_id][]=${service_type_id}`
        let setviceCategoryId = service_category_id && `&filters[service_category_id][]=${service_category_id}`
        let productionYearFrom = production_year_from && `&ranges[production_year][from]=${production_year_from}`
        let productionYearTo = production_year_to && `&ranges[production_year][to]=${production_year_to}`
        let vehicleManufacturerId = vehicle_manufacturer_id && `&filters[vehicle_manufacturer_id][]=${vehicle_manufacturer_id}`
        let vehicleModelId = vehicle_model_id && `&filters[vehicle_model_id][]=${vehicle_model_id}`
        let customerId = customer_id && `&filters[customer_id][]=${customer_id}`
        let vehicleId = vehicle_id && `&filters[vehicle_id][]=${vehicle_id}`
        let serviceSubcategoryId = service_subcategory_id && `&filters[service_subcategory_id][]=${service_subcategory_id}`

        let filtersStr = dateFrom + dateTo + serviceTypeId + setviceCategoryId + productionYearFrom + productionYearTo + vehicleManufacturerId + vehicleModelId + customerId + vehicleId + serviceSubcategoryId

        dispatch(addServiceLogReport(reportTitle, filtersStr ? filtersStr : '*'))
        setModal(false)
        history.push('/pregled-izveštaja')
        dispatch(getServiceLogReports(1, 10))
    }

    const submitForm = () => {
        const { date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id } = values;
        dispatch(getServiceLogs(props.current, props.perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id))
    }

    const resetForm = () => {
        setValues({
            date_from: '',
            date_to: '',
            service_type_id: '',
            service_category_id: '',
            production_year_from: '',
            production_year_to: '',
            vehicle_manufacturer_id: '',
            vehicle_model_id: '',
            customer_id: '',
            vehicle_id: '',
            service_subcategory_id: ''
        })
        setServiceSubcategoriesList([])
    }

    return (
        <div className='search-container'>
            <Row>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Datum od'
                        handleDate={(e) => handleDate(e, 'date_from')}
                        date={values.date_from}
                        style={{ marginBottom: '8px' }}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Datum do'
                        handleDate={(e) => handleDate(e, 'date_to')}
                        date={values.date_to}
                        style={{ marginBottom: '8px' }}
                    />
                </Col>
                <Col sm={12} md={6}>
                    <SelectSearch
                        label='Vozilo'
                        options={vehiclesList}
                        value={vehiclesList?.filter(x => x.id === values.vehicle_id)}
                        onChange={(option) => {
                            setValues({ ...values, vehicle_id: option.id, label: option.label })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3}>
                    <Select
                        label='Godina proizvodnje od'
                        list={years}
                        name='production_year_from'
                        value={values.production_year_from}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Godina proizvodnje do'
                        list={years}
                        name='production_year_to'
                        value={values.production_year_to}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Proizvodjac'
                        list={props.manufacturers}
                        name='vehicle_manufacturer_id'
                        value={values.vehicle_manufacturer_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Model'
                        list={models}
                        name='vehicle_model_id'
                        value={values.vehicle_model_id}
                        onChange={handleInput}
                        disabled={values.vehicle_manufacturer_id === '' ? true : false}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3}>
                    <Select
                        label='Tip servisa'
                        list={props.serviceTypes}
                        name='service_type_id'
                        value={values.service_type_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Kategorija'
                        list={props.serviceCategories}
                        name='service_category_id'
                        value={values.service_category_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Potkategorija'
                        list={serviceSubcategoriesList}
                        name='service_subcategory_id'
                        value={values.service_subcategory_id}
                        onChange={handleInput}
                        disabled={values.service_category_id === '' ? true : false}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <SelectSearch
                        label='Klijent'
                        options={customersList}
                        value={customersList?.filter(x => x.id === values.customer_id)}
                        onChange={(option) => {
                            setValues({ ...values, customer_id: option.id, customer_label: option.label })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={4}>
                    <CommonButton
                        variant="danger"
                        label='Pretraži'
                        onClick={submitForm}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <CommonButton
                        variant="success"
                        label='Sacuvaj'
                        onClick={handleModal}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}
                    />
                </Col>
                <Col sm={12} md={4}>
                    <CommonButton
                        variant="dark"
                        label='Poništi'
                        onClick={resetForm}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}
                    />
                </Col>
            </Row>
            {modal && <SaveModal handleModalInput={handleModalInput} saveItem={handleSaveReport} close={() => setModal(false)} />}
        </div>
    )
}

export default ReportSearch
