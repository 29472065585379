import React from 'react';
import { Button } from 'react-bootstrap';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import loadingIcon from './../../assets/images/loading.svg';

const CommonButton = ({ label, type, variant, onClick, icon, style, name, cls, loading }) => {
    return (
        <Button
            style={style}
            variant={variant}
            type={type}
            name={name}
            onClick={onClick}
            className={`common-button ${cls}`}
        >
            {icon && <FontAwesomeIcon icon={icon} />} {label} {loading && <img src={loadingIcon} alt='loading' width="22px" height="22px" />}
        </Button>
    )
}

export default CommonButton
