export const GET_SERVICE_LOGS = 'GET_SERVICE_LOGS';
export const GET_SERVICE_LOGS_SUCCESS = 'GET_SERVICE_LOGS_SUCCESS';
export const GET_SERVICE_LOGS_ERROR = 'GET_SERVICE_LOGS_ERROR';

export const CREATE_SERVICE_LOG = 'CREATE_SERVICE_LOG';
export const CREATE_SERVICE_LOG_SUCCESS = 'CREATE_SERVICE_LOG_SUCCESS';
export const CREATE_SERVICE_LOG_ERROR = 'CREATE_SERVICE_LOG_ERROR';

export const GET_SERVICE_LOG = 'GET_SERVICE_LOG';
export const GET_SERVICE_LOG_SUCCESS = 'GET_SERVICE_LOG_SUCCESS';
export const GET_SERVICE_LOG_ERROR = 'GET_SERVICE_LOG_ERROR';

export const UPDATE_SERVICE_LOG = 'UPDATE_SERVICE_LOG';
export const UPDATE_SERVICE_LOG_SUCCESS = 'UPDATE_SERVICE_LOG_SUCCESS';
export const UPDATE_SERVICE_LOG_ERROR = 'UPDATE_SERVICE_LOG_ERROR';

export const DELETE_SERVICE_LOG = 'DELETE_SERVICE_LOG';
export const DELETE_SERVICE_LOG_SUCCESS = 'DELETE_SERVICE_LOG_SUCCESS';
export const DELETE_SERVICE_LOG_ERROR = 'DELETE_SERVICE_LOG_ERROR';


//MEDIA

export const GET_SERVICE_LOG_MEDIA = 'GET_SERVICE_LOG_MEDIA';
export const GET_SERVICE_LOG_MEDIA_SUCCESS = 'GET_SERVICE_LOG_MEDIA_SUCCESS';
export const GET_SERVICE_LOG_MEDIA_ERROR = 'GET_SERVICE_LOG_MEDIA_ERROR';

export const ADD_SERVICE_LOG_MEDIA = 'ADD_SERVICE_LOG_MEDIA';
export const ADD_SERVICE_LOG_MEDIA_SUCCESS = 'ADD_SERVICE_LOG_MEDIA_SUCCESS';
export const ADD_SERVICE_LOG_MEDIA_ERROR = 'ADD_SERVICE_LOG_MEDIA_ERROR';

export const DELETE_SERVICE_LOG_MEDIA = 'DELETE_SERVICE_LOG_MEDIA';
export const DELETE_SERVICE_LOG_MEDIA_SUCCESS = 'DELETE_SERVICE_LOG_MEDIA_SUCCESS';
export const DELETE_SERVICE_LOG_MEDIA_ERROR = 'DELETE_SERVICE_LOG_MEDIA_ERROR';

//SERVICE LOG REPORTS

export const GET_SERVICE_LOG_REPORTS = 'GET_SERVICE_LOG_REPORTS';
export const GET_SERVICE_LOG_REPORTS_SUCCESS = 'GET_SERVICE_LOG_REPORTS_SUCCESS';
export const GET_SERVICE_LOG_REPORTS_ERROR = 'GET_SERVICE_LOG_REPORTS_ERROR';

export const ADD_SERVICE_LOG_REPORT = 'ADD_SERVICE_LOG_REPORT';
export const ADD_SERVICE_LOG_REPORT_SUCCESS = 'ADD_SERVICE_LOG_REPORT_SUCCESS';
export const ADD_SERVICE_LOG_REPORT_ERROR = 'ADD_SERVICE_LOG_REPORT_ERROR';

export const SHOW_SERVICE_LOG_REPORT = 'SHOW_SERVICE_LOG_REPORT';
export const SHOW_SERVICE_LOG_REPORT_SUCCESS = 'SHOW_SERVICE_LOG_REPORT_SUCCESS';
export const SHOW_SERVICE_LOG_REPORT_ERROR = 'SHOW_SERVICE_LOG_REPORT_ERROR';

export const DELETE_SERVICE_LOG_REPORT = 'DELETE_SERVICE_LOG_REPORT';
export const DELETE_SERVICE_LOG_REPORT_SUCCESS = 'DELETE_SERVICE_LOG_REPORT_SUCCESS';
export const DELETE_SERVICE_LOG_REPORT_ERROR = 'DELETE_SERVICE_LOG_REPORT_ERROR';

export const CLEAR_SERVICE_LOG_REPORTS = 'CLEAR_SERVICE_LOG_REPORTS';