import React, { Component } from 'react';
import { Container, Form } from 'react-bootstrap';
import logo from './../../../assets/images/logo.png';

class LoginCard extends Component {
    render() {
        return (
            <Container fluid className='login'>
                <Form>
                    <img src={logo} width="100" height="50" alt='logo' />
                    {this.props.children}
                </Form>
            </Container>
        )
    }
}

export default LoginCard
