import React from 'react';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimesCircle } from '@fortawesome/free-solid-svg-icons';
import InputField from '../InputField';


const SearchByText = (props) => {
    return (
        <div className='search-by-text' style={props.style}>
            <InputField
                type='text'
                value={props.value}
                name='search'
                onChange={props.handleSearch}
                placeholder='Pretraga'
                onBlur={props.onBlur}
            />
            {props.value.length > 0 &&
                <FontAwesomeIcon icon={faTimesCircle} onClick={props.clearInput} color='#272c33' />
            }
        </div>
    )

}

export default SearchByText
