import {
    GET_ME,
    GET_ME_SUCCESS,
    GET_ME_ERROR,
    LOGOUT,
    LOGOUT_SUCCESS,
    LOGOUT_ERROR
} from './actionTypes';

let initialState = {
    loading: false,
    me: null,
    error: ''
}

const Me = (state = initialState, action) => {
    switch (action.type) {
        case GET_ME:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case GET_ME_SUCCESS:
            return {
                ...state,
                loading: false,
                me: action.payload
            }
        case GET_ME_ERROR:
            return {
                ...state,
                loading: false,
                error: action.error
            }
        case LOGOUT:
            return { ...state }
        case LOGOUT_SUCCESS:
            return { ...state }
        case LOGOUT_ERROR:
            return { ...state }
        default:
            return state
    }
}

export default Me