import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Row, Col } from 'react-bootstrap';
import CommonButton from '../CommonButton';
import CommonDatePicker from '../CommonDatePicker';
import SelectSearch from '../SelectSearch';
import Select from '../Select';
import { getToDo } from './../../../store/actions';
import { formatDateForServer } from '../../../helpers/common';

const initialValues = {
    created_at_from: '',
    created_at_to: '',
    date_from: '',
    date_to: '',
    assigner_id: '',
    assignee_id: '',
    vehicle_id: '',
    customer_id: '',
    status_id: '',
    vehicle_label: '',
    customer_label: ''
}

const MultileParametersSearch = (props) => {
    const [values, setValues] = useState(initialValues)
    const [vehiclesList, setVehiclesList] = useState([])
    const [customersList, setCustomersList] = useState([])
    const dispatch = useDispatch()

    useEffect(() => {
        setCustomersList(props.customers?.map(item => ({ label: `${item.first_name} ${item.last_name}`, value: item.first_name, ...item })))
        setVehiclesList(props.vehicles?.map(item => {
            item.label = `${item.production_year ? item.production_year : ''} ${item.vehicle_manufacturer.name} ${item.vehicle_model.name} ${item.label} ${item.vehicle_plate ? item.vehicle_plate : ''}`
            return item
        }))
    }, [props.customers, props.vehicles])

    const handleInput = (e) => {
        const { name, value } = e.target
        setValues({
            ...values,
            [name]: value
        })
    }

    const handleDate = (e, name) => {
        const date = formatDateForServer(e);
        setValues({
            ...values,
            [name]: date
        })
    }

    const submitForm = (e) => {
        const { created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id } = values;
        dispatch(getToDo(props.current, props.perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id))
    }

    const resetForm = () => {
        setValues({
            created_at_from: '',
            created_at_to: '',
            date_from: '',
            date_to: '',
            assigner_id: '',
            assignee_id: '',
            vehicle_id: '',
            customer_id: '',
            status_id: ''
        })
    }

    return (
        <div className='search-container'>
            <Row>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Od datuma kreiranja'
                        handleDate={(e) => handleDate(e, 'created_at_from')}
                        date={values.created_at_from}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Do datuma kreiranja'
                        handleDate={(e) => handleDate(e, 'created_at_to')}
                        date={values.created_at_to}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Datum od'
                        handleDate={(e) => handleDate(e, 'date_from')}
                        date={values.date_from}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <CommonDatePicker
                        cardText='Datum do'
                        handleDate={(e) => handleDate(e, 'date_to')}
                        date={values.date_to}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3}>
                    <Select
                        label='Kreator'
                        list={props.users}
                        name='assigner_id'
                        value={values.assigner_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <Select
                        label='Korisnik'
                        list={props.users}
                        name='assignee_id'
                        value={values.assignee_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <SelectSearch
                        label='Vozilo'
                        options={vehiclesList}
                        value={vehiclesList?.filter(x => x.id === values.vehicle_id)}
                        onChange={(option) => {
                            setValues({ ...values, vehicle_id: option.id, vehicle_label: option.label })
                        }}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <SelectSearch
                        label='Klijent'
                        options={customersList}
                        value={customersList?.filter(x => x.id === values.customer_id)}
                        onChange={(option) => {
                            setValues({ ...values, customer_id: option.id, customer_label: option.label })
                        }}
                    />
                </Col>
            </Row>
            <Row>
                <Col sm={12} md={3}>
                    <Select
                        label='Status'
                        list={props.todoStatuses}
                        name='status_id'
                        value={values.status_id}
                        onChange={handleInput}
                    />
                </Col>
                <Col sm={12} md={3}></Col>
                <Col sm={12} md={3}>
                    <CommonButton
                        variant="danger"
                        label='Pretraži'
                        onClick={submitForm}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}
                    />
                </Col>
                <Col sm={12} md={3}>
                    <CommonButton
                        variant="dark"
                        label='Poništi'
                        onClick={resetForm}
                        style={{
                            display: 'flex',
                            alignItems: 'center',
                            marginTop: '30px',
                            justifyContent: 'center'
                        }}
                    />
                </Col>
            </Row>
        </div>
    )
}

export default MultileParametersSearch
