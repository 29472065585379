import React from 'react';
import { faInfo } from '@fortawesome/free-solid-svg-icons';
import CardForm from './Card/CardForm';

const BasicInfo = ({ className, onClick, addCustomerBtn, vehicleCustomers, ...rest }) => {
    return (
        <CardForm
            title={"Informacije o vlasnicima"}
            icon={faInfo}
            addCustomerBtn={addCustomerBtn}
            onClick={onClick}
        >
            {vehicleCustomers ?
                vehicleCustomers.map(customer => (
                    <div className={className} key={customer.id}>
                        {customer.first_name && <p>Ime: {customer.first_name}</p>}
                        {customer.last_name && <p>Prezime: {customer.last_name}</p>}
                        {customer.email && <p>Email: {customer.email}</p>}
                        <p>Adresa: {customer.address}</p>
                        <p>Telefon: {customer.phone}</p>
                        <p>Grad: {customer.city}</p>
                        <p>Postanski broj: {customer.postal_code}</p>
                    </div>
                ))
                :
                <div className={className}>
                    <p>Adresa: {rest.address}</p>
                    <p>Telefon: {rest.phone}</p>
                    <p>Grad: {rest.city}</p>
                    <p>Postanski broj: {rest.postal_code}</p>
                </div>
            }

        </CardForm>
    )
}

export default BasicInfo
