import React, { Fragment, useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { faListAlt } from '@fortawesome/free-solid-svg-icons';
import { useHistory } from 'react-router-dom'
import SearchByText from '../Common/Search/SearchByText';
import CardForm from '../Common/Card/CardForm';
import CardTable from '../Common/Card/CardTable';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import { getUsers } from '../../store/actions';


const UsersList = ({ title, headerList, usersList, path, loading, customer, meta }) => {
    const [query, setQuery] = useState('')
    const [filteredList, setFilteredList] = useState([])
    const [current, setCurrent] = useState(1);
    const [metaTags, setMetaTags] = useState({});
    const perPage = 10;
    const dispatch = useDispatch()
    const history = useHistory()

    useEffect(() => {
        const page = meta && meta.current_page;
        setMetaTags(meta)
        setCurrent(page)
    }, [meta])

    useEffect(() => {
        setFilteredList(usersList)
    }, [usersList])

    const handleClick = (e) => {
        const page = parseInt(e.target.id)
        setCurrent(page)
        dispatch(getUsers(page, perPage))
    }

    const clickableRow = (path, id) => {
        history.push(`/${path}/${id}`)
    }

    const handleSearch = (e) => {
        const query = e.target.value
        setQuery(query)
        const filtered = usersList.filter(item =>
            item.first_name.toLowerCase().includes(query.toLowerCase()) ||
            item.last_name.toLowerCase().includes(query.toLowerCase()))
        setFilteredList(filtered)
    }

    const clearInput = () => {
        setQuery("")
        setFilteredList(usersList)
    }

    return (
        <CardForm
            title={title}
            icon={faListAlt}
        >
            <div className='list-of-users'>
                <SearchByText handleSearch={handleSearch} value={query} clearInput={clearInput} />
                {loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        <CardTable
                            headListItems={headerList}
                        >
                            {
                                filteredList?.map((user) => (
                                    <tr key={user.id} onClick={() => clickableRow(path, user.id)} >
                                        <td>{user.first_name}</td>
                                        <td>{user.last_name}</td>
                                        <td>{user.email}</td>
                                        <td>{user.phone}</td>
                                    </tr>
                                )
                                )}
                        </CardTable>
                        {metaTags && parseInt(metaTags.total) > 10 &&
                            <Pagination
                                data={metaTags && parseInt(metaTags.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }

            </div>
        </CardForm >
    )

}

export default UsersList
