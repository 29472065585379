import React from "react";
import Routes from "./routes/routes";
import 'react-datepicker/dist/react-datepicker.css';

function App() {
  return (
    <Routes />
  );
}

export default App;
