import { takeEvery, all, call, put, fork } from 'redux-saga/effects';
import {
    GET_SERVICE_LOGS,
    CREATE_SERVICE_LOG,
    GET_SERVICE_LOG,
    UPDATE_SERVICE_LOG,
    DELETE_SERVICE_LOG,
    GET_SERVICE_LOG_MEDIA,
    ADD_SERVICE_LOG_MEDIA,
    DELETE_SERVICE_LOG_MEDIA,
    GET_SERVICE_LOG_REPORTS,
    ADD_SERVICE_LOG_REPORT,
    DELETE_SERVICE_LOG_REPORT,
    SHOW_SERVICE_LOG_REPORT
} from './actionTypes';
import {
    getServiceLogsSuccess,
    getServiceLogsError,
    createServiceLogSuccess,
    createServiceLogError,
    getServiceLogSuccess,
    getServiceLogError,
    updateServiceLogSuccess,
    updateServiceLogError,
    deleteServiceLogSuccess,
    deleteServiceLogError,
    getServiceLogMediaSuccess,
    getServiceLogMediaError,
    deleteServiceLogMediaSuccess,
    deleteServiceLogMediaError,
    addServiceLogMediaSuccess,
    addServiceLogMediaError,
    addServiceLogReportSuccess,
    addServiceLogReportError,
    getServiceLogReportsSuccess,
    getServiceLogReportsError,
    deleteServiceLogReportSuccess,
    deleteServiceLogReportError,
    showServiceLogReportSuccess,
    showServiceLogReportError
} from './actions';
import {
    getServiceLogs as apiGetServiceLogs,
    createServiceLog as apiCreateServiceLog,
    getServiceLog as apiGetServiceLog,
    updateServiceLog as apiUpdateServiceLog,
    deleteServiceLog as apiDeleteServiceLog,
    getServiceLogMedia as apiGetServiceLogMedia,
    addServiceLogMedia as apiAddServiceLogMedia,
    deleteServiceLogMedia as apiDeleteServiceLogMedia,
    getServiceLogReports as apiGetServiceLogReports,
    addServiceLogReport as apiAddServiceLogReport,
    showServiceLogReport as apiShowServiceLogReport,
    deleteServiceLogReport as apiDeleteServiceLogReport
} from './../../helpers/log_service/service_log_controller';

function* getServiceLogs({ payload: { page, perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id } }) {
    try {
        const response = yield call(apiGetServiceLogs, page, perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id);
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceLogsSuccess(response.data))
        }
        yield put(getServiceLogsError(response.error))
    } catch (error) {
        yield put(getServiceLogsError(error))
    }
}

function* createServiceLog({ payload: { vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories } }) {
    try {
        const response = yield call(apiCreateServiceLog, vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories);
        if (response.status === 200 || response.code === 200 || response.status === 201 || response.code === 201) {
            yield put(createServiceLogSuccess(response.data))
            yield getServiceLogs({ payload: { page: 1, perPage: 10 } })
        }
        yield put(createServiceLogError(response.error))
    } catch (error) {
        yield put(createServiceLogError(error))
    }
}

function* getServiceLog({ payload }) {
    try {
        const response = yield call(apiGetServiceLog, payload.id);
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceLogSuccess(response.data))
        }
        yield put(getServiceLogError(response.error))
    } catch (error) {
        yield put(getServiceLogError(error))
    }
}

function* updateServiceLog({ payload: { id, vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories } }) {
    try {
        const response = yield call(apiUpdateServiceLog, id, vehicle_id, user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories);
        if (response.status === 200 || response.code === 200) {
            yield put(updateServiceLogSuccess(response.data))
        }
        yield put(updateServiceLogError(response.error))
    } catch (error) {
        yield put(updateServiceLogError(error))
    }
}

function* deleteServiceLog({ payload }) {
    try {
        const response = yield call(apiDeleteServiceLog, payload.id);
        if (response.status === 200 || response.code === 200) {
            yield put(deleteServiceLogSuccess(response.data))
            yield getServiceLogs({ payload: { page: 1, perPage: 10 } })
        }
        yield put(deleteServiceLogError(response.error))
    } catch (error) {
        yield put(deleteServiceLogError(error))
    }
}

//media

function* getServiceLogMedia({ payload }) {
    try {
        const response = yield call(apiGetServiceLogMedia, payload.id)
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceLogMediaSuccess(response.data))
        }
        yield put(getServiceLogMediaError(response.error))
    } catch (error) {
        yield put(getServiceLogMediaError(error))
    }
}

function* addServiceLogMedia({ payload }) {
    try {
        const response = yield call(apiAddServiceLogMedia, payload.id, payload.attachments)
        if (response.status === 200 || response.code === 200) {
            yield put(addServiceLogMediaSuccess(response.data))
        }
        yield put(addServiceLogMediaError(response.error))
    } catch (error) {
        yield put(addServiceLogMediaError(error))
    }
}

function* deleteServiceLogMedia({ payload }) {
    try {
        const response = yield call(apiDeleteServiceLogMedia, payload.id, payload.uuid)
        if (response.status === 200 || response.code === 200) {
            yield put(deleteServiceLogMediaSuccess(response.data))
        }
        yield put(deleteServiceLogMediaError(response))
    } catch (error) {
        yield put(deleteServiceLogMediaError(error))
    }
}

//service log reports

function* getServiceLogReports({ payload: { page, perPage, startDate, endDate, orderByType } }) {
    try {
        const response = yield call(apiGetServiceLogReports, page, perPage, startDate, endDate, orderByType)
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceLogReportsSuccess(response.data))
        }
        yield put(getServiceLogReportsError(response.error))
    } catch (error) {
        yield put(getServiceLogReportsError(error))
    }
}

function* addServiceLogReport({ payload }) {
    try {
        const response = yield call(apiAddServiceLogReport, payload.title, payload.report_attributes)
        if (response.status === 200 || response.code === 200) {
            yield put(addServiceLogReportSuccess(response.data))
        }
        yield put(addServiceLogReportError(response.error))
    } catch (error) {
        yield put(addServiceLogReportError(error))
    }
}

function* showServiceLogReport({ payload }) {
    try {
        const response = yield call(apiShowServiceLogReport, payload.id)
        if (response.status === 200 || response.code === 200) {
            yield put(showServiceLogReportSuccess(response.data))
        }
        yield put(showServiceLogReportError(response.error))
    } catch (error) {
        yield put(showServiceLogReportError(error))
    }
}

function* deleteServiceLogReport({ payload }) {
    try {
        const response = yield call(apiDeleteServiceLogReport, payload.id)
        if (response.status === 200 || response.code === 200) {
            yield put(deleteServiceLogReportSuccess(response.data))
        }
        yield put(deleteServiceLogReportError(response))
    } catch (error) {
        yield put(deleteServiceLogReportError(error))
    }
}

function* watchGetServiceLogs() {
    yield takeEvery(GET_SERVICE_LOGS, getServiceLogs)
}

function* watchCreateServiceLog() {
    yield takeEvery(CREATE_SERVICE_LOG, createServiceLog)
}

function* watchGetServiceLog() {
    yield takeEvery(GET_SERVICE_LOG, getServiceLog)
}

function* watchUpdateServiceLog() {
    yield takeEvery(UPDATE_SERVICE_LOG, updateServiceLog)
}

function* watchDeleteServiceLog() {
    yield takeEvery(DELETE_SERVICE_LOG, deleteServiceLog)
}
//media
function* watchGetServiceLogMedia() {
    yield takeEvery(GET_SERVICE_LOG_MEDIA, getServiceLogMedia)
}

function* watchAddServiceLogMedia() {
    yield takeEvery(ADD_SERVICE_LOG_MEDIA, addServiceLogMedia)
}

function* watchDeleteServiceLogMedia() {
    yield takeEvery(DELETE_SERVICE_LOG_MEDIA, deleteServiceLogMedia)
}
//service log report
function* watchGetServiceLogReports() {
    yield takeEvery(GET_SERVICE_LOG_REPORTS, getServiceLogReports)
}

function* watchAddServiceLogReport() {
    yield takeEvery(ADD_SERVICE_LOG_REPORT, addServiceLogReport)
}

function* watchShowServiceLogReport() {
    yield takeEvery(SHOW_SERVICE_LOG_REPORT, showServiceLogReport)
}

function* watchDeleteServiceLogReport() {
    yield takeEvery(DELETE_SERVICE_LOG_REPORT, deleteServiceLogReport)
}
function* serviceLogsSaga() {
    yield all([
        fork(watchGetServiceLogs),
        fork(watchCreateServiceLog),
        fork(watchGetServiceLog),
        fork(watchUpdateServiceLog),
        fork(watchDeleteServiceLog),
        fork(watchGetServiceLogMedia),
        fork(watchAddServiceLogMedia),
        fork(watchDeleteServiceLogMedia),
        fork(watchGetServiceLogReports),
        fork(watchAddServiceLogReport),
        fork(watchShowServiceLogReport),
        fork(watchDeleteServiceLogReport),
    ])
}

export default serviceLogsSaga