import { faSave, faTrash } from '@fortawesome/free-solid-svg-icons';
import axios from 'axios';
import React, { useEffect, useState } from 'react'
import CommonButton from './CommonButton';
import pdflogo from './../../assets/images/pdflogo.png';
const token = localStorage.getItem('authUser')

const MediaImage = ({ thumbnail, originalUrl, filename, handleDownload, handleDelete }) => {
    const [imageUrl, setImageUrl] = useState('')

    useEffect(() => {
        const options = {
            method: 'GET',
            mode: 'no-cors',
            url: originalUrl,
            responseType: 'arraybuffer',
            headers: {
                'Content-Type': 'application/json',
                'Authorization': `Basic ${token}`
            }
        };

        axios.request(options).then(function (response) {
            const url = new Blob([response.data], { type: response.headers['content-type'] })
            var blobUrl = URL.createObjectURL(url);
            setImageUrl(blobUrl)
        }).catch(function (error) {
            console.error(error);
        });
        return () => {
            setImageUrl('')
        }
    }, [originalUrl])


    const handleOriginalUrl = () => {
        window.open(imageUrl, '_blank').focus();
    }

    return (
        <div className='media-preview'>
            <img src={thumbnail?.slice(thumbnail.length - 3, thumbnail.length) === 'pdf' ? pdflogo : imageUrl} onClick={handleOriginalUrl} alt={filename} style={{ cursor: 'pointer' }} />
            <div>
                <CommonButton
                    variant="danger"
                    label='Sačuvaj'
                    onClick={handleDownload}
                    icon={faSave}
                    style={{ width: '45%' }}
                />
                <CommonButton
                    variant="dark"
                    label='Obriši'
                    onClick={handleDelete}
                    icon={faTrash}
                    style={{ width: '45%' }}
                />
            </div>
        </div>
    )
}

export default MediaImage
