import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getBusiness = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/businesses/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const uploadBusinessLogo = async (file) => {
    const formdata = new FormData();
    formdata.append("business_logo", file, file.name)

    return await axios.post(`${process.env.REACT_APP_API_URL}/businesses/business_logo?_method=put`, formdata, {
        headers: {
            "Content-Type": "multipart/form-data",
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteBusinessLogo = async () => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/businesses/business_logo`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}