import { all, takeEvery, call, put, fork } from 'redux-saga/effects';

import {
    GET_FUEL_TYPES,
    GET_SERVICE_TYPES,
    GET_SERVICE_CATEGORIES,
    GET_CUSTOMER_ROLES,
    GET_VEHICLE_TYPES,
    GET_VEHICLE_STATUSES,
    GET_TODO_STATUSES,
    GET_LOGS_STATUSES,
    GET_USER_STATUSES
} from './actionTypes';

import {
    getFuelTypesSuccess,
    getFuelTypesError,
    getServiceTypesSuccess,
    getServiceTypesError,
    getServiceCategoriesSuccess,
    getServiceCategoriesError,
    getCustomerRolesSuccess,
    getCustomerRolesError,
    getVehicleTypesSuccess,
    getVehicleTypesError,
    getVehicleStatusesSuccess,
    getVehicleStatusesError,
    getTodoStatusesSuccess,
    getTodoStatusesError,
    getLogsStatusesSuccess,
    getLogsStatusesError,
    getUserStatusesSuccess,
    getUserStatusesError
} from './actions';

import {
    getFuelTypes as apiGetFuelTypes,
    getServiceTypes as apiGetServiceTypes,
    getServiceCategories as apiGetServiceCategories,
    getCustomerRoles as apiGetCustomerRoles,
    getVehicleTypes as apiGetVehicleTypes,
    getVehicleStatuses as apiGetVehicleStatuses,
    getTodoStatuses as apiGetTodoStatuses,
    getLogsStatuses as apiGetLogsStatuses,
    getUserStatuses as apiGetUserStatuses
} from './../../helpers/static_values_service/static_values_controller';

function* getFuelTypes() {
    try {
        const response = yield call(apiGetFuelTypes);
        if (response.status === 200 || response.code === 200) {
            yield put(getFuelTypesSuccess(response.data))
        }
        yield put(getFuelTypesError(response.message))
    } catch (error) {
        yield put(getFuelTypesError(error))
    }
}

function* getServiceTypes() {
    try {
        const response = yield call(apiGetServiceTypes);
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceTypesSuccess(response.data))
        }
        yield put(getServiceTypesError(response.message))
    } catch (error) {
        yield put(getServiceTypesError(error))
    }
}

function* getServiceCategories() {
    try {
        const response = yield call(apiGetServiceCategories);
        if (response.status === 200 || response.code === 200) {
            yield put(getServiceCategoriesSuccess(response.data))
        }
        yield put(getServiceCategoriesError(response.message))
    } catch (error) {
        yield put(getServiceCategoriesError(error))
    }
}

function* getCustomerRoles() {
    try {
        const response = yield call(apiGetCustomerRoles);
        if (response.status === 200 || response.code === 200) {
            yield put(getCustomerRolesSuccess(response.data))
        }
        yield put(getCustomerRolesError(response.message))
    } catch (error) {
        yield put(getCustomerRolesError(error))
    }
}

function* getVehicleTypes() {
    try {
        const response = yield call(apiGetVehicleTypes);
        if (response.status === 200 || response.code === 200) {
            yield put(getVehicleTypesSuccess(response.data))
        }
        yield put(getVehicleTypesError(response.message))
    } catch (error) {
        yield put(getVehicleTypesError(error))
    }
}

function* getVehicleStatuses() {
    try {
        const response = yield call(apiGetVehicleStatuses);
        if (response.status === 200 || response.code === 200) {
            yield put(getVehicleStatusesSuccess(response.data))
        }
        yield put(getVehicleStatusesError(response.message))
    } catch (error) {
        yield put(getVehicleStatusesError(error))
    }
}

function* getTodoStatuses() {
    try {
        const response = yield call(apiGetTodoStatuses);
        if (response.status === 200 || response.code === 200) {
            yield put(getTodoStatusesSuccess(response.data))
        }
        yield put(getTodoStatusesError(response.message))
    } catch (error) {
        yield put(getTodoStatusesError(error))
    }
}

function* getLogsStatuses() {
    try {
        const response = yield call(apiGetLogsStatuses);
        if (response.status === 200 || response.code === 200) {
            yield put(getLogsStatusesSuccess(response.data))
        }
        yield put(getLogsStatusesError(response.message))
    } catch (error) {
        yield put(getLogsStatusesError(error))
    }
}

function* getUserStatuses() {
    try {
        const response = yield call(apiGetUserStatuses);
        if (response.status === 200 || response.code === 200) {
            yield put(getUserStatusesSuccess(response.data))
        }
        yield put(getUserStatusesError(response.message))
    } catch (error) {
        yield put(getUserStatusesError(error))
    }
}

function* watchGetFuelTypes() {
    yield takeEvery(GET_FUEL_TYPES, getFuelTypes)
}
function* watchGetServiceTypes() {
    yield takeEvery(GET_SERVICE_TYPES, getServiceTypes)
}
function* watchGetCustomerRoles() {
    yield takeEvery(GET_CUSTOMER_ROLES, getCustomerRoles)
}
function* watchGetServiceCategories() {
    yield takeEvery(GET_SERVICE_CATEGORIES, getServiceCategories)
}
function* watchGetVehicleTypes() {
    yield takeEvery(GET_VEHICLE_TYPES, getVehicleTypes)
}
function* watchGetVehicleStatuses() {
    yield takeEvery(GET_VEHICLE_STATUSES, getVehicleStatuses)
}
function* watchGetTodoStatuses() {
    yield takeEvery(GET_TODO_STATUSES, getTodoStatuses)
}

function* watchGetLogsStatuses() {
    yield takeEvery(GET_LOGS_STATUSES, getLogsStatuses)
}

function* watchGetUserStatuses() {
    yield takeEvery(GET_USER_STATUSES, getUserStatuses)
}

function* staticTypesSaga() {
    yield all([
        fork(watchGetFuelTypes),
        fork(watchGetServiceTypes),
        fork(watchGetCustomerRoles),
        fork(watchGetServiceCategories),
        fork(watchGetVehicleTypes),
        fork(watchGetVehicleStatuses),
        fork(watchGetTodoStatuses),
        fork(watchGetLogsStatuses),
        fork(watchGetUserStatuses),
    ])
}

export default staticTypesSaga;