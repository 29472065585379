import React from 'react';
import ReactDom from 'react-dom';
import CommonButton from './CommonButton';

const Modal = (props) => {
    return ReactDom.createPortal(
        <div className='delete-modal-content'>
            <h3>Da li ste sigurni da želite da obrišete?</h3>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <CommonButton
                    variant='danger'
                    label='Da'
                    style={{ width: '15%' }}
                    onClick={props.deleteItem}
                    type='submit'
                />
                <CommonButton
                    variant='secondary'
                    label='Ne'
                    style={{ width: '15%' }}
                    onClick={props.close}
                    type='submit'
                />
            </div>
        </div>,
        document.getElementById('modal')
    )
}

export default Modal
