import axios from 'axios';
const token = localStorage.getItem('authUser')

export const getUsers = async (page, perPage) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/users?per_page=${perPage}&page=${page}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const createUser = async (business_id, role_id, status_id, first_name, last_name, email, phone, password, password_confirmation) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/users`, {
        status_id,
        role_id,
        first_name,
        last_name,
        email,
        phone,
        password,
        password_confirmation
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getUser = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateUser = async (id, business_id, role_id, status_id, first_name, last_name, email, phone) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        status_id,
        role_id,
        first_name,
        last_name,
        email,
        phone
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteUser = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/users/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateUserPassword = async (id, old_password, password, password_confirmation) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/users/${id}/password_update`, {
        old_password, password, password_confirmation
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            console.error(error)
            return error
        })
}