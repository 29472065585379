import React, { useState, Fragment, useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCar, faMotorcycle, faQuestionCircle, faShuttleVan, faTruck } from '@fortawesome/free-solid-svg-icons';
import SearchByText from '../Common/Search/SearchByText';
import CardForm from '../Common/Card/CardForm';
import CardTable from '../Common/Card/CardTable';
import Loader from '../Common/Loader';
import Pagination from '../Common/Pagination';
import { tableHeadList } from '../../helpers/common';
import { getVehicles } from '../../store/actions';

const VehiclesList = (props) => {
    const [query, setQuery] = useState('')
    const [current, setCurrent] = useState(1);
    const [meta, setMeta] = useState({})
    const perPage = 10;
    const dispatch = useDispatch()
    const history = useHistory()
    useEffect(() => {
        setMeta(props.meta)
    }, [props.meta])


    useEffect(() => {
        const page = meta && meta.current_page;
        setCurrent(page)
    }, [meta])

    const handleClick = (e) => {
        let page = parseInt(e.target.id);
        setCurrent(page)
        dispatch(getVehicles(page, perPage, query))
    }

    const clickableRow = (id) => {
        history.push(`/pregled-garaže/${id}`)
    }

    const returnIcon = (id) => {
        switch (id) {
            case 1:
                return <FontAwesomeIcon icon={faMotorcycle} size="2x" color='#272c33' />
            case 10:
                return <FontAwesomeIcon icon={faCar} size="2x" color='#272c33' />
            case 20:
                return <FontAwesomeIcon icon={faShuttleVan} size="2x" color='#272c33' />
            case 30:
                return <FontAwesomeIcon icon={faTruck} size="2x" color='#272c33' />
            default:
                return <FontAwesomeIcon icon={faQuestionCircle} size="2x" color='#272c33' />
        }
    }

    const handleSearch = (e) => {
        setQuery(e.target.value)
        dispatch(getVehicles(current, perPage, query))
    }

    const clearInput = () => {
        setQuery("")
        dispatch(getVehicles(current, perPage, ""))
    }

    return (
        <CardForm
            title={props.title}
            icon={faCar}
            addVehicleBtn={props.addVehicleBtn}
            onClick={props.onClick}
        >
            <div className='list-of-vehicles'>
                <SearchByText handleSearch={handleSearch} value={query} clearInput={clearInput} style={props.style} />
                {props.loading ?
                    <Loader
                        marginTop='50px'
                    />
                    :
                    <Fragment>
                        <CardTable
                            headListItems={tableHeadList.vehicles}
                        >
                            {props.vehiclesList &&
                                props.vehiclesList.length > 0 &&
                                props.vehiclesList.map((car, index) => (
                                    <tr key={car.id} onClick={() => clickableRow(car.id)} className='list-grid'>
                                        <td>{returnIcon(car.vehicle_type_id)}</td>
                                        <td>{car.production_year} {car.vehicle_manufacturer.name} {car.vehicle_model.name} <br /> {car.label}</td>
                                        <td>{car.vehicle_plate}</td>
                                        <td>{car.mileage}</td>
                                        <td>{car.note}</td>
                                    </tr>
                                )
                                )}
                        </CardTable>
                        {meta && meta.total > 10 &&
                            <Pagination
                                data={meta && parseInt(meta.total)}
                                handleClick={handleClick}
                                itemsPerPage={perPage}
                                currentPage={current}
                            />
                        }
                    </Fragment>
                }
            </div>
        </CardForm>
    )

}

export default VehiclesList
