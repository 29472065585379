export const GET_FUEL_TYPES = 'GET_FUEL_TYPES';
export const GET_FUEL_TYPES_SUCCESS = 'GET_FUEL_TYPES_SUCCESS';
export const GET_FUEL_TYPES_ERROR = 'GET_FUEL_TYPES_ERROR';

export const GET_SERVICE_TYPES = 'GET_SERVICE_TYPES';
export const GET_SERVICE_TYPES_SUCCESS = 'GET_SERVICE_TYPES_SUCCESS';
export const GET_SERVICE_TYPES_ERROR = 'GET_SERVICE_TYPES_ERROR';

export const GET_SERVICE_CATEGORIES = 'GET_SERVICE_CATEGORIES';
export const GET_SERVICE_CATEGORIES_SUCCESS = 'GET_SERVICE_CATEGORIES_SUCCESS';
export const GET_SERVICE_CATEGORIES_ERROR = 'GET_SERVICE_CATEGORIES_ERROR';

export const GET_CUSTOMER_ROLES = 'GET_CUSTOMER_ROLES';
export const GET_CUSTOMER_ROLES_SUCCESS = 'GET_CUSTOMER_ROLES_SUCCESS';
export const GET_CUSTOMER_ROLES_ERROR = 'GET_CUSTOMER_ROLES_ERROR';

export const GET_VEHICLE_TYPES = 'GET_VEHICLE_TYPES';
export const GET_VEHICLE_TYPES_SUCCESS = 'GET_VEHICLE_TYPES_SUCCESS';
export const GET_VEHICLE_TYPES_ERROR = 'GET_VEHICLE_TYPES_ERROR';

export const GET_VEHICLE_STATUSES = 'GET_VEHICLE_STATUSES';
export const GET_VEHICLE_STATUSES_SUCCESS = 'GET_VEHICLE_STATUSES_SUCCESS';
export const GET_VEHICLE_STATUSES_ERROR = 'GET_VEHICLE_STATUSES_ERROR';

export const GET_TODO_STATUSES = 'GET_TODO_STATUSES';
export const GET_TODO_STATUSES_SUCCESS = 'GET_TODO_STATUSES_SUCCESS';
export const GET_TODO_STATUSES_ERROR = 'GET_TODO_STATUSES_ERROR';

export const GET_LOGS_STATUSES = 'GET_LOGS_STATUSES';
export const GET_LOGS_STATUSES_SUCCESS = 'GET_LOGS_STATUSES_SUCCESS';
export const GET_LOGS_STATUSES_ERROR = 'GET_LOGS_STATUSES_ERROR';

export const GET_USER_STATUSES = 'GET_USER_STATUSES';
export const GET_USER_STATUSES_SUCCESS = 'GET_USER_STATUSES_SUCCESS';
export const GET_USER_STATUSES_ERROR = 'GET_USER_STATUSES_ERROR';