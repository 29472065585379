import {
    GET_VEHICLES,
    GET_VEHICLES_SUCCESS,
    GET_VEHICLES_ERROR,
    GET_VEHICLE,
    GET_VEHICLE_SUCCESS,
    GET_VEHICLE_ERROR,
    CREATE_VEHICLE,
    CREATE_VEHICLE_SUCCESS,
    CREATE_VEHICLE_ERROR,
    UPDATE_VEHICLE,
    UPDATE_VEHICLE_SUCCESS,
    UPDATE_VEHICLE_ERROR,
    DELETE_VEHICLE,
    DELETE_VEHICLE_SUCCESS,
    DELETE_VEHICLE_ERROR,
    GET_VEHICLE_CUSTOMERS,
    GET_VEHICLE_CUSTOMERS_SUCCESS,
    GET_VEHICLE_CUSTOMERS_ERROR,
    GET_VEHICLE_SERVICE_LOGS,
    GET_VEHICLE_SERVICE_LOGS_SUCCESS,
    GET_VEHICLE_SERVICE_LOGS_ERROR
} from './actionType';

const initialState = {
    loading: false,
    vehicles: [],
    vehicle: {},
    vehicleCustomers: [],
    vehicleServiceLogs: [],
    error: ''
}

const Vehicles = (state = initialState, action) => {
    switch (action.type) {
        case GET_VEHICLES:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLES_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicles: action.payload
            }
        case GET_VEHICLES_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_VEHICLE:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicle: action.payload.data
            }
        case GET_VEHICLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case CREATE_VEHICLE:
            return {
                ...state,
                loading: true
            }
        case CREATE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicles: [action.payload.data, ...state.vehicles.data]
            }
        case CREATE_VEHICLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPDATE_VEHICLE:
            return {
                ...state,
                loading: true
            }
        case UPDATE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicle: action.payload.data
            }
        case UPDATE_VEHICLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_VEHICLE:
            return {
                ...state,
                loading: true
            }
        case DELETE_VEHICLE_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicles: [...state.vehicles.data]
            }
        case DELETE_VEHICLE_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_VEHICLE_CUSTOMERS:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_CUSTOMERS_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicleCustomers: action.payload.data
            }
        case GET_VEHICLE_CUSTOMERS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_VEHICLE_SERVICE_LOGS:
            return {
                ...state,
                loading: true
            }
        case GET_VEHICLE_SERVICE_LOGS_SUCCESS:
            return {
                ...state,
                loading: false,
                vehicleServiceLogs: action.payload.data
            }
        case GET_VEHICLE_SERVICE_LOGS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default Vehicles