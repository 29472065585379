import { takeEvery, all, fork, call, put } from 'redux-saga/effects';
import {
    GET_TODO,
    ADD_TODO,
    GET_TODO_TASK,
    DELETE_TODO_TASK,
    UPDATE_TODO_TASK
} from './actionTypes';
import {
    getTodoSuccess,
    getTodoError,
    addTodoSuccess,
    addTodoError,
    getToDoTaskSuccess,
    getToDoTaskError,
    deleteToDoTaskSuccess,
    deleteToDoTaskError,
    updateToDoTaskSuccess,
    updateToDoTaskError
} from './actions';
import {
    getToDo as apiGetToDo,
    addToDo as apiAddToDo,
    getTodoTask as apiGetToDoTask,
    deleteTodoTask as apiDeleteToDoTask,
    updateToDoTask as apiUpdateToDoTask
} from './../../helpers/todo_service/todo_controller';

function* getToDo({ payload: { page, perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id } }) {
    try {
        const response = yield call(apiGetToDo, page, perPage, created_at_from, created_at_to, date_from, date_to, assigner_id, assignee_id, vehicle_id, customer_id, status_id)
        if (response.status === 200 || response.code === 200) {
            yield put(getTodoSuccess(response.data))
        }
        yield put(getTodoError(response.error))
    } catch (error) {
        yield put(getTodoError(error))
    }
}

function* addToDo({ payload }) {
    try {
        const response = yield call(apiAddToDo, payload.business_id, payload.vehicle_id, payload.assignee_id, payload.service_type_id, payload.service_category_id, payload.status_id, payload.date, payload.title, payload.text, payload.service_subcategories)
        if (response.status === 200 || response.code === 200 || response.status === 201 || response.code === 201) {
            yield put(addTodoSuccess(response.data))
            yield getToDo({ payload: { page: 1, perPage: 10 } })
        }
        yield put(addTodoError(response.error))
    } catch (error) {
        yield put(addTodoError(error))
    }
}

function* getToDoTask({ payload }) {
    try {
        const response = yield call(apiGetToDoTask, payload.id)
        if (response.status === 200 || response.code === 200 || response.status === 201 || response.code === 201) {
            yield put(getToDoTaskSuccess(response.data))
        }
        yield put(getToDoTaskError(response.error))
    } catch (error) {
        yield put(getToDoTaskError(error))
    }
}

function* deleteToDoTask({ payload }) {
    try {
        const response = yield call(apiDeleteToDoTask, payload.id)
        if (response.status === 200 || response.code === 200 || response.status === 201 || response.code === 201) {
            yield put(deleteToDoTaskSuccess(response.data))
            yield getToDo({ payload: { page: 1, perPage: 10 } })
        }
        yield put(deleteToDoTaskError(response.error))
    } catch (error) {
        yield put(deleteToDoTaskError(error))
    }
}

function* updateToDoTask({ payload }) {
    try {
        const response = yield call(apiUpdateToDoTask, payload.id, payload.business_id, payload.vehicle_id, payload.assignee_id, payload.service_type_id, payload.service_category_id, payload.status_id, payload.date, payload.title, payload.text, payload.service_subcategories)
        if (response.status === 200 || response.code === 200 || response.status === 201 || response.code === 201) {
            yield put(updateToDoTaskSuccess(response.data))
            yield getToDo({ payload: { page: 1, perPage: 10 } })
        }
        yield put(updateToDoTaskError(response.error))
    } catch (error) {
        yield put(updateToDoTaskError(error))
    }
}

function* watchGetToDo() {
    yield takeEvery(GET_TODO, getToDo)
}

function* watchAddToDo() {
    yield takeEvery(ADD_TODO, addToDo)
}

function* watchGetToDoTask() {
    yield takeEvery(GET_TODO_TASK, getToDoTask)
}

function* watchDeleteToDoTask() {
    yield takeEvery(DELETE_TODO_TASK, deleteToDoTask)
}

function* watchUpdateToDoTask() {
    yield takeEvery(UPDATE_TODO_TASK, updateToDoTask)
}

function* todoSaga() {
    yield all([
        fork(watchGetToDo),
        fork(watchAddToDo),
        fork(watchGetToDoTask),
        fork(watchDeleteToDoTask),
        fork(watchUpdateToDoTask)
    ])
}

export default todoSaga