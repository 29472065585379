import React, { useState, forwardRef, useEffect } from 'react';
import { Card } from 'react-bootstrap';
import DatePicker from 'react-datepicker';
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendarAlt } from '@fortawesome/free-solid-svg-icons'

const CommonDatePicker = (props) => {
    const [date, setDate] = useState('');
    const ExampleCustomInput = forwardRef(
        ({ value, onClick }, ref) => (
            <button className="date-picker-btn" onClick={onClick} ref={ref}>
                {value ? value : 'Izaberi'} <FontAwesomeIcon icon={faCalendarAlt} />
            </button>
        ),
    );
    useEffect(() => {
        if (props.date) {
            setDate(new Date(props.date))
        } else {
            setDate('')
        }
    }, [props.date])

    const getDate = (date) => {
        setDate(date);
        props.handleDate(date)
    }
    return (
        <div className={props.className}>
            {props.cardText && <Card.Text style={props.style}>{props.cardText}</Card.Text>}
            <DatePicker
                selected={date}
                onChange={getDate}
                name={props.name}
                customInput={<ExampleCustomInput />}
            />
        </div>
    )
}

export default CommonDatePicker
