import React from 'react';
import ReactDom from 'react-dom';
import CommonButton from './CommonButton';
import InputField from './InputField';

const SaveModal = (props) => {
    return ReactDom.createPortal(
        <div className='delete-modal-content'>
            <div style={{ marginBottom: '20px' }}>
                <h3>Upišite naziv izveštaja</h3>
                <InputField onChange={(e) => props.handleModalInput(e)} />
            </div>
            <div style={{ display: 'flex', justifyContent: 'space-evenly' }}>
                <CommonButton
                    variant='danger'
                    label='Sačuvaj'
                    style={{ width: '30%' }}
                    onClick={props.saveItem}
                    type='submit'
                />
                <CommonButton
                    variant='secondary'
                    label='Zatvori'
                    style={{ width: '30%' }}
                    onClick={props.close}
                    type='submit'
                />
            </div>
        </div>,
        document.getElementById('modal')
    )
}

export default SaveModal
