import {
    GET_BUSINESS,
    GET_BUSINESS_SUCCESS,
    GET_BUSINESS_ERROR,
    UPLOAD_BUSINESS_LOGO,
    UPLOAD_BUSINESS_LOGO_SUCCESS,
    UPLOAD_BUSINESS_LOGO_ERROR,
    DELETE_BUSINESS_LOGO,
    DELETE_BUSINESS_LOGO_SUCCESS,
    DELETE_BUSINESS_LOGO_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    business: {},
    business_logo: {},
    error: ''
}

const Business = (state = initialState, action) => {
    switch (action.type) {
        case GET_BUSINESS:
            return {
                ...state,
                loading: true
            }
        case GET_BUSINESS_SUCCESS:
            return {
                ...state,
                loading: false,
                business: action.payload.data
            }
        case GET_BUSINESS_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case UPLOAD_BUSINESS_LOGO:
            return {
                ...state,
                loading: true
            }
        case UPLOAD_BUSINESS_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                business: { ...state.business, ...action.payload.data }
            }
        case UPLOAD_BUSINESS_LOGO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_BUSINESS_LOGO:
            return {
                ...state,
                loading: true
            }
        case DELETE_BUSINESS_LOGO_SUCCESS:
            return {
                ...state,
                loading: false,
                business_logo: {},
                business: { ...state.business }
            }
        case DELETE_BUSINESS_LOGO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default Business