import {
    GET_VEHICLE_MODELS,
    GET_VEHICLE_MODELS_SUCCESS,
    GET_VEHICLE_MODELS_ERROR,
} from './actionTypes';

export const getVehicleModels = (id) => {
    return {
        type: GET_VEHICLE_MODELS,
        payload: { id }
    }
}

export const getVehicleModelsSuccess = (models) => {
    return {
        type: GET_VEHICLE_MODELS_SUCCESS,
        payload: models
    }
}

export const getVehicleModelsError = (message) => {
    return {
        type: GET_VEHICLE_MODELS_ERROR,
        payload: message
    }
}