import React, { useEffect } from 'react'
import ReactDom from 'react-dom';

const PrivacyPolicy = () => {

    useEffect(() => {
        document.body.classList.add("overflow-hidden");
    }, [])

    return ReactDom.createPortal(
        <embed
            src={`${process.env.REACT_APP_BASE_URL}privacy_policy`}
            className="welcome-page"
        />,
        document.getElementById('welcome')
    )
}

export default PrivacyPolicy
