import React, { useState, useEffect } from 'react';
import { Link, withRouter } from 'react-router-dom';
import { useSelector, useDispatch } from 'react-redux';

import { forgotPassword } from './../../store/actions';

import InputField from './../../components/Common/InputField';
import LoginCard from './../../components/Common/Card/LoginCard';
import CommonButton from './../../components/Common/CommonButton';
import ErrorAlert from './../../components/Common/ErrorAlert';
import { validateEmail } from './../../helpers/common';
import SuccessAlert from '../../components/Common/SuccessAlert';
import Loader from '../../components/Common/Loader';



const ForgotPassword = () => {
    const [email, setEmail] = useState('');
    const [error, setError] = useState(false);
    const [message, setMessage] = useState(false);
    const fp = useSelector(state => state.ForgotPassword)
    const dispatch = useDispatch();

    useEffect(() => {
        if (fp.error !== '') {
            setError(true)
            setMessage(false)
        } else if (fp.message !== '') {
            setError(false)
            setMessage(true)
        } else {
            setError(false)
        }
    }, [fp.error, fp.message])

    const handleInput = (e) => {
        setEmail(e.target.value)
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        dispatch(forgotPassword(email))

        if (!validateEmail(email)) {
            setError(true)
            setMessage(false)
        } else if (fp.error !== '') {
            setError(true)
            setMessage(false)
        } else if (fp.message !== '') {
            setError(false)
            setMessage(true)
        } else {
            setError(false)
        }
    }
    return (
        <LoginCard>
            <InputField
                label='Email adresa'
                type='email'
                controlId='formBasicEmail'
                value={email}
                infoText='Unesite email adresu sa kojom ste se registrovali.'
                onChange={handleInput}
            />
            <CommonButton
                label='Pošalji zahtev'
                type='submit'
                variant='danger'
                onClick={handleSubmit}
            />
            <p style={{ marginTop: '10px' }}>Setili ste se koja vam je lozinka? <Link to={{ pathname: '/' }}>Prijavite se</Link></p>
            {fp.loading ? <Loader marginBottom='40px' /> : error ? <ErrorAlert content={fp.error} onClose={() => setError(false)} /> : message && <SuccessAlert content={fp.message} onClose={() => setMessage(false)} />}
        </LoginCard>
    )
}

export default withRouter(ForgotPassword)
