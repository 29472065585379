import {
    GET_TODO,
    GET_TODO_SUCCESS,
    GET_TODO_ERROR,
    ADD_TODO,
    ADD_TODO_SUCCESS,
    ADD_TODO_ERROR,
    GET_TODO_TASK,
    GET_TODO_TASK_SUCCESS,
    GET_TODO_TASK_ERROR,
    DELETE_TODO_TASK,
    DELETE_TODO_TASK_SUCCESS,
    DELETE_TODO_TASK_ERROR,
    UPDATE_TODO_TASK,
    UPDATE_TODO_TASK_SUCCESS,
    UPDATE_TODO_TASK_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    tasks: [],
    task: {},
    error: ''
}

const Todo = (state = initialState, action) => {
    switch (action.type) {
        case GET_TODO:
            return {
                ...state,
                loading: true
            }
        case GET_TODO_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: action.payload
            }
        case GET_TODO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case ADD_TODO:
            return {
                ...state,
                loading: true
            }
        case ADD_TODO_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: [action.payload.data, ...state.tasks.data]
            }
        case ADD_TODO_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_TODO_TASK:
            return {
                ...state,
                loading: true
            }
        case GET_TODO_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                task: action.payload.data
            }
        case GET_TODO_TASK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case DELETE_TODO_TASK:
            return {
                ...state,
                loading: true
            }
        case DELETE_TODO_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                tasks: [...state.tasks.data]
            }
        case DELETE_TODO_TASK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }

        case UPDATE_TODO_TASK:
            return {
                ...state,
                loading: true
            }
        case UPDATE_TODO_TASK_SUCCESS:
            return {
                ...state,
                loading: false,
                task: action.payload.data
            }
        case UPDATE_TODO_TASK_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return state
    }
}

export default Todo