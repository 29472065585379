import {
    GLOBAL_SEARCH,
    GLOBAL_SEARCH_SUCCESS,
    GLOBAL_SEARCH_ERROR
} from './actionTypes';

const initialState = {
    loading: false,
    search: [],
    error: ''
}

const Search = (state = initialState, action) => {
    switch (action.type) {
        case GLOBAL_SEARCH:
            return {
                ...state,
                loading: true,
                error: ''
            }
        case GLOBAL_SEARCH_SUCCESS:
            return {
                ...state,
                loading: false,
                search: action.payload.data,
                error: ''
            }
        case GLOBAL_SEARCH_ERROR:
            return {
                ...state,
                loading: false,
                error: action.payload.message
            }
        default:
            return state
    }
}

export default Search