export const GET_CUSTOMERS = 'GET_CUSTOMERS';
export const GET_CUSTOMERS_SUCCESS = 'GET_CUSTOMERS_SUCCESS';
export const GET_CUSTOMERS_ERROR = 'GET_CUSTOMERS_ERROR';

export const CREATE_CUSTOMER = 'CREATE_CUSTOMER';
export const CREATE_CUSTOMER_SUCCESS = 'CREATE_CUSTOMER_SUCCESS';
export const CREATE_CUSTOMER_ERROR = 'CREATE_CUSTOMER_ERROR';

export const GET_CUSTOMER = 'GET_CUSTOMER';
export const GET_CUSTOMER_SUCCESS = 'GET_CUSTOMER_SUCCESS';
export const GET_CUSTOMER_ERROR = 'GET_CUSTOMER_ERROR';

export const UPDATE_CUSTOMER = 'UPDATE_CUSTOMER';
export const UPDATE_CUSTOMER_SUCCESS = 'UPDATE_CUSTOMER_SUCCESS';
export const UPDATE_CUSTOMER_ERROR = 'UPDATE_CUSTOMER_ERROR';

export const DELETE_CUSTOMER = 'DELETE_CUSTOMER';
export const DELETE_CUSTOMER_SUCCESS = 'DELETE_CUSTOMER_SUCCESS';
export const DELETE_CUSTOMER_ERROR = 'DELETE_CUSTOMER_ERROR';

export const GET_CUSTOMER_VEHICLES = 'GET_CUSTOMER_VEHICLES';
export const GET_CUSTOMER_VEHICLES_SUCCESS = 'GET_CUSTOMER_VEHICLES_SUCCESS';
export const GET_CUSTOMER_VEHICLES_ERROR = 'GET_CUSTOMER_VEHICLES_ERROR';

export const GET_CUSTOMER_SERVICE_LOGS = 'GET_CUSTOMER_SERVICE_LOGS';
export const GET_CUSTOMER_SERVICE_LOGS_SUCCESS = 'GET_CUSTOMER_SERVICE_LOGS_SUCCESS';
export const GET_CUSTOMER_SERVICE_LOGS_ERROR = 'GET_CUSTOMER_SERVICE_LOGS_ERROR';

//Customer vehicles endpoint

export const GET_CUSTOMER_VEHICLES_DATA = 'GET_CUSTOMER_VEHICLES_DATA';
export const GET_CUSTOMER_VEHICLES_DATA_SUCCESS = 'GET_CUSTOMER_VEHICLES_DATA_SUCCESS';
export const GET_CUSTOMER_VEHICLES_DATA_ERROR = 'GET_CUSTOMER_VEHICLES_DATA_ERROR';

export const CREATE_CUSTOMER_VEHICLES_DATA = 'CREATE_CUSTOMER_VEHICLES_DATA';
export const CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS = 'CREATE_CUSTOMER_VEHICLES_DATA_SUCCESS';
export const CREATE_CUSTOMER_VEHICLES_DATA_ERROR = 'CREATE_CUSTOMER_VEHICLES_DATA_ERROR';

export const SHOW_CUSTOMER_VEHICLES_DATA = 'SHOW_CUSTOMER_VEHICLES_DATA';
export const SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS = 'SHOW_CUSTOMER_VEHICLES_DATA_SUCCESS';
export const SHOW_CUSTOMER_VEHICLES_DATA_ERROR = 'SHOW_CUSTOMER_VEHICLES_DATA_ERROR';

export const UPDATE_CUSTOMER_VEHICLES_DATA = 'UPDATE_CUSTOMER_VEHICLES_DATA';
export const UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS = 'UPDATE_CUSTOMER_VEHICLES_DATA_SUCCESS';
export const UPDATE_CUSTOMER_VEHICLES_DATA_ERROR = 'UPDATE_CUSTOMER_VEHICLES_DATA_ERROR';

export const DELETE_CUSTOMER_VEHICLES_DATA = 'DELETE_CUSTOMER_VEHICLES_DATA';
export const DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS = 'DELETE_CUSTOMER_VEHICLES_DATA_SUCCESS';
export const DELETE_CUSTOMER_VEHICLES_DATA_ERROR = 'DELETE_CUSTOMER_VEHICLES_DATA_ERROR';