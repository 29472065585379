import React, { Component, Fragment } from 'react';

import TodoList from './TodoList';



class Todo extends Component {
    render() {
        return (
            <Fragment>
                <TodoList
                    title='Zadaci za korisnike'
                    loading={this.props.loading}
                    tasksList={this.props.tasksList}
                    deleteTask={this.props.deleteTask}
                    meta={this.props.meta}
                    users={this.props.users}
                    vehicles={this.props.vehicles}
                    customers={this.props.customers}
                    todoStatuses={this.props.todoStatuses}
                />
            </Fragment>

        )
    }
}

export default Todo
