import {
    GET_BUSINESS,
    GET_BUSINESS_SUCCESS,
    GET_BUSINESS_ERROR,
    UPLOAD_BUSINESS_LOGO,
    UPLOAD_BUSINESS_LOGO_SUCCESS,
    UPLOAD_BUSINESS_LOGO_ERROR,
    DELETE_BUSINESS_LOGO,
    DELETE_BUSINESS_LOGO_SUCCESS,
    DELETE_BUSINESS_LOGO_ERROR
} from './actionTypes';

export const getBusiness = (id) => {
    return {
        type: GET_BUSINESS,
        payload: { id }
    }
}

export const getBusinessSuccess = (business) => {
    return {
        type: GET_BUSINESS_SUCCESS,
        payload: business
    }
}

export const getBusinessError = (message) => {
    return {
        type: GET_BUSINESS_ERROR,
        payload: message
    }
}

export const uploadBusinessLogo = (file) => {
    return {
        type: UPLOAD_BUSINESS_LOGO,
        payload: { file }
    }
}

export const uploadBusinessLogoSuccess = (message) => {
    return {
        type: UPLOAD_BUSINESS_LOGO_SUCCESS,
        payload: message
    }
}

export const uploadBusinessLogoError = (message) => {
    return {
        type: UPLOAD_BUSINESS_LOGO_ERROR,
        payload: message
    }
}

export const deleteBusinessLogo = (id) => {
    return {
        type: DELETE_BUSINESS_LOGO,
        payload: { id }
    }
}

export const deleteBusinessLogoSuccess = (message) => {
    return {
        type: DELETE_BUSINESS_LOGO_SUCCESS,
        payload: message
    }
}

export const deleteBusinessLogoError = (message) => {
    return {
        type: DELETE_BUSINESS_LOGO_ERROR,
        payload: message
    }
}