import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getCustomers = async (page, perPage, query) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/customers?per_page=${perPage}&page=${page}&q=${query || ""}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const createCustomer = async (business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/customers`,
        { first_name, last_name, phone, email, address, postal_code, city, country_id, note },
        {
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        .then(response => {
            if (response.status === 201 || response.status === 200) {
                localStorage.setItem('customer_id', response.data.data.id)
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getCustomer = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/customers/${id}`,
        {
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateCustomer = async (id, business_id, first_name, last_name, email, phone, address, postal_code, city, country_id, note) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/customers/${id}`,
        { first_name, last_name, email, phone, address, postal_code, city, country_id, note },
        {
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteCustomer = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/customers/${id}`,
        {
            headers: {
                Accept: 'application/json',
                'Authorization': `Basic ${token}`
            }
        })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getCustomerVehicles = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/vehicles?filters[customer_id][]=${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getCustomerServiceLogs = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_logs?filters[customer_id][]=${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}


export const getCustomerVehiclesData = async () => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/customer_vehicles`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const createCustomerVehiclesData = async (data) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/customer_vehicles`, { customer_vehicles: data }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const showCustomerVehiclesData = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/customer_vehicles/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateCustomerVehiclesData = async (id, vehicle_id, customer_id, customer_role_id) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/customer_vehicles/${id}`, { vehicle_id, customer_id, customer_role_id }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteCustomerVehiclesData = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/customer_vehicles/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}