import React from 'react';
import avatar from './../../assets/images/generic-avatar.png';

const Avatar = ({ src, width, marginLeft }) => {
    return (
        <img src={src} alt='account' style={{ borderRadius: '50%', width, marginLeft }} />
    )
}

Avatar.defaultProps = {
    src: avatar
}

export default Avatar
