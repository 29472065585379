import React, { PureComponent } from 'react';
import { Col, Row } from 'react-bootstrap';
import { connect } from 'react-redux';
import AddUser from '../../components/Forms/AddUser';
import UsersList from '../../components/Lists/UsersList';
import { tableHeadList } from '../../helpers/common';
import { getUsers, createUser, deleteUser, getUserStatuses } from '../../store/actions';

class Users extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }

    componentDidMount() {
        this.props.getUsers(1, 10);
        if (this.props.userStatuses.length === 0) {
            this.props.getUserStatuses();
        }
    }

    render() {
        return (
            <div className='page'>
                <Row>
                    <Col sm={4}>
                        <AddUser
                            title='Dodavanje korisnika'
                            createUser={this.props.createUser}
                            userStatuses={this.props.userStatuses}
                            me={this.props.me}
                            // getUsers={this.props.getUsers}
                            user={true}
                        />
                    </Col>
                    <Col sm={8}>
                        <UsersList
                            title='Lista korisnika'
                            headerList={tableHeadList.users}
                            usersList={this.props.users}
                            path='korisnici'
                            loading={this.props.loading}
                            deleteUser={this.props.deleteUser}
                            meta={this.props.meta}
                        />
                    </Col>
                </Row>
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        me: state.Me.me,
        users: state.Users.users.data,
        loading: state.Users.loading,
        meta: state.Users.users.meta,
        userStatuses: state.StaticTypes.userStatuses
    }
}

export default connect(mapStateToProps, { getUsers, createUser, deleteUser, getUserStatuses })(Users)
