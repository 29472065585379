import React, { PureComponent } from 'react';
import { connect } from 'react-redux';

import CustomersList from '../../components/Lists/CustomersList';
import { tableHeadList } from '../../helpers/common';

import { getCustomers, deleteCustomer } from './../../store/actions';

class Customers extends PureComponent {
    constructor(props) {
        super(props);
        this.state = {};
    }
    componentDidMount() {
        this.props.getCustomers(1, 10);

    }
    render() {
        return (
            <div className='page'>
                <CustomersList
                    title="Lista klijenata"
                    customer={true}
                    headerList={tableHeadList.customers}
                    usersList={this.props.customers}
                    loading={this.props.loading}
                    path='pregled-klijenata'
                    deleteCustomer={this.props.deleteCustomer}
                    meta={this.props.meta}
                />
            </div>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        loading: state.Customers.loading,
        customers: state.Customers.customers.data,
        meta: state.Customers.customers.meta,
        me: state.Me.me
    }
}

export default connect(mapStateToProps, { getCustomers, deleteCustomer })(Customers)
