import {
    GET_USERS,
    GET_USERS_SUCCESS,
    GET_USERS_ERROR,
    CREATE_USER,
    CREATE_USER_SUCCESS,
    CREATE_USER_ERROR,
    DELETE_USER,
    DELETE_USER_SUCCESS,
    DELETE_USER_ERROR,
    GET_USER,
    GET_USER_SUCCESS,
    GET_USER_ERROR,
    UPDATE_USER,
    UPDATE_USER_SUCCESS,
    UPDATE_USER_ERROR,
    UPDATE_USER_PASSWORD,
    UPDATE_USER_PASSWORD_SUCCESS,
    UPDATE_USER_PASSWORD_ERROR
} from './actionTypes';

export const getUsers = (page, perPage) => {
    return {
        type: GET_USERS,
        payload: { page, perPage }
    }
}

export const getUsersSuccess = (users) => {
    return {
        type: GET_USERS_SUCCESS,
        payload: users
    }
}

export const getUsersError = (message) => {
    return {
        type: GET_USERS_ERROR,
        payload: message
    }
}

export const createUser = (business_id, role_id, status_id, first_name, last_name, email, phone, password, password_confirmation) => {
    return {
        type: CREATE_USER,
        payload: { business_id, role_id, status_id, first_name, last_name, email, phone, password, password_confirmation }
    }
}

export const createUserSuccess = (user) => {
    return {
        type: CREATE_USER_SUCCESS,
        payload: user
    }
}

export const createUserError = (message) => {
    return {
        type: CREATE_USER_ERROR,
        payload: message
    }
}

export const getUser = (id) => {
    return {
        type: GET_USER,
        payload: { id }
    }
}

export const getUserSuccess = (message) => {
    return {
        type: GET_USER_SUCCESS,
        payload: message
    }
}

export const getUserError = (message) => {
    return {
        type: GET_USER_ERROR,
        payload: message
    }
}

export const updateUser = (id, business_id, role_id, status_id, first_name, last_name, email, phone) => {
    return {
        type: UPDATE_USER,
        payload: { id, business_id, role_id, status_id, first_name, last_name, email, phone }
    }
}

export const updateUserSuccess = (message) => {
    return {
        type: UPDATE_USER_SUCCESS,
        payload: message
    }
}

export const updateUserError = (message) => {
    return {
        type: UPDATE_USER_ERROR,
        payload: message
    }
}

export const deleteUser = (id, history) => {
    return {
        type: DELETE_USER,
        payload: { id, history }
    }
}

export const deleteUserSuccess = (message) => {
    return {
        type: DELETE_USER_SUCCESS,
        payload: message
    }
}

export const deleteUserError = (message) => {
    return {
        type: DELETE_USER_ERROR,
        payload: message
    }
}

export const updateUserPassword = (id, old_password, password, password_confirmation) => {
    return {
        type: UPDATE_USER_PASSWORD,
        payload: { id, old_password, password, password_confirmation }
    }
}

export const updateUserPasswordSuccess = (message) => {
    return {
        type: UPDATE_USER_PASSWORD_SUCCESS,
        payload: message
    }
}

export const updateUserPasswordError = (message) => {
    return {
        type: UPDATE_USER_PASSWORD_ERROR,
        payload: message
    }
}