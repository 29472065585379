import React from 'react';
import { Container, Form } from 'react-bootstrap';
import { useHistory } from 'react-router-dom';

import CommonButton from './../../components/Common/CommonButton';
import logo from './../../assets/images/logo.png'

const token = localStorage.getItem('authUser');

const styles = {
    form: {
        textAlign: 'center'
    }
}

const NotFound = () => {
    const history = useHistory()
    const handleSubmit = () => {
        if (token) {
            history.push('/kontrolna-tabla')
        } else {
            history.push('/')
        }

    }
    return (
        <Container fluid className='login'>
            <Form style={styles.form}>
                <div className='img-container'>
                    <img src={logo} width="100" height="50" alt='logo' className='img-anime' />
                </div>
                <h2>404</h2>
                <p>
                    Traženi sadržaj nije pronađen. Proverite adresu u vašem pretraživaču. Ukoliko se greška ponovi, molimo vas obavestite administratora na admin@caloboo.com.
                </p>
                <CommonButton
                    label='Servisna knjizica'
                    type='submit'
                    variant='danger'
                    onClick={handleSubmit}
                />
            </Form>
        </Container>
    )
}

export default NotFound
