import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getVehicles = async (page, perPage, query) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/vehicles?per_page=${perPage}&page=${page}&q=${query || ""}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getVehicle = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const createVehicle = async (business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date) => {
    return await axios.post(`${process.env.REACT_APP_API_URL}/vehicles`, {
        vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                localStorage.setItem('vehicle_id', response.data.data.id)
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateVehicle = async (id, business_id, create_user_id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
        id, vehicle_model_id, vehicle_manufacturer_id, fuel_type_id, status_id, vehicle_type_id, label, vehicle_plate, production_year, mileage, vin, engine_code, engine_displacement, engine_power_hp, engine_power_kw, daily_mileage, small_service_mileage, big_service_mileage, note, registration_date
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteVehicle = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/vehicles/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getVehicleCustomers = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/customers?filters[vehicle_id][]=${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getVehicleServiceLogs = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_logs?filters[vehicle_id][]=${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}