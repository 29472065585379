import axios from 'axios';
const token = localStorage.getItem('authUser');

export const getServiceLogs = async (page, perPage, date_from, date_to, service_type_id, service_category_id, production_year_from, production_year_to, vehicle_manufacturer_id, vehicle_model_id, customer_id, vehicle_id, service_subcategory_id) => {
    const url = `${process.env.REACT_APP_API_URL}/service_logs?per_page=${perPage}&page=${page}`;

    return await axios.get(`${url}&ranges[date][from]=${date_from || ""}&ranges[date][to]=${date_to || ""}&filters[service_type_id][]=${service_type_id || ""}&filters[service_category_id][]=${service_category_id || ""}&ranges[production_year][from]=${production_year_from || ""}&ranges[production_year][to]=${production_year_to || ""}&filters[vehicle_manufacturer_id][]=${vehicle_manufacturer_id || ""}&filters[vehicle_model_id][]=${vehicle_model_id || ""}&filters[customer_id][]=${customer_id || ""}&filters[vehicle_id][]=${vehicle_id || ""}&filters[service_subcategory_id][]=${service_subcategory_id || ""}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const createServiceLog = async (vehicle_id, create_user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, attachments, service_subcategories) => {

    const formdata = new FormData()
    formdata.append("vehicle_id", vehicle_id);
    formdata.append("service_type_id", service_type_id);
    formdata.append("service_category_id", service_category_id);
    formdata.append("date", date);
    formdata.append("title", title);
    formdata.append("text", text);
    formdata.append("used_parts", used_parts);
    formdata.append("price_parts", price_parts);
    formdata.append("price_work", price_work);
    formdata.append("mileage", mileage);
    Array.from(service_subcategories).forEach(subCat => formdata.append("service_subcategory[]", subCat))
    Array.from(attachments).forEach(file => {
        formdata.append("attachments[]", file, file.name)
    })
    return await axios.post(`${process.env.REACT_APP_API_URL}/service_logs`, formdata, {
        headers: {
            "Content-type": "multipart/form-data",
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200 || response.status === 201) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const getServiceLog = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_logs/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const updateServiceLog = async (id, vehicle_id, create_user_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, service_subcategories) => {
    return await axios.put(`${process.env.REACT_APP_API_URL}/service_logs/${id}`, {
        vehicle_id, service_type_id, service_category_id, date, title, text, used_parts, price_parts, price_work, mileage, service_subcategory: service_subcategories
    }, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}

export const deleteServiceLog = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/service_logs/${id}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    })
        .then(response => {
            if (response.status === 200) {
                return response
            }
        })
        .catch(error => {
            return error
        })
}


//Media

export const getServiceLogMedia = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_logs/${id}/media`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

export const addServiceLogMedia = async (id, attachments) => {
    const formdata = new FormData();
    Array.from(attachments).forEach(file => {
        formdata.append('attachments[]', file, file.name)
    })
    return await axios.post(`${process.env.REACT_APP_API_URL}/service_logs/${id}/media`, formdata, {
        headers: {
            "Content-type": "multipart/form-data",
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

export const deleteServiceLogMedia = async (id, uuid) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/service_logs/${id}/media/${uuid}`, {
        headers: {
            Accept: 'application/json',
            "Authorization": `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

//Service logs report

export const getServiceLogReports = async (page, perPage, startDate, endDate, orderByType) => {
    const url = `${process.env.REACT_APP_API_URL}/service_log_reports?per_page=${perPage}&page=${page}`;

    return await axios.get(`${url}&ranges[created_at][from]=${startDate || ''}&ranges[created_at][to]=${endDate || ''}&order_by_type=${orderByType || 'asc'}`, {
        headers: {
            Accept: 'application/json',
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

export const addServiceLogReport = async (title, report_attributes) => { // send * if user not set filters
    return await axios.post(`${process.env.REACT_APP_API_URL}/service_log_reports`, {
        title, report_attributes
    }, {
        headers: {
            "Content-type": "application/json",
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

export const showServiceLogReport = async (id) => {
    return await axios.get(`${process.env.REACT_APP_API_URL}/service_log_reports/${id}`, {
        headers: {
            "Content-type": "application/json",
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}

export const deleteServiceLogReport = async (id) => {
    return await axios.delete(`${process.env.REACT_APP_API_URL}/service_log_reports/${id}`, {
        headers: {
            "Content-type": "application/json",
            'Authorization': `Basic ${token}`
        }
    }).then(response => {
        return response
    }).catch(error => {
        return error
    })
}